import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from '../axiosconfig'
import { RuleType } from 'react-querybuilder'
import { toast } from 'react-toastify'

/* eslint-disable */

export class FiltersStore {
  rootStore
  allFilters: {
    action: {
      label: string
      query: {
        rules: RuleType[]
        combinator: string
        not: boolean
      }
    }
    access_group: string
    id: string
    view: string
    created_by: string
    created_at: string
  }[] = []
  currentFilters: { value: string; label: string; delete?: boolean }[] = []
  query: {
    combinator: string
    rules: any[]
    not: boolean
    id?: string
  } = {
    rules: [],
    combinator: 'and',
    not: false,
  }
  appliedQuery: {
    combinator: string
    rules: any[]
    not: boolean
    id?: string
  } = {
    rules: [],
    combinator: 'and',
    not: false,
  }
  appliedPredefinedQuery = {
    defaultFilter: 'empty',
    predefinedFilter: 'empty',
  }
  globalSearch = ''
  appliedGlobalSearch = ''

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchAllFilters = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/filter_view?from=0&size=1000&user=${this.rootStore.UserStore.userID}`
    //console.log(url)
    const data = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    const response = await axios.post(url, data, config)
    this.allFilters = await response.data.filters
    this.filterFilters()
  }

  @action.bound filterFilters = () => {
    const temparray = [
      ...this.allFilters.filter(
        (e) => e.view == this.rootStore.TableStore.getApiRequestFromUrl(),
      ),
    ]
    this.currentFilters = temparray.map((e) => {
      return {
        value: e.id,
        label: `${e.action.label} ${e.access_group === 'private' ? `(private)` : `(public)`}`,
        delete: e.access_group === 'private',
      }
    })
  }

  @action.bound removeFilter = async (id: string) => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/filter/${id}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }
    await axios.delete(url, config)
    toast('Filter deleted successfully', { type: 'success' })
    await this.fetchAllFilters()
  }

  @action.bound getDefaultFilter = (): string | null => {
    if (
      this.rootStore.UserStore.defaultFilter.hasOwnProperty(
        this.rootStore.PaginationStore.currentPagination,
      )
    ) {
      return this.rootStore.UserStore.defaultFilter[
        this.rootStore.PaginationStore.currentPagination
      ]
    } else return null
  }

  @action.bound getQueryFromRedirect = (): any => {
    const clickQuery = localStorage.getItem(
      `${this.rootStore.RouteStore.currentPage}-query`,
    )
    return clickQuery ? JSON.parse(clickQuery) : null
  }

  @action.bound setLocalQuery = () => {
    const { currentPage } = this.rootStore.RouteStore

    if (currentPage) {
      localStorage.setItem(
        `${currentPage}-query`,
        JSON.stringify(this.appliedQuery),
      )
      localStorage.setItem(
        `${currentPage}-appliedQuery`,
        JSON.stringify(this.appliedPredefinedQuery),
      )
    }
  }

  @action.bound setQuery = (query: {
    combinator: string
    rules: any[]
    not: boolean
    id?: string
  }): void => {
    this.query = query
  }

  @action.bound applyQuery = () => {
    this.appliedQuery = this.query
    this.appliedGlobalSearch = this.globalSearch
  }

  @action.bound applyDefaultAndPredefinedQuery = (
    defaultFilter: string,
    predefinedFilter: string,
  ) => {
    this.appliedPredefinedQuery = {
      defaultFilter: defaultFilter,
      predefinedFilter: predefinedFilter,
    }
  }

  @action.bound clearPredefinedQuery = () => {
    this.query = {
      rules: [],
      combinator: 'and',
      not: false,
    }
    this.appliedPredefinedQuery = {
      defaultFilter: this.appliedPredefinedQuery.defaultFilter,
      predefinedFilter: 'empty',
    }
  }

  @action.bound setEmptyQuery = () => {
    this.query = {
      rules: [],
      combinator: 'and',
      not: false,
    }
    this.appliedPredefinedQuery = {
      defaultFilter: 'empty',
      predefinedFilter: 'empty',
    }
  }

  @action.bound setGlobalSearch = (search: string) => {
    this.globalSearch = search
  }
}
