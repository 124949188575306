import { observer } from 'mobx-react'
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'
/* eslint-disable */
import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'
import { ReactComponent as StarEmpty } from '../../../assets/icons/star-empty.svg'
import { ReactComponent as StarFull } from '../../../assets/icons/star-full.svg'
import {
  ActionEdit,
  ActionList,
  Container,
  SingleAction,
  StarComponent,
  StyledIconDotsVertical,
  SvgCont,
} from './TableActions.styles'
import { IconDotsVertical, IconMenu2 } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import RemoveOrderComponent from '../../removeOrder/RemoveOrder.component'
import EditGenericComponent from '../../editGeneric/EditGeneric.component'
import AssignTagGeneric from '../../assignTagGeneric/AssignTagGeneric.component'
import AddSupplierProduct from '../../addSupplierProduct/AddSupplierProduct.component'
import AssignCollectionGenericComponent from '../../assignCollectionGeneric/AssignCollectionGeneric.component'
import AssignOrderLineTagGenericComponent from '../../assignOrderLineTagGeneric/AssignOrderLineTagGeneric.component'
import useOutsideClick from '../../../methods/methods/useOutSideClick'
import Button from '../../button/Button.component'
import useWindowDimensions from '../../../methods/hooks/useWindowDimensions'
import EditRenderedProductComponent from '../../editRenderedProduct/EditRenderedProduct.component'
import { toast } from 'react-toastify'
import RemoveRenderedProduct from '../../removeSupplierProduct/RemoveRenderedProduct.component'
import AssignOrderLineTagComponent from '../../_settings/tags/edits/assignOrderLineTag/AssignOrderLineTagAllTags.component'

interface Props extends PropsWithChildren {
  tableName: string
  actions: {
    display: boolean
    label: string
    edit: string | null
    create: string | null
    remove: string | null
    assignTag: string | null
    custom: any[]
  }
  columns: Array<string>
  row: any
  loaded: boolean
  setLoaded?: (loaded: boolean) => void
  fetchData: () => Promise<void>
  id?: string
}

const TableMoreActions: FC<Props> = observer(
  ({
    tableName,
    actions,
    columns,
    row,
    loaded,
    setLoaded,
    fetchData,
    id,
  }: Props) => {
    const store = useContext(StoreContext)
    const containerRef = useRef<any>(null)
    const { Theme } = store.Theme
    const { width, height } = useWindowDimensions()
    const [showActions, setShowActions] = useState(false)
    const [listedActions, setListedActions] = useState(actions)
    const [showMore, setShowMore] = useState(false)
    const keys = [...row.getAllCells().map((e: any) => e.column.id)]
    const values = [
      ...keys.map((e, index) => ({
        header: keys[index],
        value: row.getValue(e),
      })),
    ]
    //console.log(values)
    const [favoriteAction, setFavoriteAction] = useState('')

    const changeFavouriteAction = async (action: string) => {
      setShowActions(true)
      const tempDefaultActions: any = store.UserStore.defaultAction
      tempDefaultActions[tableName] = action
      store.SlidingPanelState.setSelectedSlider('')
      await store.UserStore.updateDefaultSettings(
        store.UserStore.defaultLanguage,
        store.UserStore.defaultTheme,
        store.UserStore.defaultAction,
        store.UserStore.defaultFilter,
        store.UserStore.defaultView,
      )
      await store.SlidingPanelState.setSelectedSlider('')
    }

    const ButtonsList = ({ allActions }: { allActions: boolean }) => {
      const actionsToRender = allActions
        ? actions // Show all actions for SlidingPanel
        : listedActions // Show favorite and limited actions for ActionList

      return (
        <>
          {actionsToRender.edit && (
            <Button
              type={'secondary'}
              onClick={() => {
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-edit-${row.id}`,
                )
                setShowActions(false)
              }}
            >
              <StarComponent>
                {favoriteAction === 'edit' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('edit')
                    }}
                  />
                )}
              </StarComponent>
              {'Edit ' + actions.label}
            </Button>
          )}
          {actionsToRender.remove && (
            <Button
              type={'secondary'}
              onClick={() => {
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-remove-${row.id}`,
                )
                setShowActions(false)
              }}
            >
              <StarComponent>
                {favoriteAction === 'remove' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('remove')
                    }}
                  />
                )}
              </StarComponent>
              {'Remove ' + actions.label}
            </Button>
          )}
          {actionsToRender.assignTag && (
            <>
              <Button
                type={'secondary'}
                onClick={() => {
                  store.SlidingPanelState.setSelectedSlider(
                    `sliding-assigntag-${row.id}`,
                  )
                  setShowActions(false)
                }}
              >
                <StarComponent>
                  {favoriteAction === 'assignTag' ? (
                    <StarFull />
                  ) : (
                    <StarEmpty
                      onClick={(e) => {
                        e.stopPropagation()
                        changeFavouriteAction('assignTag')
                      }}
                    />
                  )}
                </StarComponent>
                {'Assign tag to ' + actions.label}
              </Button>
            </>
          )}
          {actionsToRender.custom.includes('assignCollection') && (
            <Button
              type={'secondary'}
              onClick={() => {
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-assigncollection-${row.id}`,
                )
                setShowActions(false)
              }}
            >
              <StarComponent>
                {favoriteAction === 'assignCollection' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('assignCollection')
                    }}
                  />
                )}
              </StarComponent>
              {'Assign Collection to ' + actions.label}
            </Button>
          )}
          {actionsToRender.custom.includes('getPackageDocuments') && (
            <>
              <Button
                type={'secondary'}
                onClick={() => {
                  store.PackagesStore.exportPackageDocuments(row, true)
                  setShowActions(false)
                }}
              >
                <StarComponent>
                  {favoriteAction === 'getPackageDocuments' ? (
                    <StarFull />
                  ) : (
                    <StarEmpty
                      onClick={(e) => {
                        e.stopPropagation()
                        changeFavouriteAction('getPackageDocuments')
                      }}
                    />
                  )}
                </StarComponent>
                {'Export package documents'}
              </Button>
              <Button
                type={'secondary'}
                onClick={() => {
                  setShowActions(false)
                  store.PackagesStore.exportPackageDocuments(row, false)
                }}
              >
                <StarComponent>
                  {favoriteAction === 'exportLabels' ? (
                    <StarFull />
                  ) : (
                    <StarEmpty
                      onClick={(e) => {
                        e.stopPropagation()
                        changeFavouriteAction('exportLabels')
                      }}
                    />
                  )}
                </StarComponent>
                {'Export Labels'}
              </Button>
            </>
          )}
          {actionsToRender.custom.includes('addSupplierProduct') && (
            <Button
              type={'secondary'}
              onClick={() => {
                setShowActions(false)
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-addsupplierproduct-${row.id}`,
                )
              }}
            >
              <StarComponent>
                {favoriteAction === 'addSupplierProduct' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('addSupplierProduct')
                    }}
                  />
                )}
              </StarComponent>
              {'Add Product to Supplier Order'}
            </Button>
          )}
          {actionsToRender.custom.includes('orderLineAllTags') && (
            <Button
              type={'secondary'}
              onClick={() => {
                setShowActions(false)
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-orderLineAllTags-${row.id}`,
                )
              }}
            >
              <StarComponent>
                {favoriteAction === 'orderLineAllTags' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('orderLineAllTags')
                    }}
                  />
                )}
              </StarComponent>
              {'Assign Tag to all OrderLines'}
            </Button>
          )}
          {actionsToRender.custom.includes('editOrderLineProducts') && (
            <Button
              type={'secondary'}
              onClick={() => {
                setShowActions(false)
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-editOrderLineProducts-${id}-${row.id}`,
                )
              }}
            >
              <StarComponent>
                {favoriteAction === 'editOrderLineProducts' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('editOrderLineProducts')
                    }}
                  />
                )}
              </StarComponent>
              {'Edit Single Product'}
            </Button>
          )}
          {actionsToRender.custom.includes('removeOrderLineProducts') && (
            <Button
              type={'secondary'}
              onClick={() => {
                setShowActions(false)
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-removeOrderLineProducts-${id}-${row.id}`,
                )
              }}
            >
              <StarComponent>
                {favoriteAction === 'removeOrderLineProducts' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('removeOrderLineProducts')
                    }}
                  />
                )}
              </StarComponent>
              {'Remove This Product'}
            </Button>
          )}
          {actionsToRender.custom.includes('assignOrderLineTag') && (
            <Button
              type={'secondary'}
              onClick={() => {
                setShowActions(false)
                store.SlidingPanelState.setSelectedSlider(
                  `sliding-assignOrderLineTag-${id}-${row.id}`,
                )
              }}
            >
              <StarComponent>
                {favoriteAction === 'assignOrderLineTag' ? (
                  <StarFull />
                ) : (
                  <StarEmpty
                    onClick={(e) => {
                      e.stopPropagation()
                      changeFavouriteAction('assignOrderLineTag')
                    }}
                  />
                )}
              </StarComponent>
              {'Assign Tag to Orderline'}
            </Button>
          )}
          {!showMore && !allActions ? (
            <Button type={'secondary'} onClick={() => setShowMore(true)}>
              <b>{'Show more actions...'}</b>
            </Button>
          ) : (
            <></>
          )}
        </>
      )
    }

    useOutsideClick(containerRef, () => {
      if (width >= 600) {
        setShowActions(false)
        setShowMore(false)
      }
    })

    useEffect(() => {
      if (
        Object.prototype.hasOwnProperty.call(
          store.UserStore.defaultAction,
          tableName,
        )
      )
        //@ts-ignore
        setFavoriteAction(store.UserStore.defaultAction[tableName])
    }, [JSON.stringify(store.UserStore.defaultAction)])

    useEffect(() => {
      if (!showMore) {
        const tempActions = {
          display: true,
          label: actions.label,
          edit: null,
          create: null,
          remove: null,
          assignTag: null,
          custom: [],
        }
        const fields = ['edit', 'create', 'remove', 'assignTag']
        if (favoriteAction.length) {
          if (fields.includes(favoriteAction))
            //@ts-ignore
            tempActions[favoriteAction] = actions[favoriteAction]
          //@ts-ignore
          else tempActions.custom = [favoriteAction]
        } else {
          let count = 0
          fields.forEach((e) => {
            //@ts-ignore
            if (count === 0 && actions[e] !== null) {
              //@ts-ignore
              tempActions[e] = actions[e]
              count = count + 1
            }
          })
          if (count === 0) tempActions.custom = actions.custom[0]
        }
        setListedActions(tempActions)
      } else setListedActions(actions)
    }, [favoriteAction, showMore])

    if (listedActions)
      return (
        <>
          <Container ref={containerRef}>
            <SvgCont>
              <StyledIconDotsVertical
                size='16px'
                onClick={() => {
                  store.SlidingPanelState.setSelectedSlider(`sliding-${row.id}`)
                  setShowActions(!showActions)
                }}
              />
            </SvgCont>
            {showActions && width >= 600 && (
              <ActionList
                sliding={store.SlidingPanelState.visibility}
                showActions={showActions}
                {...Theme.tableMoreActionsStyles.actionList}
              >
                <ButtonsList allActions={false} />
              </ActionList>
            )}
          </Container>
          {showActions && width < 600 && (
            <SlidingPanel
              contentId={`sliding-${row.id}`}
              title={`Actions for ${actions.label}`}
            >
              <ButtonsList allActions={true} />
            </SlidingPanel>
          )}
          {actions.edit && (
            <SlidingPanel
              contentId={`sliding-edit-${row.id}`}
              title={`Edit ${actions.label}`}
            >
              <EditGenericComponent
                tableName={tableName}
                url={actions.edit}
                fetchData={fetchData}
                row={row}
                setLoaded={setLoaded}
                columns={values}
                toggleClick={() => setShowActions(false)}
                name={'Edit'}
              />
            </SlidingPanel>
          )}
          {actions.remove && (
            <SlidingPanel
              contentId={`sliding-remove-${row.id}`}
              title={`Remove ${actions.label}`}
            >
              <RemoveOrderComponent
                id={'test'}
                tableName={tableName}
                row={row}
                fetchData={fetchData}
                columns={values}
                toggleClick={() => setShowActions(false)}
                name={actions.label}
                url={actions.remove}
              />
            </SlidingPanel>
          )}
          {actions.assignTag && (
            <SlidingPanel
              contentId={`sliding-assigntag-${row.id}`}
              title={`Assign tag to ${actions.label}`}
            >
              <AssignTagGeneric
                id={'test'}
                tableName={tableName}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
                url={actions.assignTag}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('assignCollection') && actions.assignTag && (
            <SlidingPanel
              contentId={`sliding-assigncollection-${row.id}`}
              title={`Assign Collection to ${actions.label}`}
            >
              <AssignCollectionGenericComponent
                id={'test'}
                tableName={tableName}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
                url={actions.assignTag}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('addSupplierProduct') && (
            <SlidingPanel
              contentId={`sliding-addsupplierproduct-${row.id}`}
              title={`Add Product to ${actions.label}`}
            >
              <AddSupplierProduct
                id={'test'}
                row={row}
                columns={values}
                tableName={tableName}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('orderLineAllTags') && actions.assignTag && (
            <SlidingPanel
              contentId={`sliding-orderLineAllTags-${row.id}`}
              title={`Assign Tag to all Products`}
            >
              <AssignOrderLineTagGenericComponent
                id={'test'}
                tableName={tableName}
                row={row}
                columns={values}
                fetchData={fetchData}
                toggleClick={() => setShowActions(false)}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('editOrderLineProducts') && id && (
            <SlidingPanel
              contentId={`sliding-editOrderLineProducts-${id}-${row.id}`}
              title={`Edit Product`}
            >
              <EditRenderedProductComponent
                row={row}
                columns={values}
                tableRow={id}
              />
            </SlidingPanel>
          )}
          {actions.custom.includes('removeOrderLineProducts') && id && (
            <SlidingPanel
              contentId={`sliding-removeOrderLineProducts-${id}-${row.id}`}
              title={`Remove Product`}
            >
              <RemoveRenderedProduct row={row} tableRow={id} />
            </SlidingPanel>
          )}
          {actions.custom.includes('assignOrderLineTag') && id && (
            <SlidingPanel
              contentId={`sliding-assignOrderLineTag-${id}-${row.id}`}
              title={`Remove Product`}
            >
              <AssignOrderLineTagComponent row={row} tableRow={id} />
            </SlidingPanel>
          )}
        </>
      )
    else return <></>
  },
)

export default TableMoreActions
