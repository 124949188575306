import styled, { css } from 'styled-components'
import { breakpoints } from '../../static/breakpoints'
import { darken } from 'polished'

/* eslint-disable */
interface ContainerProps {
  padding?: string
  bgColor?: string
  loading: boolean
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  min-height: calc(100vh - 54px);
  position: relative;
  width: 100%;
  overflow-x: hidden !important;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  ${(props) => {
    if (!props.loading)
      return css`
        justify-content: center;
        align-items: center;
      `
  }}
`
export const HeaderCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

/* eslint-disable */

export const ReturnsButtonCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 32px;
`

interface ReturnsButtonInterface {
  selected: boolean
  view?: 'left' | 'right'
}

export const ReturnsButton = styled.button<ReturnsButtonInterface>`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #303030;
  padding: 8px 20px;
  margin-top: 16px;
  border-radius: ${(props) =>
    props.view === 'left'
      ? '6px 0 0 6px'
      : props.view === 'right'
        ? '0 6px 6px 0'
        : '0'};
  border: 1px solid ${(props) => (!props.selected ? '#575757' : '#D6D6D6')};
  background-color: ${(props) => (!props.selected ? '#F5F5F5' : '#FFFFFF')};
`
export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 35%;
  flex-grow: 2;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;
`

interface HeaderButtonProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const HeaderButton = styled.div<HeaderButtonProps>`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;
  margin-right: 16px;
  color: ${(props) => (props.color ? props.color : '#424242')};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};

  img {
    cursor: pointer;
    margin-right: 6px;
  }

  @media print {
    display: none;
  }
`
export const PrintSelectedButton = styled(HeaderButton)``

PrintSelectedButton.defaultProps = {
  onClick: () => {
    const selectedElements: NodeListOf<HTMLElement> =
      document.querySelectorAll('.hide-print')
    selectedElements.forEach((element) => {
      element.classList.add('no-print')
    })
    window.print()
    selectedElements.forEach((element) => {
      element.classList.remove('no-print')
    })
  },
}

interface HeaderButtonProps {
  border?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
}

/* eslint-disable */

export const SubTitleText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
  color: #808080;
`
export const Body = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  margin-top: 32px;
  margin-bottom: 40px;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }

  @media print {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 12px 0;
  }

  &.no-print {
    @media print {
      display: none !important;
      transform: scale(0.6);
    }
  }
`

export const StyledSelect = styled.select`
  border-radius: 4px;
  height: 42px;
  padding: 8px 2px;
  border: 1px solid #e5e5e5;
  background-color: white;
  width: 240px;
  color: rgba(26, 26, 23, 1);
  margin-left: 4px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.06);
`

export const MediaCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`

export const StyledMediaImg = styled.img`
  height: 200px;
  width: 220px;
  object-fit: cover;
`
export const StyledVideo = styled.video`
  cursor: pointer;
  width: 260px;
  height: 200px;
`
