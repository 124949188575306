import { observer } from 'mobx-react'
import {
  Container,
  DropDown,
  DropDownItem,
  FilterLabel,
  ImageCont,
  Input,
  TextCont,
} from './AddOrderSearch.styles'
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'
import axios from '../../axiosconfig'
import { StoreContext } from '../../App'

interface AddOrderSearchProps {
  applySearch: (order: any) => void
  clearInputValue?: boolean
}

const AddOrderSearchComponent = observer(
  ({ applySearch, clearInputValue }: AddOrderSearchProps) => {
    const store = useContext(StoreContext)
    const locales = store.TranslationsState.translations
    const [data, setData] = useState<any[]>([])
    const [inputValue, setInputValue] = useState<string>('')
    const [isInputFocused, setIsInputFocused] = useState(false)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    const searchRecommendedOrders = async (from = 0) => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_orders_view?size=10&from=${from}`

      const requestData = {
        filterGroups: {
          rules: [
            {
              field: 'client',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue,
            },
            {
              field: 'order_number',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue,
            },
            {
              field: 'order',
              operator: 'contains',
              valueSource: 'value',
              value: inputValue,
            },
          ],
          combinator: 'or',
          not: false,
        },
      }

      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }

      const response = await axios.post(url, requestData, config)

      if (response.status === 200) {
        setData((prevData) => [...prevData, ...response.data.orders])
      }
    }

    const handleScroll = useCallback(() => {
      if (!dropdownRef.current) return

      //const dropdownElement = dropdownRef.current

      /*if (
                                                                                            dropdownElement.scrollTop + dropdownElement.clientHeight >=
                                                                                            dropdownElement.scrollHeight
                                                                                          ) {
                                                                                            setCurrentPage((prevPage) => {
                                                                                              const newPage = prevPage + 1
                                                                                              searchRecommendedOrders(newPage * 10) // Use the updated page value directly here
                                                                                              return newPage
                                                                                            })
                                                                                          }*/
    }, [searchRecommendedOrders])

    useEffect(() => {
      if (inputValue.length >= 3) {
        setData([])
        //setCurrentPage(0)
        searchRecommendedOrders(0)
      }
    }, [inputValue])

    useEffect(() => {
      const dropDownElement = dropdownRef.current

      if (dropDownElement) {
        dropDownElement.addEventListener('scroll', handleScroll)
      }

      return () => {
        if (dropDownElement) {
          dropDownElement.removeEventListener('scroll', handleScroll)
        }
      }
    }, [handleScroll])

    return (
      <Container>
        <FilterLabel>{'Search order'}</FilterLabel>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={locales.buttons.minThreeChars}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setTimeout(() => setIsInputFocused(false), 100)}
        />
        {isInputFocused && inputValue.length >= 3 ? (
          <DropDown ref={dropdownRef}>
            {data.map((e: any, index: number) => (
              <DropDownItem
                key={`search-dropdown=${index}`}
                onClick={() => {
                  applySearch(e)
                  setInputValue(e.order)
                  if (clearInputValue) {
                    setInputValue('')
                  }
                }}
              >
                <TextCont>
                  <b>{e.order}</b>
                  <p>{e.order_number}</p>
                  <p>{e.client}</p>
                </TextCont>
              </DropDownItem>
            ))}
          </DropDown>
        ) : null}
      </Container>
    )
  },
)

export default AddOrderSearchComponent
