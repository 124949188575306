/* eslint-disable */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import {
  Cell,
  Column,
  useReactTable,
  getFilteredRowModel,
  getCoreRowModel,
  flexRender,
  ExpandedState,
  getExpandedRowModel,
} from '@tanstack/react-table'
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync'
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu'

import Headline from '../../headline/Headline.component'
import QueryFilter from '../../queryFilter/QueryFilter.component'
import ColumnVisibility from '../columnVisibility/ColumnVisibility.component'
import Paginator from '../paginator/Paginator.component'
import TableHeader from '../tableHeader/TableHeader.component'
import TableHeaderCheckboxes from '../tableHeaderCheckboxes/TableHeaderCheckboxes.component'
import TableBankExports from '../tableBankExports/TableBankExports.component'
import TableCell from '../tableCell/TableCell.component'
import TableCellFirstColumn from '../tableCellFirstColumn/TableCellFirstColumn.component'
import TableRendered from '../tableRendered/TableRendered.component'
import SearchFieldButton from '../searchFieldButton/SearchFieldButton.component'

import { StoreContext } from '../../../App'
import { SlidingPanel } from '../../slidingContainer/SlidingContainer.component'

import {
  MainCont,
  QueryRulesCont,
  SearchSwitchWrapper,
  SwitchCont,
  SwitchInput,
  SwitchInputText,
  SwitchStyled,
  TableBody,
  TableCont,
  TableFooter,
  TableFooterWrap,
  TableOptionsCont,
  TableRow,
  TableRowWrapper,
  UpperCont,
} from './Table.styles'
import { Container, Wrap } from '../../../styles/Loading.styles'

import ClearFilters from '../../../assets/icons/clear-filters-white.svg'
import FilterIcon from '../../../assets/icons/filter-icon.svg'
import TableOptions from '../../../assets/icons/table-options.svg'
import { IconChevronDown, IconSquareChevronDown } from '@tabler/icons-react'
import { Tooltip } from '@mui/material'
import Breadcrumb from '../../breadcrumb/Breadcrumb.component'
import { toJS } from 'mobx'
import TableActionsComponent from '../tableActions/TableActions.component'
import Plus from '../../../assets/icons/plus-white.svg'
import { CreateGeneric } from '../../createGeneric/CreateGeneric.component'
import ImportSelectComponent from '../../importSelect/ImportSelect.component'
import TableDefaultCellComponent from '../tableDefaultCell/tableDefaultCell.component'
import Footer from '../footer/Footer.component'
import Views from '../../views/Views.component'
import { toast } from 'react-toastify'
import { CreateReturn } from '../../createReturn/CreateReturn.component'

export interface TransactionsTableProps {
  config: {
    tableName: string
    data: Array<any>
    fetchData?: () => Promise<void>
    columnHeaders: {
      [e: string]: {
        Cell?: (cell: any) => JSX.Element
        Header?: string | JSX.Element
        filter?: string
        columns?: any
        Filter?: any
        canFilter?: boolean
        canSort?: boolean
        aggregate?: string
        Aggregated?: (val: any) => string
        editable?: boolean
        editType?: string
        dateFormat?: string
        mandatory?: boolean
        canBeNull?: boolean
        selectValues?: { value: string; label: string }[]
        arrayValues?: string[]
        mandatoryLanguage?: string
        regex?: string
        rules?: string[]
        isVisible?: boolean
      }
    }
    displayColumnVisibility: boolean
    allowColumnResize: boolean
    displaySearch: boolean
    displaySelect: boolean
    displayOrdinalList: boolean
    selectAllFunction?: () => Promise<void>
    displayExpand: boolean
    displayTooltip: boolean
    displayEntry: boolean
    displayPaginator: boolean
    displayInfinitySwitch: boolean
    allowSort: boolean
    bank?: boolean
    views?: boolean
    actions?: {
      display: boolean
      label: string
      edit: string | null
      create: string | null
      remove: string | null
      assignTag: string | null
      custom: any[]
    } | null
    actionsMultiple: {
      display: boolean
      label: string
      edit: string | null
      create: string | null
      remove: string | null
      assignTag: boolean
      custom: any[]
    } | null
    headline: {
      visible: boolean
      title: string
      subTitle: string
    }
    breadcrumb: Array<string>
    loaded: boolean
    setLoaded?: (loaded: boolean) => void
    importSelect?: boolean
  }
}

const Table = observer(({ config }: TransactionsTableProps) => {
  const {
    tableName,
    data,
    fetchData = async () => {},
    columnHeaders,
    displayColumnVisibility,
    allowColumnResize,
    displaySearch,
    displaySelect,
    selectAllFunction,
    displayExpand,
    displayTooltip,
    displayEntry,
    displayPaginator,
    displayOrdinalList,
    displayInfinitySwitch,
    allowSort,
    bank,
    actions,
    actionsMultiple,
    headline,
    views,
    breadcrumb,
    loaded,
    setLoaded,
    importSelect,
  } = config

  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [change, toggleChange] = useState(false)
  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const [tableLoaded, setTableLoaded] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState(
    store.ViewStore.hiddenColumns,
  )
  const [columnOrder, setColumnOrder] = useState(store.ViewStore.columnOrder)
  const pagination = store.PaginationStore.pagination[tableName] || {
    tableSize: 0,
    pageSize: 10,
    pageIndex: 1,
    totalCount: 0,
  }
  const containerRef = useRef<any>(null)

  const handleDefaultExpanded = () => {
    let expandObject = {}

    Object.keys(store.TableStore.expandedRows).map((e: string) => {
      const index = data.findIndex((i: any) => i.id === e)
      if (index !== -1) {
        expandObject = { [index.toString()]: true, ...expandObject }
      }
    })
    setExpanded(expandObject)
  }

  const columns: Column<object>[] = useMemo(() => {
    let baseColumns = Object.keys(columnHeaders).map((e) => {
      return {
        header: columnHeaders[e].Header ? columnHeaders[e].Header : e,
        accessorKey: e,
        defaultWidth: 70,
        size:
          JSON.parse(
            localStorage.getItem(
              `${store.RouteStore.currentPage}-columnWidths`,
            )!,
          )?.[e] || 70,
        id: e,
        ...(columnHeaders[e].Filter && { Filter: columnHeaders[e].Filter }),
        ...(columnHeaders[e].Header && { header: columnHeaders[e].Header }),
        ...(columnHeaders[e].filter && {
          filter: { name: columnHeaders[e].filter },
        }),
        ...(columnHeaders[e].Cell && { cell: columnHeaders[e].Cell }),
        ...(columnHeaders[e].columns && {
          columns: Object.keys(columnHeaders[e].columns).map((colKey: any) => {
            const col = columnHeaders[e].columns[colKey]
            return {
              header: col.header ? col.header : colKey,
              accessorKey: `${e}.${colKey}`,
              id: `${e}.${colKey}`,
              ...(col.Cell && { cell: col.Cell }),
              headerCustomOptions: {
                canFilter: col.canFilter !== false ? true : false,
                canSort: col.canSort !== false ? true : false,
                sort: {
                  desc: true,
                },
                settings: {
                  wrapText: !store.TableStore.clipTextColumns.includes(e),
                  color:
                    store.ViewStore.columnColors.find((c) => c.column === e)
                      ?.color || 'transparent',
                  border:
                    store.ViewStore.columnBorder.find((c) => c.column === e)
                      ?.border || false,
                },
              },
              editCustomOptions: {
                ...(col.hasOwnProperty('editable') && {
                  editable: col.editable,
                }),
                ...(col.editType && {
                  editType: col.editType,
                }),
                ...(col.mandatory && {
                  mandatory: col.mandatory,
                }),
                ...(col.canBeNull && {
                  canBeNull: col.canBeNull,
                }),
                ...(col.selectValues && {
                  selectValues: col.selectValues,
                }),
                ...(col.dateFormat && {
                  dateFormat: col.dateFormat,
                }),
                ...(col.arrayValues && {
                  arrayValues: col.arrayValues,
                }),
                ...(col.mandatoryLanguage && {
                  mandatoryLanguage: col.mandatoryLanguage,
                }),
              },
            }
          }),
        }),

        ...(columnHeaders[e].aggregate && {
          aggregate: columnHeaders[e].aggregate,
        }),
        ...(columnHeaders[e].Aggregated && {
          Aggregated: columnHeaders[e].Aggregated,
        }),
        ...(columnHeaders[e].isVisible && {
          isVisible: columnHeaders[e].isVisible,
        }),
        disableSortBy: !allowSort,
        headerCustomOptions: {
          canFilter: columnHeaders[e].canFilter !== false ? true : false,
          canSort: columnHeaders[e].canSort !== false ? true : false,
          sort: {
            desc: true,
          },
          settings: {
            wrapText: !store.TableStore.clipTextColumns.includes(e),
            color:
              store.ViewStore.columnColors.find((c) => c.column === e)?.color ||
              'transparent',
            border:
              store.ViewStore.columnBorder.find((c) => c.column === e)
                ?.border || false,
          },
        },
        editCustomOptions: {
          ...(columnHeaders[e].hasOwnProperty('editable') && {
            editable: columnHeaders[e].editable,
          }),
          ...(columnHeaders[e].editType && {
            editType: columnHeaders[e].editType,
          }),
          ...(columnHeaders[e].mandatory && {
            mandatory: columnHeaders[e].mandatory,
          }),
          ...(columnHeaders[e].canBeNull && {
            canBeNull: columnHeaders[e].canBeNull,
          }),
          ...(columnHeaders[e].selectValues && {
            selectValues: columnHeaders[e].selectValues,
          }),
          ...(columnHeaders[e].dateFormat && {
            dateFormat: columnHeaders[e].dateFormat,
          }),
          ...(columnHeaders[e].arrayValues && {
            arrayValues: columnHeaders[e].arrayValues,
          }),
          ...(columnHeaders[e].mandatoryLanguage && {
            mandatoryLanguage: columnHeaders[e].mandatoryLanguage,
          }),
          ...(columnHeaders[e].regex && {
            regex: columnHeaders[e].regex,
          }),
          ...(columnHeaders[e].rules && {
            rules: columnHeaders[e].rules,
          }),
        },
      }
    })

    if (displayOrdinalList) {
      baseColumns = [
        {
          id: '_lp',
          accessorKey: '_lp',
          disableSortBy: true,
          headerCustomOptions: {
            canFilter: false,
            canSort: false,
            sort: {
              desc: true,
            },
            settings: {
              wrapText: true,
              color: 'transparent',
              border: false,
            },
          },
          editCustomOptions: {},
          minSize: 40,
          size: 40,
          maxSize: 40,
          header: 'Lp.',
          cell: (cell: any) => (
            <div>
              {cell.row.index +
                1 +
                (pagination.pageIndex && pagination.pageSize
                  ? (pagination.pageIndex - 1) * pagination.pageSize
                  : 0)}
            </div>
          ),
        },
        ...baseColumns,
      ]
    }

    if (displaySelect) {
      baseColumns = [
        {
          id: 'selection',
          accessorKey: 'selection',
          disableSortBy: true,
          headerCustomOptions: {
            canFilter: false,
            canSort: false,
            sort: {
              desc: true,
            },
            settings: {
              wrapText: false,
              color: 'transparent',
              border: false,
            },
          },
          editCustomOptions: {},
          minSize: 92,
          size: 92,
          maxSize: 92,
          header: () => (
            <TableHeaderCheckboxes
              selectAllFunction={selectAllFunction}
              data={data}
              actions={actions}
            />
          ),
          cell: (props: any) => (
            <TableCellFirstColumn
              row={props.row}
              rowData={data[props.row.id]}
              displayTooltip={displayTooltip}
              displayEntry={displayEntry}
              displayExpand={displayExpand}
            />
          ),
        },
        ...baseColumns,
      ]
    }

    if (actions) {
      baseColumns.push({
        id: '_options',
        accessorKey: '_options',
        minSize: 40,
        size: 40,
        maxSize: 100,
        disableSortBy: true,
        editCustomOptions: {
          editable: false,
        },
        headerCustomOptions: {
          canFilter: false,
          canSort: false,
          sort: {
            desc: true,
          },
          settings: {
            wrapText: false,
            color: 'transparent',
            border: false,
          },
        },
        header: () => <></>,
        cell: ({ row }: any) => {
          return actions ? (
            <TableActionsComponent
              tableName={tableName}
              actions={actions}
              loaded={loaded}
              setLoaded={setLoaded}
              columns={Object.keys(columnHeaders)}
              row={row}
              fetchData={fetchData}
            />
          ) : (
            <></>
          )
        },
      })
    }

    return baseColumns
  }, [
    displaySelect,
    data,
    store.TableStore.selectedRowId.length,
    store.TableStore.allSelectedRows,
    store.TableStore.selectedRowId,
    store.TableStore.selectedExpanded,
    store.TableStore.expandedRows,
    Object.keys(store.TableStore.expandedRows).length,
  ])

  const table = useReactTable({
    columns,
    data,
    columnResizeMode: 'onChange',
    state: {
      columnVisibility,
      expanded,
      columnOrder,
    },
    defaultColumn: {
      minSize: 70,
      size: 70,
      maxSize: 400,
      cell: (cell: any) => <TableDefaultCellComponent cell={cell} />,
      headerCustomOptions: {
        sort: {
          desc: true,
        },
        settings: {
          wrapText: true,
          color: 'transparent',
          border: false,
        },
      },
      editCustomOptions: {},
    },
    initialState: {
      columnVisibility: store.ViewStore.hiddenColumns,
      columnOrder: store.ViewStore.columnOrder,
      //selectedRowIds: handleDefaultRowSelect(),
      //expanded: handleDefaultExpanded(),
    },
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    //useFilters,
  })

  const setInitialActions = async () => {
    await store.RouteStore.handlePageChange(window.location.search.substring(1))
    if (displayPaginator)
      await store.PaginationStore.setCurrentPagination(tableName)

    if (views) {
      const viewList = await store.ViewStore.getViews(tableName)
      await store.ViewStore.getViewsList(viewList, true)
      await store.ViewStore.getDefaultView(tableName)
    }

    const sessionView = sessionStorage.getItem(`tempView-${tableName}`)
    const sessionQuery = sessionStorage.getItem(`tempQuery-${tableName}`)
    const redirectQuery = store.FiltersStore.getQueryFromRedirect()
    const defaultViewId = views ? store.ViewStore.defaultView : null
    const defaultQueryId = store.FiltersStore.getDefaultFilter()
    const filter = store.FiltersStore.allFilters.find(
      (f: any) => f.id === defaultQueryId,
    )

    if (!defaultViewId) store.ViewStore.setSelectedView('')

    if (redirectQuery) {
      store.RouteStore.setQueryHandler(false)
      store.FiltersStore.setQuery(redirectQuery)
    } else if (sessionView || sessionQuery) {
      if (sessionView)
        await store.ViewStore.applySessionView(JSON.parse(sessionView))
      if (sessionQuery) store.FiltersStore.setQuery(JSON.parse(sessionQuery))
    } else if (defaultViewId) {
      await store.ViewStore.applyView(defaultViewId)
    } else if (filter && defaultQueryId) {
      store.FiltersStore.setQuery(filter.action.query)
      store.FiltersStore.applyDefaultAndPredefinedQuery(defaultQueryId, 'empty')
    } else {
      store.FiltersStore.setEmptyQuery()
    }

    store.FiltersStore.applyQuery()

    if (displayPaginator && !store.PaginationStore.pagination[tableName]) {
      const selectedView = store.ViewStore.allViews.find(
        (e) => e.id === store.ViewStore.selectedView,
      )
      const tableSize = selectedView ? selectedView.tableSize : 10
      console.log(selectedView, tableSize)
      await store.PaginationStore.setPagination(tableName, 10, tableSize, 1, 10)
      await store.PaginationStore.initPagination(tableName, tableSize)
    }

    await fetchData()
  }

  useEffect(() => {
    if (store.UserStore.mainLoaded) setInitialActions()
    //setSelectedRows(selectedRowIds)
    return () => {
      store.ViewStore.clearValues()
      setExpanded({})
      if (setLoaded) setLoaded(false)
    }
  }, [])

  useEffect(() => {
    if (tableLoaded)
      sessionStorage.setItem(
        `tempQuery-${tableName}`,
        JSON.stringify(store.FiltersStore.appliedQuery),
      )

    if (views && loaded && tableLoaded) {
      const tempView = store.ViewStore.createTemporaryViewObject(
        tableName,
        pagination?.pageSize,
      )
      sessionStorage.setItem(`tempView-${tableName}`, JSON.stringify(tempView))
    }
  }, [
    store.ViewStore.hiddenColumns,
    store.ViewStore.columnOrder,
    store.ViewStore.columnColors,
    store.ViewStore.columnBorder,
    store.TableStore.clipTextColumns,
    store.FiltersStore.appliedPredefinedQuery,
    store.FiltersStore.appliedQuery,
    store.TableStore.sort.column,
    store.TableStore.sort.desc,
    store.TableStore.sort.type,
    store.UserStore.defaultAction,
    store.TableStore.infinitySwitch,
    store.TableStore.showSearchField,
  ])

  useEffect(() => {
    setColumnVisibility(store.ViewStore.hiddenColumns)
  }, [store.ViewStore.hiddenColumns])

  useEffect(() => {
    setColumnOrder(store.ViewStore.columnOrder)
  }, [store.ViewStore.columnOrder])

  useEffect(() => {
    if (loaded) handleDefaultExpanded()
    if (!loaded) setExpanded({})
  }, [loaded])

  useEffect(() => {
    toggleChange(!change)
  }, [store.TableStore.selectedRowId.length])

  useEffect(() => {
    store.ViewStore.setColumnOrder(columnOrder)
    toggleChange(!change)
    store.ViewStore.setHiddenColumns(tableName, columnVisibility)
  }, [columnVisibility])

  useEffect(() => {
    store.ViewStore.setColumnOrder(columnOrder)
  }, [columnOrder])

  useEffect(() => {
    toggleChange(!change)
    if (Object.keys(table.getState().columnSizing).length > 0) {
      localStorage.setItem(
        `${store.RouteStore.currentPage}-columnWidths`,
        JSON.stringify(table.getState().columnSizing),
      )
    }
  }, [table.getState().columnSizing])

  useEffect(() => {
    if (store.TableStore.selectedRowId.length !== 0)
      store.TableStore.setSelectedExpanded([])
  }, [store.TableStore.selectedRowId.length])

  useEffect(() => {
    setTableLoaded(true)
  }, [data])

  const loadingRef = useRef(false)
  const newPageSizeRef = useRef(10)

  const handleScroll = useCallback(async () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement
    if (
      scrollTop + clientHeight >= scrollHeight &&
      !loadingRef.current &&
      store.TableStore.infinitySwitch
    ) {
      const prevScrollTop = scrollTop
      newPageSizeRef.current += 10 // Increment page size by 10
      loadingRef.current = true
      store.TableStore.setIsInfinityLoading(true)
      await store.PaginationStore.setPagination(
        tableName,
        pagination.tableSize,
        newPageSizeRef.current,
        pagination.pageIndex,
        pagination.totalCount,
        true, // Assuming this parameter indicates appending data
      )
      await fetchData()
      store.TableStore.setIsInfinityLoading(false)
      loadingRef.current = false
      document.documentElement.scrollTop = prevScrollTop
    }
  }, [
    store.TableStore.infinitySwitch,
    store.TableStore,
    fetchData,
    tableName,
    pagination.pageSize,
    pagination.tableSize,
    pagination.pageIndex,
    pagination.totalCount,
  ])

  useEffect(() => {
    if (store.TableStore.infinitySwitch) {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, store.TableStore.infinitySwitch])

  useEffect(() => {
    newPageSizeRef.current = pagination.pageSize
  }, [pagination.pageSize])

  return (
    <>
      <MainCont>
        <SearchSwitchWrapper>
          <div>
            {headline.visible ? (
              <Headline title={headline.title} subTitle={headline.subTitle} />
            ) : (
              <></>
            )}
            <Breadcrumb treeArray={breadcrumb} />
          </div>
          <UpperCont>
            {actions?.create ? (
              <SearchFieldButton
                text={`Create New ${actions?.label}`}
                onClick={() => {
                  store.SlidingPanelState.setSelectedSlider(
                    `table-create-${tableName}`,
                  )
                }}
                icon={<img src={Plus} />}
              />
            ) : (
              <></>
            )}
            {actions?.custom.includes('createReturn') ? (
              <SearchFieldButton
                text={`Create New ${actions?.label}`}
                onClick={() => {
                  store.SlidingPanelState.setSelectedSlider(
                    `table-createReturn-${tableName}`,
                  )
                }}
                icon={<img src={Plus} />}
              />
            ) : (
              <></>
            )}
            {store.FiltersStore.appliedQuery.rules.length > 0 &&
            store.RouteStore.currentDataId === '' ? (
              <QueryRulesCont>
                <Tooltip
                  arrow
                  title={`Filters: ${store.FiltersStore.appliedQuery.rules.map(
                    (e: any) => {
                      if (e.field && e.operator && e.value) {
                        return `${e.field} ${e.operator} ${e.value}, `
                      }
                    },
                  )}`}
                >
                  <div>
                    <SearchFieldButton
                      text={locales.buttons.clearFilters}
                      onClick={async () => {
                        await store.FiltersStore.clearPredefinedQuery()
                        await store.FiltersStore.applyQuery()
                        await fetchData()
                      }}
                      icon={<img src={ClearFilters} />}
                    />
                  </div>
                </Tooltip>
              </QueryRulesCont>
            ) : (
              <></>
            )}
            {bank ? <TableBankExports data={data} /> : <></>}
            {(displaySelect || displayColumnVisibility) && loaded ? (
              <TableOptionsCont>
                <img
                  onClick={() =>
                    store.SlidingPanelState.setSelectedSlider('optionsList')
                  }
                  src={TableOptions}
                />
              </TableOptionsCont>
            ) : (
              <></>
            )}
          </UpperCont>
        </SearchSwitchWrapper>{' '}
        {(loaded && tableLoaded) || store.TableStore.isInfinityLoading ? (
          <ScrollSync>
            <TableCont
              className={'hide-print'}
              ref={containerRef}
              {...Theme.tableStyles.tableCont}
            >
              <TableHeader
                table={table}
                tableName={tableName}
                loaded={loaded}
                columnWidth={allowColumnResize}
                filterSwitch={store.TableStore.showSearchField}
                search={displaySearch}
                change={change}
                toggleChange={toggleChange}
                setColumnOrder={setColumnOrder}
                fetchData={fetchData}
                infinity={store.TableStore.infinitySwitch}
                {...Theme.tableStyles.tableHead}
              />
              <TableBody>
                {table.getRowModel().rows.map((row, index) => (
                  <TableRowWrapper expanded={row.getIsExpanded()} key={row.id}>
                    <ScrollSyncPane>
                      <TableRow
                        select={store.TableStore.selectedRowId.includes(
                          data[index].id,
                        )}
                        odd={index % 2 == 0}
                        key={'row' + index}
                        {...Theme.tableStyles.tableRow}
                      >
                        {row.getVisibleCells().map((cell: any, idx: number) => (
                          <TableCell
                            change={change}
                            cell={cell}
                            key={idx}
                            key2={idx}
                            column={columns[index]}
                            row={row}
                          />
                        ))}
                      </TableRow>
                    </ScrollSyncPane>
                    {row.getIsExpanded() && (
                      <TableRendered
                        id={
                          store.TableStore.pagination[
                            store.RouteStore.currentPage
                          ].pageIndex *
                            store.TableStore.pagination[
                              store.RouteStore.currentPage
                            ].pageSize +
                          index
                        }
                        row={row}
                        selectedMainRows={store.TableStore.selectedRowId.length}
                        //@ts-ignore
                        data={data[index].products}
                        change={change}
                        toggleChange={toggleChange}
                      />
                    )}
                  </TableRowWrapper>
                ))}
              </TableBody>
              <TableFooterWrap
                selected={
                  store.TableStore.selectedRowId.length > 0 ||
                  store.TableStore.selectedExpanded.length > 0 ||
                  store.TableStore.allSelectedRows
                }
              >
                {table.getFooterGroups().map((footerGroup: any, index) => {
                  if (index == 0)
                    return (
                      <ScrollSyncPane key={footerGroup.id}>
                        <TableFooter>
                          {footerGroup.headers.map(
                            (columns: any, i: number) => {
                              return (
                                <div
                                  style={{
                                    width: columns.column.columnDef.size,
                                  }}
                                  key={columns.id}
                                ></div>
                              )
                            },
                          )}
                        </TableFooter>
                      </ScrollSyncPane>
                    )
                })}
              </TableFooterWrap>
            </TableCont>
          </ScrollSync>
        ) : (
          <Wrap>
            <Container></Container>
          </Wrap>
        )}
      </MainCont>
      {store.TableStore.isInfinityLoading ? (
        <Wrap>
          <Container></Container>
        </Wrap>
      ) : (
        <></>
      )}
      {displayPaginator ? (
        <Paginator
          tableName={tableName}
          fetchData={fetchData}
          loaded={loaded}
          setLoaded={setLoaded}
          infinity={store.TableStore.infinitySwitch}
        />
      ) : (
        <></>
      )}
      {displaySelect && actionsMultiple ? (
        <Footer
          tableName={tableName}
          columns={columns}
          fetchData={fetchData}
          actions={actions}
          actionsMultiple={actionsMultiple}
        />
      ) : (
        <></>
      )}
      {actions?.create ? (
        <SlidingPanel
          contentId={`table-create-${tableName}`}
          title={`Create New ${actions?.label}`}
          icon={FilterIcon}
        >
          <CreateGeneric
            tableName={tableName}
            url={actions.create}
            columns={columns}
            name={'Create'}
            setLoaded={setLoaded}
            fetchData={fetchData}
          />
        </SlidingPanel>
      ) : (
        <></>
      )}
      {actions?.custom.includes('createReturn') ? (
        <SlidingPanel
          contentId={`table-createReturn-${tableName}`}
          title={`Create New`}
          icon={FilterIcon}
        >
          <CreateReturn
            tableName={tableName}
            columns={columns}
            name={'Create'}
            setLoaded={setLoaded}
            fetchData={fetchData}
          />
        </SlidingPanel>
      ) : (
        <></>
      )}
      {displaySearch || displayColumnVisibility ? (
        <>
          <SlidingPanel
            contentId={'optionsList'}
            title={'Options List'}
            icon={FilterIcon}
          >
            <Menu
              mode='inline'
              style={{ margin: 0, width: '100%' }}
              expandIcon={<IconChevronDown size='18px' />}
              defaultOpenKeys={['3']}
            >
              {displaySearch ? (
                <SubMenu title={locales.options.tableButtons} key={'1'}>
                  <SwitchStyled {...Theme.tableStyles.switchStyled}>
                    <SwitchCont>
                      <SwitchInput
                        onColor={'#016220'}
                        offColor={'#900d09'}
                        checked={!store.TableStore.showSearchField}
                        onChange={() =>
                          store.TableStore.setShowSearchField(
                            !store.TableStore.showSearchField,
                          )
                        }
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={42}
                        handleDiameter={16}
                      />{' '}
                      <SwitchInputText>
                        {locales.actions.search}
                      </SwitchInputText>
                    </SwitchCont>
                    {displayInfinitySwitch ? (
                      <SwitchCont>
                        <SwitchInput
                          onColor={'#016220'}
                          offColor={'#900d09'}
                          checked={store.TableStore.infinitySwitch}
                          onChange={() =>
                            store.TableStore.setInfinitySwitch(
                              !store.TableStore.infinitySwitch,
                            )
                          }
                          uncheckedIcon={false}
                          checkedIcon={false}
                          height={20}
                          width={42}
                          handleDiameter={16}
                        />{' '}
                        <SwitchInputText>
                          {'Set Infinity Scroll'}
                        </SwitchInputText>
                      </SwitchCont>
                    ) : (
                      <></>
                    )}
                  </SwitchStyled>
                </SubMenu>
              ) : (
                <></>
              )}
              <SubMenu title={locales.options.columnVisibility} key={'2'}>
                {displayColumnVisibility ? (
                  <ColumnVisibility change={change} table={table} />
                ) : null}
              </SubMenu>
              {importSelect ? (
                <SubMenu title={'Select Rows by File'} key={'4'}>
                  <ImportSelectComponent allColumns={columns} />
                </SubMenu>
              ) : (
                <></>
              )}
              {views ? (
                <SubMenu title={'Manage Views'} key={'5'}>
                  <Views
                    tableName={tableName}
                    pagination={pagination?.pageSize}
                    fetchData={fetchData}
                    setLoaded={setLoaded}
                  />
                </SubMenu>
              ) : (
                <></>
              )}
              {displaySearch ? (
                <SubMenu title={locales.options.queryFilers} key={'3'}>
                  <QueryFilter
                    table={table}
                    tableName={tableName}
                    allColumns={columns}
                    setLoaded={setLoaded}
                    fetchData={fetchData}
                  />
                </SubMenu>
              ) : (
                <></>
              )}
            </Menu>
          </SlidingPanel>
        </>
      ) : null}
    </>
  )
})
export default Table
