import Table from '../../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'
import TableBool from '../../_tableGeneric/TableBool/TableBool.component'
/* eslint-disable */

const CurrenciesTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: true,
      mandatory: true,
    },
    manual_rate: {
      canFilter: false,
      editable: true,
      editType: 'number',
      canBeNull: false,
      mandatory: true,
    },
    auto_rate: {
      canFilter: false,
      editable: false,
      editType: 'number',
      canBeNull: false,
      mandatory: true,
    },
    rate: {
      canFilter: false,
      editable: false,
      editType: 'number',
      canBeNull: false,
      mandatory: true,
    },
    active: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      canSort: false,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    auto_exchange: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      canSort: false,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
  }

  useEffect(() => {
    store.StoreSettingsStore.fetchData('currencies')
    return () => store.StoreSettingsStore.clearData()
  }, [])

  return (
    <Table
      config={{
        tableName: 'currencies',
        data: store.StoreSettingsStore.data || [],
        fetchData: async () =>
          await store.StoreSettingsStore.fetchData('currencies'),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: {
          display: true,
          label: locales.tableLabels.currencies,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/currencies?identifier=`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/currencies?identifier=_create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/currencies`,
          assignTag: null,
          custom: [],
        },
        //actions: null,
        actionsMultiple: null,

        headline: {
          visible: true,
          title: 'List of Currencies',
          subTitle: 'List of Currencies',
        },
        breadcrumb: [],
        loaded: store.StoreSettingsStore.loaded,
      }}
    ></Table>
  )
})
export default CurrenciesTable
