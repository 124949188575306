/* eslint-disable */
import { observer } from 'mobx-react'
import React, {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { StoreContext } from '../../App'
import { hiddenColumns } from '../../columnsConfig'
import { validate } from '../../static/validate'
import {
  Checkbox,
  Container,
  EqualDiv,
  ErrorMessage,
  FilterLabel,
  Input,
  InputCont,
  StyledSelect,
  SwitchCont,
  SwitchInput,
} from './DataInputGeneric.styles'
import { ColorInput } from '../_settings/tags/edits/colorInput/ColorInput.component'
import { ObjectInput } from '../_settings/tags/edits/objectInput/ObjectInput.component'
import SearchTagComponent from '../_settings/tags/edits/searchTag/SearchTag.component'
import { StringSelectInput } from '../_settings/tags/edits/stringArraySelect/StringArraySelect.component'
import ArrayObjectInput from '../_settings/tags/edits/arrayObjectInput/ArrayObjectInput.component'
import { toJS } from 'mobx'
import SearchTagNewComponent from '../_settings/tags/edits/searchTagNew/SearchTagNew.component'
import { toast } from 'react-toastify'
import { StringArraySelectWithLabel } from '../_settings/tags/edits/stringArraySelectWithLabel/StringArraySelectWithLabel.component'

interface InnerProps extends PropsWithChildren {
  type: string
  vals: { header: string; value: any }
  editOptions: {
    editable: boolean
    editType?: string
    mandatory?: boolean
    regex?: string
    selectValues?: { value: string; label: string }[]
    arrayValues?: string[]
    dateFormat?: string
    canBeNull?: boolean
    mandatoryLanguage?: string
  }
  form: Array<{
    header: string
    value: any
    error: string
    rules: Array<string>
    isActive: boolean
  }>
  index: number

  setForm: (
    val: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => void
  triggerChange?: () => void
}

export const DataInputGeneric = observer(
  ({
    type,
    vals,
    index,
    form,
    setForm,
    triggerChange,
    editOptions,
  }: InnerProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    const mandatory = type === 'create' && editOptions.mandatory === true
    const [active, setActive] = useState(mandatory)
    const [changeForValidation, setChangeForValidation] = useState(false)

    const formEqual = form.find((e: any) => e.header === 'equal')
    const formEqualIndex = form.findIndex((e) => e.header === 'equal')
    const changeHandler = (val: any) => {
      const tempForm = form
      tempForm[index] = {
        header: vals.header,
        value: val,
        error: form[index].error,
        rules: form[index].rules,
        isActive: active,
      }
      setForm(tempForm)
    }

    const getInputType = () => {
      switch (editOptions.editType) {
        case 'text':
        default:
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value || ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                editOptions.editType === 'number'
                  ? changeHandler(Number(el.target.value))
                  : changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'number':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value !== null ? form[index].value : ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                const value = el.target.value
                if (value === '' && editOptions.canBeNull) {
                  changeHandler(null)
                } else {
                  changeHandler(Number(value))
                }

                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'date':
        case 'datetime-local':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={store.TranslationsState.dateFormatter(
                form[index].value,
                editOptions.editType,
                editOptions.dateFormat,
              )}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                changeHandler(
                  store.TranslationsState.dateDeformatter(
                    el.target.value,
                    editOptions.editType,
                    editOptions.dateFormat,
                  ),
                )
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'numberEqual':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={'number'}
              disabled={!active}
              activeStyle={active}
              value={form[index].value !== null ? form[index].value : ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'color':
          return (
            <ColorInput
              vals={form[index]}
              index={index}
              form={form}
              setForm={setForm}
              active={active}
            />
          )
        case 'filter':
          return (
            <Input
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              type={editOptions.editType}
              disabled={!active}
              activeStyle={active}
              value={form[index].value.label || ''}
              onChange={(el: ChangeEvent<HTMLInputElement>) => {
                const tempForm = form[index].value
                tempForm.label = el.target.value
                changeHandler(tempForm)
                if (triggerChange) {
                  triggerChange()
                }
              }}
              {...Theme.editProductsStyles.styledInput}
            />
          )
        case 'languageObject':
          return (
            <ObjectInput
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
              data={form[index].value}
              defaultHeader={editOptions.mandatoryLanguage || 'EN'}
              active={active}
              notCutLang={
                //@ts-ignore
                editOptions.mandatoryLanguage?.length > 3 ? true : false
              }
            />
          )
        case 'tagSearch':
          return (
            <SearchTagNewComponent
              header={vals.header}
              applySearch={(e) => changeHandler(e.id)}
              view={'tag'}
              active={active}
            />
          )
        case 'rootSearch':
          return (
            <SearchTagComponent
              header={vals.header}
              applySearch={(e) => changeHandler(e.id)}
              view={'root'}
              active={active}
            />
          )
        case 'groupSearch':
          return (
            <SearchTagComponent
              header={vals.header}
              applySearch={(e) => changeHandler(e.id)}
              view={'group'}
              active={active}
            />
          )
        case 'stringArraySelect':
          return (
            <StringSelectInput
              data={form[index].value}
              dataList={editOptions.arrayValues || []}
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
              active={active}
            />
          )
        case 'stringArraySelectWithLabel':
          return (
            <StringArraySelectWithLabel
              data={form[index].value}
              dataList={editOptions.selectValues || []}
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
              active={active}
            />
          )
        case 'salesChannels':
          return (
            <ArrayObjectInput
              data={form[index].value}
              header={vals.header}
              index={index}
              form={form}
              handleChange={setForm}
              active={active}
            />
          )
        case 'select':
          return (
            <StyledSelect
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              disabled={!active}
              activeStyle={active}
              onChange={(el) => {
                changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
            >
              {editOptions.selectValues?.map((e: any, i: number) => (
                <option
                  key={`option-=${i}-${index}-${e.label}`}
                  value={e.value}
                  selected={vals.value !== null && vals.value == e.value}
                >
                  {e.label}
                </option>
              ))}
            </StyledSelect>
          )
        case 'selectBool': {
          let value =
            typeof vals.value === 'boolean' ? vals.value : vals.value === 'true'
          return (
            <StyledSelect
              id={`input-${store.RouteStore.currentPage}-${vals.header}`}
              disabled={!active}
              activeStyle={active}
              onChange={(el) => {
                changeHandler(el.target.value)
                if (triggerChange) {
                  triggerChange()
                }
              }}
            >
              <option value={'true'} selected={value.toString() === 'true'}>
                {'True'}
              </option>
              <option value={'false'} selected={value.toString() === 'false'}>
                {'False'}
              </option>
            </StyledSelect>
          )
        }
      }
    }

    const getEqual = () => {
      switch (editOptions.editType) {
        case 'numberEqual':
          return true
        default:
          return false
      }
    }

    useEffect(() => {
      if (!active) {
        const tempForm = form
        tempForm[index].error = ''
        setForm(tempForm)
      } else {
        let error = validate(form[index].rules, form[index].value)
        if (editOptions.editType === 'text' && editOptions.regex) {
          const regex = new RegExp(editOptions.regex)
          if (!regex.test(form[index].value)) {
            error = `Invalid format: does not match ${editOptions.regex}`
          }
        }
        const tempForm = form
        tempForm[index].error = error
        setForm(tempForm)
      }
    }, [changeForValidation, form[index].value])

    return (
      <Container>
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {/*{locales[`${store.RouteStore.currentPage}Columns`][vals.header] ||
              vals.header}*/}
          {vals.header + (mandatory ? '*' : '')}
        </FilterLabel>
        <InputCont>
          {getInputType()}
          <SwitchCont>
            <p>{!active ? 'Unlock' : 'Lock'}</p>
            <SwitchInput
              onColor={'#016220'}
              offColor={'#900d09'}
              checked={active}
              onChange={() => {
                if (!mandatory) setActive(!active)
                store.TableStore.toggleDataInput()
                if (triggerChange) {
                  triggerChange()
                }
                setChangeForValidation(!changeForValidation)
                const tempForm = form
                form[index].isActive = !active
                setForm(tempForm)
              }}
              uncheckedIcon={false}
              checkedIcon={false}
              height={24}
              width={42}
            />
          </SwitchCont>

          {getEqual() && formEqual && formEqualIndex ? (
            <EqualDiv>
              <Checkbox
                type={'checkbox'}
                name={`${form[index].header}`}
                checked={formEqual ? formEqual.value : false}
                onChange={(e) => {
                  const tempForm = form
                  tempForm[formEqualIndex].value = e.currentTarget.checked
                  setForm(tempForm)
                  if (triggerChange) {
                    triggerChange()
                  }
                }}
                {...Theme.editProductsStyles.styledCheckbox}
              />
              <p>{'Equal'}</p>
            </EqualDiv>
          ) : (
            <></>
          )}
        </InputCont>
        {form[index].error ? (
          <ErrorMessage>{form[index].error}</ErrorMessage>
        ) : (
          <></>
        )}
      </Container>
    )
  },
)
