/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import { TitleText } from '../../components/reports/timeline/Timeline.styles'
import {
  Body,
  Container,
  HeaderCont,
  MediaCont,
  ReturnsButton,
  ReturnsButtonCont,
  StyledMediaImg,
  StyledSelect,
  StyledVideo,
  SubTitleText,
} from './ReturnsDetails.styles'
import { useParams } from 'react-router-dom'
import {
  Container as LoadingContainer,
  Wrap,
} from '../../styles/Loading.styles'
import ReturnsDetailsTable from '../../components/returnsDetailsTable/ReturnsDetailsTable.component'
import Button from '../../components/button/Button.component'
import { Label, Setting, SettingsCategory } from '../reports/reports.styles'
import { toast } from 'react-toastify'

const ReturnsDetails = observer(() => {
  const store = useContext(StoreContext)
  const params = useParams()
  const [totalReturnValue, setTotalReturnValue] = useState(0)
  const [acceptAll, setAcceptAll] = useState(true)

  const {
    selectedProducts,
    setSelectedProducts,
    decisionsList,
    initialDetailsData,
    setDetailsData,
    detailsData,
    detailsProducts,
    loaded,
    change,
  } = store.ReturnsStore

  const orderDetails = store.TableStore.detailsData

  const [selectedDecision, setSelectedDecision] = useState('exchange')

  const renderMediaPreview = () => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg']
    if (!detailsData?.mediaFiles || detailsData.mediaFiles.length === 0) {
      return <SubTitleText>No media files were included by user</SubTitleText>
    }

    return detailsData.mediaFiles.map((fileUrl, index) => {
      const extension = fileUrl.split('.').pop()?.split('?')[0].toLowerCase()
      const isImage = extension ? imageExtensions.includes(extension) : false
      return (
        <div key={index}>
          {isImage ? (
            <StyledMediaImg
              src={fileUrl}
              alt='upload preview'
              width={100}
              height={100}
            />
          ) : (
            <StyledVideo width={260} height={200} controls>
              <source src={fileUrl} type={`video/${extension}`} />
            </StyledVideo>
          )}
        </div>
      )
    })
  }

  useEffect(() => {
    if (params.id) {
      store.ReturnsStore.fetchDetailsData(params.id)
    }
  }, [params.id, store.ReturnsStore])

  useEffect(() => {
    if (detailsData?.products) {
      detailsData.products.forEach((product) => {
        setSelectedProducts(product.code, product.amount)
      })
    }
  }, [detailsData, setSelectedProducts])

  useEffect(() => {
    if (detailsData && initialDetailsData) {
      if (acceptAll) {
        setSelectedDecision('exchange')
        setDetailsData(initialDetailsData)
      } else {
        setSelectedDecision('partial')
      }
    }
  }, [acceptAll])

  useEffect(() => {
    if (detailsProducts && orderDetails && orderDetails?.products) {
      const returnValue = Object.keys(selectedProducts)
        .filter((code) => store.TableStore.selectedRowId.includes(code))
        .reduce((total, code) => {
          const productDetails = Object.values(
            orderDetails.products as any[],
          ).find((p: any) => p.code === code)
          const productPrice = productDetails ? productDetails.price : 0
          return total + productPrice * selectedProducts[code]
        }, 0)
      setTotalReturnValue(returnValue)
    }
  }, [
    store.TableStore.selectedRowId.length,
    selectedProducts,
    detailsProducts,
    orderDetails,
    change,
  ])

  const handleReturn = () => {
    const finalStatus =
      selectedDecision === 'rejected'
        ? 'rejected'
        : `accepted-${selectedDecision}`

    // @ts-ignore
    const allProducts = detailsData.products.map((product) => {
      const productDetails = Object.values(orderDetails.products as any[]).find(
        (p: any) => p.code === product.code,
      )
      return {
        ...product,
        status: finalStatus,
        price: productDetails?.price || 0,
      }
    })

    const newAmount =
      finalStatus === 'accepted-returnmoney'
        ? allProducts.reduce((acc, p) => acc + p.price * p.amount, 0)
        : // @ts-ignore
          detailsData.amount

    toast('Return decision has been made')
    // Update the entire return
    store.ReturnsStore.updateReturn(params.id, {
      ...detailsData,
      products: allProducts.map(({ code, amount, status }) => ({
        code,
        amount,
        status,
      })),
      status: finalStatus,
      amount: newAmount,
    })
  }

  return (
    <Container loading={!!(loaded || detailsData)}>
      {!loaded || !detailsData || !detailsProducts || !orderDetails ? (
        <LoadingContainer>
          <Wrap />
        </LoadingContainer>
      ) : (
        <HeaderCont>
          <TitleText>Order number: {detailsData.order_id}</TitleText>
          <ReturnsButtonCont>
            <ReturnsButton
              selected={!acceptAll}
              view={'left'}
              onClick={() => setAcceptAll(true)}
            >
              Decide on all products
            </ReturnsButton>
            <ReturnsButton
              view={'right'}
              selected={acceptAll}
              onClick={() => setAcceptAll(false)}
            >
              Decide on selected products
            </ReturnsButton>
          </ReturnsButtonCont>
          {acceptAll ? (
            <></>
          ) : (
            <>
              <TitleText>Select Products to make decision:</TitleText>
              <Body>
                <ReturnsDetailsTable />
              </Body>
            </>
          )}
          <SettingsCategory>
            <TitleText>Return Options</TitleText>
            {detailsData.hasOwnProperty('mediaFiles') &&
            detailsData.mediaFiles?.length > 0 ? (
              <>
                <Label>Included media files:</Label>
                <MediaCont>{renderMediaPreview()}</MediaCont>
              </>
            ) : null}
            {detailsData.comment.length ? (
              <>
                <Label>Comment:</Label>
                <Label>{detailsData.comment}</Label>
              </>
            ) : (
              <></>
            )}
            {acceptAll ? (
              <>
                <Label>Choose Decision:</Label>
                <StyledSelect
                  value={selectedDecision}
                  onChange={(e) => setSelectedDecision(e.target.value)}
                >
                  {decisionsList.map((decisionOption, index) => (
                    <option key={index} value={decisionOption.value}>
                      {decisionOption.label}
                    </option>
                  ))}
                </StyledSelect>
              </>
            ) : (
              <></>
            )}
            {!acceptAll ? (
              <>
                <Button
                  margin={'16px 0 0 0'}
                  width={'max-content'}
                  type={'primary'}
                  onClick={handleReturn}
                >
                  Decide on selected products
                </Button>
                <p>Total Return Value: {totalReturnValue.toFixed(2)} PLN</p>
              </>
            ) : (
              <Button
                margin={'16px 0 0 0'}
                width={'max-content'}
                type={'primary'}
                onClick={handleReturn}
              >
                Decide on all products
              </Button>
            )}
          </SettingsCategory>
        </HeaderCont>
      )}
    </Container>
  )
})

export default ReturnsDetails
