/* eslint-disable */
import {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Row } from '@tanstack/react-table'

import {
  Container,
  Footer,
  InnerCont,
  TagCont,
} from './AssignOrderLineTag.styles'

import { StoreContext } from '../../../../../App'

import axios from '../../../../../axiosconfig'
import { toast } from 'react-toastify'
import SearchTagComponent from '../searchTag/SearchTag.component'
import {
  SingleTag,
  SingleTagCross,
} from '../../../../_tableGeneric/tableNewTags/TableNewTags.styles'
import Cross from '../../../../../assets/icons/cross-white.svg'
import SearchTagNewComponent from '../searchTagNew/SearchTagNew.component'
import Button from '../../../../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  row: any
  tableRow: string
}

const AssignCustomerTag: (props: EditProductsProps) => JSX.Element = ({
  row,
  tableRow,
}: EditProductsProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const [isAbleToSave, setIsAbleToSave] = useState(false)

  let id = row
    .getAllCells()
    .find((e: any) => e.column.id === 'ProductCode')?.value
  const [currentTagList, setCurrentTagList] = useState<Array<any>>([])
  const [initialTagList, setInitialTagList] = useState<Array<any>>([])

  const applySearch = (tag: any) => {
    const addedTag = [...currentTagList, tag]
    const unique = [
      ...new Map(addedTag.map((item) => [item.id, item])).values(),
    ]
    setCurrentTagList(unique)
    setIsAbleToSave(true)
  }
  const applyClearTag = (tag: any) => {
    setCurrentTagList([...currentTagList.filter((e) => e.id !== tag.id)])
    setIsAbleToSave(true)
  }
  const handleSave = async () => {
    const supOrderUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${tableRow}`

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }

    const response = await axios.get(supOrderUrl, config)
    const productTags = response.data.product_tags

    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update/orderline/${tableRow}`

    const dataTags = [...currentTagList.map((e) => e.id)]
    const tagsObject = { productID: id, tags: dataTags }

    const addedTags = productTags

    const productIndex = addedTags.findIndex(
      (tag: any) => tag.productID === tagsObject.productID,
    )

    if (productIndex !== -1) {
      addedTags[productIndex] = {
        productID: tagsObject.productID,
        tags: tagsObject.tags,
      }
    } else {
      addedTags.push({
        productID: tagsObject.productID,
        tags: tagsObject.tags,
      })
    }

    const initialTags = [...initialTagList.map((e) => e.id)]
    const removedTags = initialTags.filter((tag) => !dataTags.includes(tag))
    const removedTagsBody = {
      tags: [{ productID: id, tags: removedTags }],
    }
    await axios.post(`${url}?operator=assign`, { tags: addedTags }, config)
    await axios.post(`${url}?operator=unassign`, removedTagsBody, config)
    await toast(locales.successes.success, { type: 'success' })
    await store.SlidingPanelState.setVisibility(false)
    await store.TableStore.fetchData()
  }

  useEffect(() => {
    const tags = row
      .getAllCells()
      .find((e: any) => e.column.id === 'OrderLineTags')?.value
    if (tags && tags.length > 0) {
      let tempTags: any = [...tags]
      setCurrentTagList(tempTags)
      setInitialTagList(tempTags)
    }
  }, [])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <SearchTagNewComponent
          active={true}
          view={'tag'}
          header={`${locales.buttons.addNew} Tag`}
          applySearch={applySearch}
          clearInputValue={true}
          customIndex={'customer orders'}
        />
        <TagCont>
          Current Tags:{' '}
          {currentTagList.map((e: any, index) => {
            if (e)
              return (
                <SingleTag
                  key={`new-tag-${e}`}
                  size={'12px'}
                  style={{ whiteSpace: 'nowrap' }}
                  color={e.hasOwnProperty('colour') ? e.colour : '#505050'}
                >
                  {e.name.EN}
                  <SingleTagCross onClick={() => applyClearTag(e)}>
                    <img src={Cross} />
                  </SingleTagCross>
                </SingleTag>
              )
          })}
        </TagCont>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        {/*<SecondaryButton
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {locales.buttons.cancel}
        </SecondaryButton>*/}
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default AssignCustomerTag
