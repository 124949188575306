import Table from '../_tableGeneric/table/Table.component'
import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import TableObjectDisplayComponent from '../_tableGeneric/tableObjectDisplay/TableObjectDisplay.component'
import { toJS } from 'mobx'

const ContactTable = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  console.log(toJS(store.ContactsStore.addresses))

  const columns = {
    id: {
      canSort: false,
      Header: 'ID',
      Cell: (cell: any) => <a href={'/contacts/addresses/1'}>details</a>,
    },
    basic_information: {
      Header: 'Basic Information',
      columns: {
        address_id: {
          canSort: false,
          Header: 'Address ID',
        },
        address_type: {
          canSort: false,
          Header: 'Address Type',
        },
        is_default: {
          canSort: false,
          Header: 'Is Default',
          Cell: (cell: any) => (cell.getValue() ? 'Yes' : 'No'),
        },
      },
    },
    location_details: {
      Header: 'Location Details',
      columns: {
        street: {
          canSort: false,
          Header: 'Street',
        },
        apartment_suite: {
          canSort: false,
          Header: 'Apartment/Suite',
        },
        city: {
          canSort: false,
          Header: 'City',
        },
        state: {
          canSort: false,
          Header: 'State',
        },
        postal_code: {
          canSort: false,
          Header: 'Postal Code',
        },
        country: {
          canSort: false,
          Header: 'Country',
        },
      },
    },
    contact_details: {
      Header: 'Contact Details',
      columns: {
        phone: {
          canSort: false,
          Header: 'Phone',
        },
        email: {
          canSort: false,
          Header: 'Email',
        },
      },
    },
    additional_information: {
      Header: 'Additional Information',
      columns: {
        notes: {
          canSort: false,
          Header: 'Notes',
        },
        geo_coordinates: {
          canSort: false,
          Header: 'Geo Coordinates',
          Cell: (cell: any) => (
            <TableObjectDisplayComponent data={cell.getValue()} />
          ),
        },
      },
    },
    external_references: {
      Header: 'External References',
      Cell: (cell: any) => (
        <TableObjectDisplayComponent data={cell.getValue()} />
      ),
      canSort: false,
    },
  }

  useEffect(() => {
    store.ContactsStore.fetchAddresses()
    return () => {
      store.ContactsStore.clearAddresses()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'contactsAddresses',
        data: store.ContactsStore.addresses || [],
        fetchData: async () => await store.ContactsStore.fetchAddresses(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'Addresses',
          subTitle: 'List of addresses',
        },
        breadcrumb: [],
        loaded: store.ContactsStore.loaded.addresses,
      }}
    />
  )
})

export default ContactTable
