import { action, makeAutoObservable, toJS } from 'mobx'
import { RootStore } from './Root.store'
import { socket } from '../socket/socket'
import { toast } from 'react-toastify'
import axios from 'axios'

interface PaginationData {
  tableSize: number
  pageSize: number
  pageIndex: number
  totalCount: number
}

export class PaginationStore {
  rootStore: RootStore
  pagination: { [tableName: string]: PaginationData } = {}
  currentPagination: string = ''

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound initPagination = async (
    tableName: string,
    pageSize?: number,
  ) => {
    const getUrl = () => {
      switch (tableName) {
        case 'customerorders':
        case 'stock':
        case 'supplierorders':
        case 'packing':
        case 'bank':
        case 'audit':
        case 'tags':
        case 'roottags':
        case 'grouptags':
        case 'categories':
          return `${
            process.env.REACT_APP_BASE_URL
          }experience/v1/${this.rootStore.TableStore.getApiRequestFromTableName(tableName)}?size=1&from=0`
        default:
          return null
      }
    }

    const getData = () => {
      switch (tableName) {
        case 'customerorders':
        case 'stock':
        case 'supplierorders':
        case 'packing':
        case 'bank':
        case 'audit':
          return {}
        case 'tags':
        case 'roottags':
        case 'grouptags':
        case 'categories':
          return { skipChildren: false }
        default:
          return null
      }
    }

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.rootStore.UserStore.user,
      },
    }

    const url = getUrl()
    const data = getData()
    if (url && data) {
      const response = await axios.post(url, data, config)
      await this.setPagination(
        tableName,
        response.data.count,
        pageSize || 10,
        1,
        response.data.count,
      )
    }
  }

  @action.bound setPagination = async (
    tableName: string,
    tableSize: number,
    pageSize: number,
    pageIndex: number,
    totalCount: number,
    noLoading?: boolean,
  ) => {
    this.pagination[tableName] = {
      tableSize,
      pageSize,
      pageIndex,
      totalCount,
    }
    console.log(toJS(this.pagination))
  }

  @action.bound setCurrentPagination = async (tableName) => {
    this.currentPagination = tableName
  }
}
