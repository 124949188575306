import React, { useContext } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { StoreContext } from '../../App'
/* eslint-disable */
const styles = StyleSheet.create({
  body: {},
  table: {
    width: 'auto',
    margin: '5%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColLp: {
    width: '10%', // Narrow column for `Lp.`
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    width: `${90 / 6}%`, // Adjusted width for the remaining 6 columns
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 'auto',
    marginTop: 5,
    fontSize: 10,
  },
})

export const TableToPrint = () => {
  const store = useContext(StoreContext)
  const products = store.TableStore.detailsData.products

  return (
    <View style={styles.table}>
      {/* Table Header */}
      <View style={styles.tableRow}>
        <View style={styles.tableColLp}>
          <Text style={styles.tableCell}>Lp.</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Name</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Code</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Original</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>After This</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Tags</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>Demand</Text>
        </View>
      </View>

      {/* Table Rows */}
      {products ? (
        Object.keys(products)
          .map((key) => products[key])
          .map((e: any, index: number) => (
            <View key={`products-row-${index}`} style={styles.tableRow}>
              <View style={styles.tableColLp}>
                <Text style={styles.tableCell}>{index + 1}</Text>{' '}
                {/* Ordinal Number */}
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.name ? e.name : ''}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.code}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.stock_quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.afterThis}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {e.product_tags
                    ? Object.values(e.product_tags).join(', ')
                    : ''}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{e.quantity}</Text>
              </View>
            </View>
          ))
      ) : (
        <></>
      )}
    </View>
  )
}
