import { action, makeAutoObservable } from 'mobx'
import { RootStore } from './Root.store'

export class SlidingPanelStore {
  rootStore
  position: 'left' | 'right' = 'right'
  visibility = false
  selectedSlider = ''

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setPosition = (position: 'left' | 'right'): void => {
    this.position = position
    //console.log(position)
  }

  @action.bound setVisibility = (visibility: boolean): void => {
    this.visibility = visibility
    this.selectedSlider = ''
  }

  @action.bound setSelectedSlider = (selectedSlider: string) => {
    if (selectedSlider == this.selectedSlider) {
      this.visibility = false
      this.selectedSlider = ''
    } else {
      this.selectedSlider = selectedSlider
      this.visibility = true
    }
  }
}
