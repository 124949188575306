import { TranslationsInterface } from './interface'

export const translations: TranslationsInterface = {
  steps: {
    controlInvoice: 'Kontrola faktury i szczegółów zamówienia',
    printListProducts: 'Drukuj listę produktów',
    pickProducts: 'Wybierz produkty',
    countProducts: 'Liczenie produktów',
    checkQualityProducts: 'Sprawdź jakość produktów',
    packProducts: 'Pakowanie produktów',
    packLeeflets: 'Pakowanie ulotek',
    issueSalesInvoice: 'Wystaw fakturę sprzedaży',
    sendSalesInvoiceEmail: 'Wyślij fakturę sprzedaży (e-mail)',
    controlTagsStatusesOrderDetailsOnInvoice:
      'Kontrola tagów i statusów na fakturze oraz szczegółach zamówienia',
    generateShipping: 'Generowanie wysyłki',
    stickShippingLabelOnPackageAndSend:
      'Przyklej etykietę wysyłki na paczkę i wyślij',
  },
  actions: {
    search: 'Pokaż filtry dla kolumn',
    columnVisibility: 'Pokaż kolumny',
    filters: 'Filtry',
    chooseDefaultFilter: 'Wybierz domyślny filtr',
    choosePredefinedFilter: 'Wybierz gotowy filter',
    columnName: 'Nazwa kolumny',
    show: 'Pokaż',
    noActions: 'Brak akcji!',
  },
  details: {
    Description: 'Opis',
    status: 'status',
    completable: 'Skompletowanie:',
    shipping: 'wysyłka',
    Sum: 'Suma',
    paid: 'zapłacono',
    paymentDifference: 'Różnica płatności',
    paymentMethod: 'Metoda płatności',
    estimateDate: 'Szacunkowa Data',
    tags: 'tagi',
    user_id: 'ID klienta',
    client: 'Emial Klienta',
    completableMicroserviceInt: 'completableMicroserviceInt',
    email: 'Email',
    phone: 'Number Telefonu',
    lastEditDate: 'data ostatniej edycji',
    country_code: 'kod kraju',
    notes_priv: 'Notatki',
    ordered: 'Data Zamówienia',
  },
  address: {
    firstname: 'imię',
    lastname: 'nazwisko',
    company: 'firma',
    city: 'miasto',
    postcode: 'kod pocztowy',
    street1: 'ulica 1',
    street2: 'ulica 2',
    country: 'kraj',
  },
  tableNames: {
    customerorders: 'Zamówienia Klientów',
    stock: 'Produkty',
    supplierorders: 'Zamówienia Dostawców',
    reports: 'Raporty',
    bank: 'Bank',
    packing: 'Pakowanie',
    packages: 'Paczki',
    returns: 'Zwroty',
    admin: 'Admin',
    contacts: 'Kontakty',
  },
  buttons: {
    add: 'Dodaj',
    addNew: 'Dodaj Nowy',
    addTags: 'Dodaj Tagi do',
    addCollections: 'Dodaj Kolekcje do',
    view: 'Wejdź',
    locked: 'Blokada',
    lockedBy: 'Zablokowane przez:',
    cancel: 'Anuluj',
    clearFilters: 'Wyczyść filtry',
    clearGlobalFilter: 'Wyczyść filtr globalny',
    clearFilterProduct: 'Wyczyść filtr produktu',
    apply: 'Zastosuj',
    save: 'Zapisz',
    saveDefault: 'Zapisz jako domyślny',
    backToDefaultQuery: 'Wróć do domyślnego filtru',
    predefinedFilter: 'Predefined Filter',
    newFilter: 'New Fiter',
    selectAll: 'Całe',
    selectPage: 'Strona',
    rowsPerPage: 'Wiersze na stronie:',
    switchLayout: 'Zmień Layout',
    enter: 'Wejdź',
    setPriority: 'Ustaw Priorytet',
    selectFirstStep: 'Wybierz pierwszy krok',
    print: 'Drukuj',
    printWithoutData: 'Drukuj bez danych użytkownika',
    goToCustomerView: 'Idź do widoku zamówienia',
    edit: 'Edytuj',
    editSelected: 'Edytuj Wybrane',
    remove: 'Usuń',
    addToSelected: 'Dodaj Produkty do Wybranych Zamówień',
    supplierProducts: 'Produkty Dostawcy',
    copyToClipboard: 'Skopoiuj do schowka',
    copied: 'Skopiowane!',
    downloadFile: 'Ściągnij plik',
    search: 'Wyszukaj...',
    searchAll: 'Wyszukaj wszędzie',
    setGlobalSearch: 'Wyszukaj Globalnie...',
    createPurchaseOrder: 'Utwórz zamówienie do dostawcy',
    minThreeChars: 'Minimum 3 znaki',
    backToTop: 'Wróć na górę',
    expand: 'Rozszerz',
    collapse: 'Schowaj Sidebar',
  },
  headers: {
    details: 'Szczególy',
    deliveryAddress: 'Adres Dostawy',
    billingAddress: 'Adres Faktury',
    historyOfActions: 'Historia Akcji',
    customerOrder: 'Zamówienie',
    nextStep: 'Następny krok',
    listOfProducts: 'Liasta Produktów',
    quantity: 'Ilość',
    searchProduct: 'Wyszukaj Produkt',
    currentCollections: 'Aktualne Kolekcje',
    currentTags: 'Aktualne Tagi',
    addNewTag: 'Dodaj Nowy Tag',
    noFilter: 'Brak filtra',
    listOf: 'Wszystkie',
  },
  labels: {
    setNameForInput: 'Ustaw nazwę dla inputa',
    makeFilterPrivate: 'Ustaw filtr jako prywatny',
  },
  packable: {
    allMissing: 'Brak wszystkich produktow',
    someMissing: 'Brak niektórych produktów',
    packable: 'Wszystkie produkty dostępne',
    incorrectValue: 'Zła wartość',
  },
  status: {
    '0': 'Anulowane',
    '1': 'Wyeksportowane do Excela',
    '2': 'Wysłane do dostawcy',
    '3': 'W trakcie',
    '4': 'Zakończone',
    '5': 'W trakcie (opóźnienie)',
    '6': 'W trakcie (reklamacja)',
    default: `Niepoprawna wartość`,
  },
  options: {
    tableButtons: 'Opcje Tabeli',
    columnVisibility: 'Widoczność Kolumn',
    queryFilers: 'Filtry',
  },
  pagination: {
    page: 'Strona',
    showing: 'Elementy',
    of: 'z',
    entries: 'elementów',
  },
  columns: {
    sort: 'Sortuj',
    none: 'Brak',
    descending: 'Malejąco',
    ascending: 'Rosnąco',
    info: 'Informacje',
    text: 'Tekst',
    wrapText: 'Zawijaj tekst',
    clipText: 'Ucinaj tekst',
    groupBy: 'Grupuj według tej kolumny',
    values: 'Wartości',
    uniqueValeus: 'Unikalne wartości',
    sum: 'Suma',
    median: 'Mediana',
    average: 'Średnia',
    move: 'Przenieś',
    moveLeft: 'Przenieś w lewo',
    moveRight: 'Przenieś w prawo',
    backToDefault: 'Wróć do domyślnych ustawień',
  },
  userInfo: {
    displayName: 'Nazwa Użytkownika',
    givenName: 'Imię',
    surname: 'Nazwisko',
    mail: 'Email',
    jobTitle: 'Stanowisko',
    mobilePhone: 'Numer Telefonu',
    officeLocation: 'Lokalizacja Biura',
    preferredLanguage: 'Preferowany Język',
  },
  successes: {
    success: 'Sukces!',
    languageAdded: 'Język został dodany!',
    saved: 'Zapisano!',
  },
  warnings: {
    vieved:
      'Ktoś w tym momencie ogląda ten produkt. Czy na pewno chcesz wejść?',
    wantToRemove: 'Czy na pewno chcesz dokonać akcję usunięcia?',
  },
  errors: {
    noDesc: 'Kolumna nie posiada opisu',
    noOrderId: 'Brak zamówienia z tym orderID!',
    invalidLanguage: 'Niepoprawny język albo już istnieje!',
  },
  tableLabels: {
    bank: 'Tranzakcję',
    listOfProducts: 'Lista',
    packingdetails: 'Zamówienie',
    packing: 'Zamówienie',
    customerorders: 'Zamówienie',
    returnsDetails: 'Zwrot',
    categories: 'Kategoria',
    packages: 'Paczka',
    packagesDetails: 'Paczka',
    returns: 'Zwrot',
    availability: 'Status',
    salesdata: 'Status',
    shippingtime: 'Status',
    statuses: 'Status',
    currencies: 'Waluta',
    timezones: 'Strefa czasowa',
    collectiontags: 'Tag',
    grouptags: 'Group Tag',
    roottags: 'Root Tag',
    tags: 'Tag',
    stock: 'Produkt',
    supplierorders: 'Zamówienie od dostawcy',
    predefinedFilters: 'Filtr',
    predefinedViews: 'Widok',
    contacts: 'Kontakt',
    contactsAddresses: 'Adres',
    contactsContacts: 'Kontakt',
    contactsIdentities: 'Tożsamość',
    contactsCompanies: 'Firma',
  },
  tableLabelsMultiple: {
    bank: 'Tranzakcje',
    listOfProducts: 'Listy',
    packingdetails: 'Zamówienia',
    packing: 'Zamówienia',
    customerorders: 'Zamówienia',
    returnsDetails: 'Zwroty',
    categories: 'Kategorie',
    packages: 'Paczki',
    packagesDetails: 'Paczki',
    returns: 'Zwroty',
    availability: 'Statusy',
    salesdata: 'Statusy',
    shippingtime: 'Statusy',
    statuses: 'Statusy',
    currencies: 'Waluty',
    timezones: 'Strefy czasowe',
    collectiontags: 'Tagi',
    grouptags: 'Group Tagi',
    roottags: 'Root Tagi',
    tags: 'Tagi',
    stock: 'Produkty',
    supplierorders: 'Zamówienia od dostawców',
    predefinedFilters: 'Filtry',
    predefinedViews: 'Widoki',
    contactsAddresses: 'Adresy',
    contactsContacts: 'Kontakty',
    contactsIdentities: 'Tożsamości',
    contactsCompanies: 'Firmy',
  },
  customerordersDetails: {
    lock: 'Informacje o blokadzie. Możesz uzyskać dostęp do szczegółów zamówienia klienta, a jeśli ktoś inny jest obecny, wyświetlane są informacje o blokadzie.',
    id: 'Unikalny identyfikator zamówienia klienta.',
    order:
      'Szczegóły dotyczące zamówienia, takie jak numer zamówienia, data i czas.',
    priority:
      'Informacje o priorytecie zamówienia. Im wyższy priorytet, tym szybciej przetwarzane jest zamówienie.',
    completable:
      'Informacje o możliwości zrealizowania zamówienia. Jeśli tak, zamówienie jest gotowe do przetwarzania.',
    completableMicroserviceInt:
      'Informacje o możliwości realizacji zamówienia po zapakowaniu innych faktur.',
    status: 'Aktualny status zamówienia..',
    ordered: 'Data i godzina złożenia zamówienia.',
    author: 'Autor zamówienia, czyli osoba, która utworzyła zamówienie.',
    lastUpdateAuthor: 'Ostatnia osoba, która aktualizowała zamówienie.',
    client: 'Informacje o kliencie, który złożył zamówienie.',
    paid: 'Informacje o tym, czy zamówienie zostało opłacone.',
    paymentMethod:
      'Metoda płatności wybrana przez klienta podczas składania zamówienia.',
    shipping:
      'Informacje związane z wysyłką, takie jak adres wysyłki i firma kurierska.',
    tags: 'Tagi powiązane z zamówieniem, które pomagają w organizacji i wyszukiwaniu zamówień.',
    estimateProduct:
      'Szacowana ilość produktu potrzebnego do zrealizowania zamówienia.',
    estimateDate:
      'Szacowana data realizacji i dostarczenia zamówienia do klienta.',
    details:
      'Szczegółowe informacje o zamówieniu, takie jak lista produktów, ceny i inne szczegóły.',
    checkListItems: 'Informacje o ostatnim działaniu wykonanym na zamówieniu.',
  },
  stockDetails: {
    id: 'Unikalny identyfikator produktu na stanie.',
    code: 'Kod związany z produktem.',
    backend_id: 'ID używane w systemie backend dla produktu.',
    name: 'Nazwa produktu.',
    producer: 'Producent lub producent produktu.',
    addDate: 'Data, kiedy produkt został dodany do magazynu.',
    active: 'Wskazuje, czy produkt jest aktywny w magazynie.',
    tags: 'Tagi związane z produktem, które pomagają w wyszukiwaniu i organizacji magazynu.',
    stock: 'Aktualna ilość produktu w magazynie.',
    stockDemand: 'Popyt na produkt na rynku.',
    stockInvoices: 'Faktury związane z produktem.',
    stockAfterPacking: 'Pozostałość magazynu po pakowaniu zamówień.',
    stockLastInvoice: 'Ostatnia wystawiona faktura za produkt.',
    stockLastInvoiceDate: 'Data wystawienia ostatniej faktury.',
    stockCalculated:
      'Obliczona ilość produktu w magazynie po uwzględnieniu oczekujących zamówień.',
    sellPrice: 'Cena sprzedaży produktu.',
    stockInProgress: 'Ilość produktu w magazynie w trakcie aktualizacji.',
    stockAfterOrders: 'Ilość produktu w magazynie po realizacji zamówień.',
    stockAmountInPackage: 'Ilość produktu w każdym opakowaniu.',
    stockAmountInPackageBool: 'Informacje o tym, czy produkt jest pakowany.',
    stockMOQAfterChecking: 'stockMOQAfterChecking.',
    minimumWarnLevel:
      'Minimalny poziom ostrzegania o stanie magazynu. Kiedy stan magazynu spada poniżej tego poziomu, wydawane jest ostrzeżenie.',
    secureWarnLevel:
      'Bezpieczny poziom ostrzegania o stanie magazynu. Jest to bezpieczny poziom dla magazynu, aby uniknąć wyczerpania zapasów.',
    maximumWarnLevel:
      'Maksymalny poziom ostrzegania o stanie magazynu. Kiedy stan magazynu osiąga ten poziom, wydawane jest ostrzeżenie.',
    quantityToOrder:
      'Ilość produktu do zamówienia w celu uzupełnienia magazynu.',
    minimumQuantityForSupplierOrder:
      'Minimalna ilość produktu, która może być zamówiona od dostawcy.',
    availabilityDate: 'Data, kiedy produkt będzie dostępny w magazynie.',
    salesPriceBrutto: 'Bruttowy cena sprzedaży produktu.',
    salesPriceNetto: 'Nettowa cena sprzedaży produktu.',
    defaultSupplierPrice: 'Domyślna cena produktu od dostawcy.',
    defaultSupplierPriceCurrency: 'Waluta domyślnej ceny dostawcy.',
    salesDate: 'Data sprzedaży produktu.',
    salesTime: 'Czas sprzedaży produktu.',
    permalink: 'Stały link do szczegółów produktu.',
    image: 'Obraz produktu.',
  },
  supplierordersDetails: {
    id: 'Unikalny identyfikator zamówienia dostawcy.',
    supplierOrderId: 'ID zamówienia od dostawcy.',
    supplier: 'Dostawca, od którego złożono zamówienie.',
    creationDate: 'Data utworzenia zamówienia.',
    author: 'Autor zamówienia, czyli osoba, która stworzyła zamówienie.',
    price_type: 'Rodzaj cen stosowanych dla zamówienia.',
    wfirma_vat_id: 'ID VAT dostawcy w systemie wfirma.',
    wfirma_id: 'ID dostawcy w systemie wfirma.',
    vat: 'Podatek VAT nałożony na zamówienie.',
    lastEditDate: 'Data ostatniej edycji zamówienia.',
    lastEditAuthor: 'Osoba, która ostatnio edytowała zamówienie.',
    estimateDate: 'Szacowana data dostawy zamówienia.',
    deliveryCode: 'Kod dostawy lub numer przesyłki zamówienia.',
    orderLink: 'Link do zamówienia dostawcy.',
    notes: 'Dodatkowe notatki dotyczące zamówienia.',
    status: 'Aktualny status zamówienia.',
  },
  packingDetails: {
    lock: 'Informacja o blokadzie. Możesz przejść do szczegółów pakowania, a jeżeli ktoś inny jest obecny, wyświetla informacje o blokadzie.',
    id: 'Unikalny identyfikator pakowania.',
    priority:
      'Priorytet pakowania. Im większy priorytet, tym wcześniej pakowanie jest przeprowadzane.',
    completable: 'Wskazuje, czy pakowanie można zakończyć.',
    completableMicroserviceInt:
      'Wskazuje, czy pakowanie można zakończyć po spakowaniu innych zamówień.',
    status: 'Aktualny status pakowania.',
    ordered: 'Data i godzina, kiedy zamówiono pakowanie.',
    client: 'Klient, dla którego przeprowadzane jest pakowanie.',
    paid: 'Wskazuje, czy za pakowanie zostało zapłacone.',
    shipping: 'Szczegóły wysyłki dla spakowanego zamówienia.',
    tags: 'Tagi związane z pakowaniem, które pomagają w wyszukiwaniu i organizacji pakowań.',
    estimateProduct: 'Szacowana ilość produktu potrzebnego do pakowania.',
    estimateDate: 'Szacowana data zakończenia pakowania.',
    details:
      'Szczegółowe informacje o pakowaniu, takie jak lista produktów, ceny i inne szczegóły.',
    orderSource: 'Źródło, skąd pochodziło zamówienie do pakowania.',
    documents: 'Dokumenty związane z pakowaniem.',
    checkListItems: 'Informacje o ostatnim działaniu wykonanym na pakowaniu.',
  },
  customerordersColumns: {
    lock: 'blokada',
    id: 'id',
    order: 'zamówienie',
    priority: 'priorytet',
    completable: 'Do ukończenia',
    completableMicroserviceInt: 'DoUkończeniaPoZapakowaniuInnychProduktów',
    status: 'status',
    ordered: 'zamówione',
    author: 'autor',
    lastUpdateAuthor: 'AutorOstatniejAktualizacji',
    client: 'klient',
    paid: 'opłacone',
    paymentMethod: 'metoda płatności',
    shipping: 'wysyłka',
    tags: 'tagi',
    estimateProduct: 'szacowany produkt',
    estimateDate: 'szacowana data',
    details: 'szczegóły',
    checkListItems: 'Ostatnia akcja',
  },
  stockColumns: {
    id: 'id',
    code: 'kod',
    ean: 'EAN',
    backend_id: 'backend_id',
    name: 'nazwa',
    producer: 'producent',
    addDate: 'dataDodania',
    active: 'aktywny',
    tags: 'tagi',
    stock: 'zapasy',
    stockDemand: 'zapotrzebowanie',
    stockInvoices: 'faktury',
    stockAfterPacking: 'poPakowaniu',
    stockLastInvoice: 'ostatniaFaktura',
    stockLastInvoiceDate: 'dataOstatniejFaktury',
    stockCalculated: 'obliczone',
    sellPrice: 'cenaSprzedaży',
    stockInProgress: 'wToku',
    stockAfterOrders: 'poZamówieniach',
    stockAmountInPackage: 'ilośćWPakiecie',
    stockAmountInPackageBool: 'stockWartośćWPaczceBool',
    stockMOQAfterChecking: 'stockMOQAfterChecking',
    minimumWarnLevel: 'minimalnyPoziomOstrzeżenia',
    secureWarnLevel: 'bezpiecznyPoziomOstrzeżenia',
    maximumWarnLevel: 'maksymalnyPoziomOstrzeżenia',
    quantityToOrder: 'ilośćDoZamówienia',
    minimumQuantityForSupplierOrder: 'minimalnaIlośćDlaZamówieniaDostawcy',
    availabilityDate: 'dataDostępności',
    salesPriceBrutto: 'cenaSprzedażyBrutto',
    salesPriceNetto: 'cenaSprzedażyNetto',
    defaultSupplierPrice: 'domyślnaCenaDostawcy',
    defaultSupplierPriceCurrency: 'domyślnaWalutaCenyDostawcy',
    salesDate: 'dataSprzedaży',
    salesTime: 'czasSprzedaży',
    permalink: 'permalink',
    image: 'obraz',
  },
  supplierordersColumns: {
    id: 'id',
    supplierOrderId: 'idZamówieniaDostawcy',
    supplier: 'dostawca',
    creationDate: 'dataUtworzenia',
    author: 'autor',
    price_type: 'typCeny',
    wfirma_vat_id: 'id_vat_wfirma',
    wfirma_id: 'id_wfirma',
    vat: 'vat',
    lastEditDate: 'dataOstatniejEdycji',
    lastEditAuthor: 'autorOstatniejEdycji',
    estimateDate: 'szacowanaData',
    deliveryCode: 'kodDostawy',
    orderLink: 'linkDoZamówienia',
    notes: 'notatki',
    status: 'status',
    currency: 'waluta',
  },
  packingColumns: {
    lock: 'blokada',
    id: 'id',
    priority: 'priorytet',
    completable: 'doKompletacji',
    completableMicroserviceInt: 'doKompletacjiPoInnychZamówieniach',
    status: 'status',
    ordered: 'zamówione',
    client: 'klient',
    paid: 'zapłacone',
    shipping: 'wysyłka',
    tags: 'tagi',
    estimateProduct: 'szacowanyProdukt',
    estimateDate: 'szacowanaData',
    details: 'szczegóły',
    orderSource: 'źródłoZamówienia',
    documents: 'dokumenty',
    checkListItems: 'ostatniaAkcja',
  },
  customerorderDetailsColumns: {
    name: 'nazwa',
    code: 'kod',
    stock_quantity: 'oryginalny',
    afterThis: 'po Akcjach',
    afterPackingOtherInvoices: 'poInnychZamówieniach',
    is_missing: 'Wystarczające produkty Po Innych Zamówieniach',
    quantity: 'popyt',
    after_orders: 'Po Zamówieniach',
    supplier_orders: 'Uwagi z Zamówienia',
    product_tags: 'Tagi',
    tax: 'podatek',
    unit: 'jednostka',
    weight: 'waga',
    price: 'cena',
    image: 'obraz',
  },
  packingDetailsColumns: {
    name: 'nazwa',
    code: 'kod',
    stock_quantity: 'oryginalny',
    afterPackingOtherInvoices: 'poInnychZamówieniach',
    is_missing: 'Wystarczające produkty Po Innych Zamówieniach',
    quantity: 'popyt',
    product_tags: 'Lokalizacja',
    image: 'obraz',
  },
  bankColumns: {
    id: 'id',
    type: 'type',
    user: 'user',
    date: 'date',
    exported: 'exported',
  },
}
