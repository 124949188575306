import React, { PropsWithChildren, FC, useContext } from 'react'
/* eslint-disable */
import { Container } from './TableReturnsDecision.styles'
import { StoreContext } from '../../../App'
import { observer } from 'mobx-react'
import { StyledSelect } from '../../dataInputGeneric/DataInputGeneric.styles'

interface Props extends PropsWithChildren {
  cell: any
}

const TableReturnsCounter: FC<Props> = observer(({ cell }: Props) => {
  const store = useContext(StoreContext)

  const code = cell.row
    .getAllCells()
    .find((e: any) => e.column.id === 'code')
    ?.getValue()

  const product: any = store.ReturnsStore.detailsData?.products?.find(
    (e) => e.code === code,
  )

  const selected = store.TableStore.selectedRowId.includes(code)

  const handleDecisionChange = (productCode, decision) => {
    const selectedProducts = store.ReturnsStore.detailsData?.products.map(
      (product) => {
        if (product.code === productCode) {
          return {
            ...product,
            status: decision,
          }
        }
        return product
      },
    )

    // Update store
    if (
      store.ReturnsStore.detailsData &&
      store.ReturnsStore.detailsData.products
    )
      //@ts-ignore
      store.ReturnsStore.detailsData.products = selectedProducts
    console.log('Updated product decisions:', selectedProducts)
  }

  return (
    <Container selected={selected}>
      <StyledSelect
        value={product?.status || 'rejection'}
        onChange={(e) => handleDecisionChange(code, e.target.value)}
        activeStyle={selected}
        disabled={!selected}
      >
        {store.ReturnsStore.decisionsList.map((decision, index) => (
          <option key={index} value={decision.value}>
            {decision.label}
          </option>
        ))}
      </StyledSelect>
    </Container>
  )
})
export default TableReturnsCounter
