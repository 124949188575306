export const hiddenColumns: Array<string> = [
  'selection',
  '_options',
  'ordered',
  'order',
  'order_number',
  'paymentMethod',
  'system',
  'OrderLineTags',
  'code',
  'ean',
  'id',
  'lastUpdateAuthor',
  'tags',
  'tags_test',
  'lastAction',
  'lock',
  'path',
  'backend_id',
  'image',
  'lastEditDate',
  'creationDate',
  'object_tags',
  'author',
  'lastEditAuthor',
  'children',
  'inProgress',
  'translations',
  'products',
  'stockCalculated',
  'permalink',
  'priority',
  'addDate',
  'stockAfterPacking',
  'stockAfterOrders',
  'stockInProgress',
  'stockDemand',
  'stockLastInvoice',
  'stockDemand',
  'stockLastInvoiceDate',
  'stockInvoices',
  'salesDate',
  'salesTime',
  'ProductID',
  'Tags',
  'stockMOQAfterChecking',
  'checkListItems',
  'vat',
  'wfirma_vat_id',
  'wfirma_id',
  'ProductCode',
  'Name',
  'ProducerName',
  'SupplierName',
  'shippingTimeStatusID',
  'avaliabilityStatusID',
]
export const TableRenderedColumns: Array<string> = [
  'ProductID',
  'Name',
  'ProductCode',
  'SupplierName',
  'SupplierDeliveryCode',
  'SupplierOrderId',
  'OrderLink',
  'Notes',
  'ProducerName',
  'OrderedQuantity',
  'SupplierDeliveryDate',
  'Tags',
  'DeliveredQuantity',
  'DeliveredDate',
]
export const ReplaceEmptyStringsForNulls: Array<string> = [
  'minimumWarnLevel',
  'secureWarnLevel',
  'maximumWarnLevel',
  'quantityToOrder',
  'minimumQuantityForSupplierOrder',
  'availabilityDate',
]

export const FormatIntegers: Array<string> = [
  'priority',
  'activation_treshold_after_packing',
  'min_shipping_time',
  'max_shipping_time',
  'instance',
  'depth',
  'positioning_priority',
  'minimumQuantityForSupplierOrder',
  'completable',
]
export const ReplaceEmptyStringsForZeros: Array<string> = [
  'DeliveredQuantity',
  'OrderedQuantity',
]

export const AllowEmptyStrings: Array<string> = ['SupplierDeliveryDate']
export const ValidateArray: { [key: string]: string[] } = {
  stockAmountInPackage: ['positive'],
}
