/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { hiddenColumns } from '../../columnsConfig'

import { Container, Footer, InnerCont } from './EditRenderedProduct.styles'

import { StoreContext } from '../../App'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import { socket } from '../../socket/socket'
import Button from '../button/Button.component'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'

export interface EditProductsProps extends PropsWithChildren {
  row: any
  columns: Array<{ header: string; value: string }>
  tableRow: string
}

const EditProducts: (props: EditProductsProps) => JSX.Element = ({
  row,
  columns,
  tableRow,
}: EditProductsProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  console.log(row.getAllCells())
  const { width, height } = useWindowDimensions()
  const [isAbleToSave, setIsAbleToSave] = useState(false)
  const [triggerChange, setTriggerChange] = useState(false)
  const divRef = useRef<any>(null)
  const visibleColumns = columns.filter(
    (e) => !hiddenColumns.includes(e.header),
  )
  const initialState = [
    ...visibleColumns.map((e) => {
      return {
        header: e.header,
        value: e.value,
        error: '',
        rules: ['required', 'length'],
        isActive: false,
      }
    }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const handleSave = async () => {
    let fetchObject: { [key: string]: string | object } = {
      id: tableRow,
    }

    let singleProduct = {}

    form.map((e) => {
      if (e.isActive)
        singleProduct = {
          [e.header]: e.value,
          ...singleProduct,
        }
    })
    const code = row
      .getAllCells()
      .find((e: any) => e.column.id === 'ProductCode')
      ?.getValue()

    fetchObject = {
      products: { [code]: singleProduct },
      ...fetchObject,
    }

    store.SlidingPanelState.setVisibility(false)
    const formattedProduct = store.TableStore.formatRequestData(fetchObject)
    await store.TableStore.updateData(formattedProduct)

    socket.emit('editOrder', {
      id: tableRow,
      user: store.UserStore.user,
      page: store.TableStore.getApiRequestFromUrl(),
    })

    store.TableStore.setSelectedExpanded([
      { rowId: tableRow, values: singleProduct },
    ])
    setTimeout(async () => {
      await store.TableStore.fetchData()
    }, 3000)
  }

  useEffect(() => {
    setInputColumns(Math.ceil((90 * visibleColumns.length) / (height * 0.8)))
  }, [height])

  useEffect(() => {
    setIsAbleToSave(form.some((item) => item.isActive))
  }, [form])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont ref={divRef} cols={inputColumns}>
        {visibleColumns
          ? visibleColumns.map((e, index) => {
              const customOptions = row
                .getAllCells()
                .find((f: any) => f.column.id === e.header)?.column
                .columnDef.editCustomOptions

              if (
                customOptions &&
                customOptions.editable &&
                e.header !== 'equal'
              )
                return (
                  <DataInputGeneric
                    type={'edit'}
                    vals={e}
                    editOptions={customOptions}
                    key={`data-input--${index}`}
                    index={index}
                    form={form}
                    setForm={handleSetForm}
                    triggerChange={() => setTriggerChange(!triggerChange)}
                  />
                )
            })
          : null}
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={async () => {
            if (isAbleToSave) await handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default EditProducts
