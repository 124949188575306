import { observer } from 'mobx-react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { StoreContext } from '../../App'
import { BreadcrumbWrapper } from './Breadcrumb.styles'
import { NavLink } from 'react-router-dom'
import { IconArrowBackUp } from '@tabler/icons-react'

interface BreadcrumbProps {
  treeArray: Array<string>
}

const Breadcrumb = observer(({ treeArray }: BreadcrumbProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const lastItem = treeArray.length - 1
  const navigate = useNavigate()

  if (!treeArray.length) return <></>
  return (
    <BreadcrumbWrapper {...Theme.breadcrumbStyles.breadcrumbWrapper}>
      <button onClick={() => navigate(-1)}>
        <IconArrowBackUp
          color={Theme.breadcrumbStyles.breadcrumbWrapper.color || '#303030'}
          size={12}
          style={{
            opacity: 0.9,
          }}
        />
      </button>
      {treeArray.map((item, index) => (
        <NavLink
          to={index === 0 ? '/' : `/${store.RouteStore.currentPage}`}
          key={index}
        >
          {item} {index !== lastItem ? '-> ' : ''}
        </NavLink>
      ))}
    </BreadcrumbWrapper>
  )
})

export default Breadcrumb
