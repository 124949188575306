import { observer } from 'mobx-react'
import { FC, PropsWithChildren, useContext, useEffect, useState } from 'react'

import { StoreContext } from '../../App'
import QueryBuilder from '../queryBuilder/QueryBuilder.component'
/* eslint-disable */

import {
  CheckBoxCont,
  FooterCont,
  LeftFilter,
  MainCont,
  QueryBuilderWrap,
  QueryFilterWrap,
  RightFilter,
  SaveInput,
  SelectButtonCont,
  SelectCont,
  SelectInput,
  SelectLabel,
  StyledParagraph,
} from './QueryFilter.styles'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'
import { toJS } from 'mobx'
import Button from '../button/Button.component'

interface Props extends PropsWithChildren {
  mobile?: boolean
  fetchData: () => Promise<void>
  setLoaded?: (loaded: boolean) => void
  allColumns: any
  tableName: string
  table: any
}

const QueryFilter: FC<Props> = observer(
  ({ table, tableName, mobile, allColumns, fetchData }) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme

    const locales = store.TranslationsState.translations

    const [selectedFilter, setSelectedFilter] = useState(false)
    const [defaultFiltersValue, setDefaultFiltersValue] = useState(
      store.FiltersStore.appliedPredefinedQuery.defaultFilter,
    )
    const [predefinedFiltersValue, setPredefinedFiltersValue] =
      useState('empty')
    const [saveFilter, setSaveFilter] = useState(false)
    const [saveValue, setSaveValue] = useState('')
    const [privateFilter, setPrivateFilter] = useState(false)

    const sendRequest = async () => {
      store.FiltersStore.applyDefaultAndPredefinedQuery(
        defaultFiltersValue,
        predefinedFiltersValue,
      )
      store.FiltersStore.applyQuery()
      fetchData()
    }

    const clearFilters = async () => {
      setPredefinedFiltersValue('empty')
      if (store.FiltersStore.query.rules.length > 0) {
        store.FiltersStore.setEmptyQuery()
        store.FiltersStore.applyQuery()
        fetchData()
      }
    }

    const cancelFilters = () => {
      setDefaultFiltersValue(
        store.FiltersStore.appliedPredefinedQuery.defaultFilter,
      )
      setPredefinedFiltersValue(
        store.FiltersStore.appliedPredefinedQuery.predefinedFilter,
      )
      store.FiltersStore.setQuery(store.FiltersStore.appliedQuery)
    }

    const saveQuery = async () => {
      const url = `${process.env.REACT_APP_BASE_URL}experience/v1/filter/_create`
      const data = {
        filter: {
          query: store.FiltersStore.query,
          label: saveValue,
        },
        access_group: privateFilter ? 'private' : 'public',
        created_by: store.UserStore.userID,
        view: store.TableStore.getApiRequestFromUrl(),
      }
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          username: store.UserStore.user.length
            ? store.UserStore.user
            : 'No data',
        },
      }
      await axios.post(url, data, config)
      await toast(locales.successes.success, {
        type: 'success',
        autoClose: 5000,
      })
      await store.FiltersStore.fetchAllFilters()
    }

    const filterIds = (query: any) => {
      const filteredQuery: any = {
        combinator: query.combinator,
        rules: query.rules.map((rule: any) => {
          const { id, ...rest } = rule
          return rest
        }),
        not: query.not,
      }
      return filteredQuery
    }

    function areQueriesEqual(query1: any, query2: any): boolean {
      if (
        query1.combinator !== query2.combinator ||
        query1.not !== query2.not ||
        query1.rules.length !== query2.rules.length
      ) {
        return false
      }

      for (let i = 0; i < query1.rules.length; i++) {
        const rule1 = query1.rules[i]
        const rule2 = query2.rules[i]

        if (JSON.stringify(rule1) !== JSON.stringify(rule2)) {
          return false
        }
      }

      return true
    }

    useEffect(() => {
      if (store.SlidingPanelState.selectedSlider === 'optionsList') {
        store.FiltersStore.setQuery(store.FiltersStore.appliedQuery)
        setDefaultFiltersValue(
          store.FiltersStore.appliedPredefinedQuery.defaultFilter,
        )
        setPredefinedFiltersValue(
          store.FiltersStore.appliedPredefinedQuery.predefinedFilter,
        )
      }
    }, [])

    useEffect(() => {
      store.FiltersStore.setQuery(store.FiltersStore.appliedQuery)
      setDefaultFiltersValue(
        store.FiltersStore.appliedPredefinedQuery.defaultFilter,
      )
      setPredefinedFiltersValue(
        store.FiltersStore.appliedPredefinedQuery.predefinedFilter,
      )
    }, [
      store.SlidingPanelState.selectedSlider,
      store.FiltersStore.appliedPredefinedQuery.defaultFilter,
      store.FiltersStore.appliedPredefinedQuery.predefinedFilter,
    ])

    return (
      <MainCont>
        {saveFilter ? (
          <>
            <SelectCont>
              <SelectLabel>Set a name for the input</SelectLabel>
              <SaveInput
                value={saveValue}
                onChange={(e) => setSaveValue(e.target.value)}
              />
              <CheckBoxCont>
                <StyledParagraph>Make this filter private</StyledParagraph>
                <input
                  type={'checkbox'}
                  checked={privateFilter}
                  onChange={(e) => setPrivateFilter(e.target.checked)}
                />
              </CheckBoxCont>
            </SelectCont>
            <FooterCont>
              <Button type={'secondary'} onClick={() => setSaveFilter(false)}>
                {locales.buttons.cancel}
              </Button>
              <Button
                margin={'0 12px 8px 0'}
                type={'primary'}
                onClick={() => {
                  saveQuery()
                  setPredefinedFiltersValue(saveValue)
                  setSaveValue('')
                  setSaveFilter(false)
                  //store.TableStore.setEmptyQuery()
                }}
              >
                {locales.buttons.save}
              </Button>
            </FooterCont>
          </>
        ) : (
          <>
            <QueryFilterWrap>
              <SelectButtonCont>
                <LeftFilter
                  selected={selectedFilter}
                  onClick={() => setSelectedFilter(false)}
                >
                  {locales.buttons.predefinedFilter}
                </LeftFilter>
                <RightFilter
                  selected={selectedFilter}
                  onClick={() => setSelectedFilter(true)}
                >
                  {locales.buttons.newFilter}
                </RightFilter>
              </SelectButtonCont>
              <div>
                {selectedFilter ? (
                  <QueryBuilderWrap>
                    <QueryBuilder
                      table={table}
                      keys={allColumns}
                      query={store.FiltersStore.query}
                      setQuery={(q: any) => store.FiltersStore.setQuery(q)}
                    />
                  </QueryBuilderWrap>
                ) : (
                  <SelectCont>
                    <SelectLabel>
                      {locales.actions.chooseDefaultFilter}
                    </SelectLabel>
                    <SearchSelectComponent
                      Container={SelectInput}
                      value={defaultFiltersValue}
                      options={[
                        { value: '', label: `` },
                        ...store.FiltersStore.currentFilters,
                      ]}
                      onClick={(e) => {
                        setDefaultFiltersValue(e.value)
                      }}
                      deleteFunction={async (e) =>
                        await store.FiltersStore.removeFilter(e.id)
                      }
                    />
                    <SelectLabel>
                      {locales.actions.choosePredefinedFilter}
                    </SelectLabel>
                    <SearchSelectComponent
                      Container={SelectInput}
                      value={predefinedFiltersValue}
                      options={[
                        { value: '', label: `` },
                        ...store.FiltersStore.currentFilters,
                      ]}
                      onClick={(e) => {
                        setPredefinedFiltersValue(e.value)
                        const selectedFilter =
                          store.FiltersStore.allFilters.find(
                            (f: any) => f.id === e.value,
                          )
                        selectedFilter &&
                          store.FiltersStore.setQuery(
                            selectedFilter.action.query,
                          )
                      }}
                      deleteFunction={async (e) =>
                        await store.FiltersStore.removeFilter(e.id)
                      }
                    />
                  </SelectCont>
                )}
              </div>
            </QueryFilterWrap>
            <FooterCont>
              {!areQueriesEqual(
                filterIds(store.FiltersStore.appliedQuery),
                filterIds(store.FiltersStore.query),
              ) ? (
                <Button
                  type={'secondary'}
                  margin={'0 0 8px 0'}
                  onClick={() => cancelFilters()}
                >
                  {locales.buttons.backToDefaultQuery}
                </Button>
              ) : (
                <></>
              )}
              {store.FiltersStore.appliedQuery.rules.length ? (
                <Button
                  type={'secondary'}
                  margin={'0 0 8px 0'}
                  onClick={() => clearFilters()}
                >
                  {locales.buttons.clearFilters}
                </Button>
              ) : (
                <></>
              )}
              {store.FiltersStore.query.rules.length > 0 && (
                <>
                  {selectedFilter ? (
                    <Button
                      type={'secondary'}
                      margin={'0 0 8px 0'}
                      onClick={() => setSaveFilter(true)}
                    >
                      {locales.buttons.save}
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {!areQueriesEqual(
                filterIds(store.FiltersStore.appliedQuery),
                filterIds(store.FiltersStore.query),
              ) ? (
                <Button
                  margin={'0 12px 8px 0'}
                  type={'primary'}
                  onClick={() => sendRequest()}
                >
                  {locales.buttons.apply}
                </Button>
              ) : (
                <></>
              )}
              {store.FiltersStore.appliedPredefinedQuery.defaultFilter !==
              defaultFiltersValue ? (
                <Button
                  margin={'0 12px 8px 0'}
                  type={'primary'}
                  onClick={async () => {
                    const tempDefaultFilters: any =
                      store.UserStore.defaultFilter
                    tempDefaultFilters[tableName] = defaultFiltersValue
                    await store.UserStore.updateDefaultSettings(
                      store.UserStore.defaultLanguage,
                      store.UserStore.defaultTheme,
                      store.UserStore.defaultAction,
                      tempDefaultFilters,
                      store.UserStore.defaultView,
                    )
                    await store.FiltersStore.applyDefaultAndPredefinedQuery(
                      defaultFiltersValue,
                      predefinedFiltersValue,
                    )
                    await toast(locales.successes.saved, {
                      type: 'success',
                      autoClose: 5000,
                    })
                  }}
                  {...Theme.buttons.primaryButton}
                >
                  {locales.buttons.saveDefault}
                </Button>
              ) : (
                <></>
              )}
            </FooterCont>
          </>
        )}
      </MainCont>
    )
  },
)
export default QueryFilter
