import { observer } from 'mobx-react'
import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import { StoreContext } from '../../App'
/* eslint-disable */

import {
  FooterCont,
  SaveInput,
  SelectCont,
  SelectInput,
  SelectLabel,
  ViewButton,
  Header,
  SelectBody,
} from './Views.styles'
import { toast } from 'react-toastify'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'
import Button from '../button/Button.component'
import { ViewOption } from '../../stores/View.store'

interface Props extends PropsWithChildren {
  tableName: string
  pagination?: number
  fetchData: any
  setLoaded?: (loaded: boolean) => void
}

const Views: FC<Props> = observer(
  ({ tableName, pagination, fetchData, setLoaded }) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    const [createMode, setCreateMode] = useState(false)
    const [saveValue, setSaveValue] = useState('')
    const [predefinedValueInInput, setPredefinedValueInInput] = useState(
      store.ViewStore.selectedView,
    )
    const [defaultViewValueInInput, setDefaultViewValueInInput] = useState(
      store.ViewStore.defaultView,
    )

    const [reset, setReset] = useState(false)

    const setSaveView = async () => {
      try {
        await store.ViewStore.saveView(saveValue, tableName, true, pagination)
        store.SlidingPanelState.setVisibility(false)
        setTimeout(async () => {
          await store.ViewStore.getAllViews()
          const views = await store.ViewStore.getViews(tableName)
          await store.ViewStore.getViewsList(views, true)
        }, 1000)
      } catch (e) {
        toast('Error', {
          type: 'error',
        })
      }
    }

    const deleteView = async (view: { value: string; label: string }) => {
      console.log('testviwe', view)
      try {
        await store.ViewStore.deleteView(view.value)
        toast('Deleted', {
          type: 'success',
        })
        const views = await store.ViewStore.getViews(tableName)
        await store.ViewStore.getViewsList(views, true)
      } catch (e) {
        toast('Error', {
          type: 'error',
        })
      }
    }

    const initViews = async () => {
      const views = await store.ViewStore.getViews(tableName)
      await store.ViewStore.getViewsList(views, true)
    }
    useEffect(() => {
      initViews()
    }, [])

    useEffect(() => {
      if (store.TableStore.loaded) {
        if (!reset) setReset(true)
        else store.ViewStore.setSelectedView('')
      }
    }, [
      store.ViewStore.hiddenColumns,
      store.ViewStore.columnOrder,
      store.ViewStore.columnColors,
      store.ViewStore.columnBorder,
      store.TableStore.clipTextColumns,
      store.FiltersStore.query,
      store.FiltersStore.appliedPredefinedQuery,
    ])

    return (
      <SelectCont>
        <Header>
          <ViewButton
            selected={createMode}
            view={'left'}
            onClick={() => setCreateMode(false)}
          >
            Predefined View
          </ViewButton>
          <ViewButton
            view={'right'}
            selected={!createMode}
            onClick={() => setCreateMode(true)}
          >
            New View
          </ViewButton>
        </Header>
        {createMode ? (
          <>
            <SelectBody>
              <SelectLabel>Set a name for this view</SelectLabel>
              <SaveInput
                value={saveValue}
                onChange={(e) => setSaveValue(e.target.value)}
              />
            </SelectBody>
            <FooterCont>
              <Button
                type={'secondary'}
                onClick={() => store.SlidingPanelState.setVisibility(false)}
                {...Theme.buttons.secondaryButton}
              >
                {locales.buttons.cancel}
              </Button>
              <Button
                margin={'0 12px 0 0'}
                type={'primary'}
                onClick={async () => {
                  await setSaveView()
                }}
                {...Theme.buttons.primaryButton}
              >
                {locales.buttons.save}
              </Button>
            </FooterCont>
          </>
        ) : (
          <>
            <SelectBody>
              <SelectLabel>Select Default view:</SelectLabel>
              <SearchSelectComponent
                Container={SelectInput}
                value={defaultViewValueInInput}
                options={store.ViewStore.viewsList}
                emptyValue={'No view...'}
                onClick={async (e) => {
                  await setDefaultViewValueInInput(e.value)
                }}
                deleteFunction={async (e) => await deleteView(e)}
              />
              <SelectLabel>Select predefined view:</SelectLabel>
              <SearchSelectComponent
                Container={SelectInput}
                value={predefinedValueInInput}
                options={store.ViewStore.viewsList}
                onClick={async (e) => {
                  setPredefinedValueInInput(e.value)
                }}
                emptyValue={'No view...'}
                deleteFunction={async (e) => await deleteView(e)}
              />
            </SelectBody>
            {store.ViewStore.selectedView !== predefinedValueInInput ||
            defaultViewValueInInput !== store.ViewStore.defaultView ? (
              <FooterCont>
                <Button
                  type={'secondary'}
                  onClick={() => store.SlidingPanelState.setVisibility(false)}
                  {...Theme.buttons.secondaryButton}
                >
                  {locales.buttons.cancel}
                </Button>
                {store.ViewStore.selectedView !== predefinedValueInInput ? (
                  <Button
                    margin={'0 12px 0 0'}
                    type={'primary'}
                    onClick={async () => {
                      store.ViewStore.setSelectedView(predefinedValueInInput)
                      if (setLoaded) await setLoaded(false)
                      if (!store.ViewStore.selectedView.length)
                        store.ViewStore.clearValues()
                      else
                        await store.ViewStore.applyView(
                          store.ViewStore.selectedView,
                        )
                      await fetchData()
                      await setReset(true)
                      if (setLoaded) await setLoaded(true)
                    }}
                  >
                    {locales.buttons.apply}
                  </Button>
                ) : (
                  <></>
                )}
                {defaultViewValueInInput !== store.ViewStore.defaultView ? (
                  <Button
                    margin={'0 12px 0 0'}
                    type={'primary'}
                    onClick={async () => {
                      await store.ViewStore.setDefaultView(
                        defaultViewValueInInput,
                        tableName,
                      )
                    }}
                  >
                    {'SAVE DEFAULT'}
                  </Button>
                ) : (
                  <></>
                )}
              </FooterCont>
            ) : (
              <></>
            )}
          </>
        )}
      </SelectCont>
    )
  },
)
export default Views
