/* eslint-disable */
import { PropsWithChildren, useContext } from 'react'

import { Container, Footer, InnerCont } from './RemoveRenderedProduct.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import { socket } from '../../socket/socket'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  row: any
  tableRow: string
}

const RemoveRenderedProduct: (props: EditProductsProps) => JSX.Element = ({
  row,
  tableRow,
}: EditProductsProps) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations

  const handleSave = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${tableRow}`
    const code = row
      .getAllCells()
      .find((e: any) => e.column.id === 'ProductID')?.value
    const data = {
      id: tableRow,
      lastEditDate: new Date().toISOString(),
      lastEditAuthor: store.UserStore.user,
      products: {
        [code]: null,
      },
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }
    const response = await axios.post(url, data, config)
    if (response.status === 200) {
      toast('Product removed successfully', { type: 'success' })
      store.SlidingPanelState.setVisibility(false)
      socket.emit('editOrder', {
        id: tableRow,
        user: store.UserStore.user,
        page: store.TableStore.getApiRequestFromUrl(),
      })
      /*setTimeout(() => {
                                                                                                                    store.TableStore.fetchData()
                                                                                                                  }, 2000)*/
    }
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont>
        <p>{locales.warnings.wantToRemove}</p>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {locales.buttons.cancel}
        </Button>
        <Button type={'primary'} onClick={() => handleSave()}>
          {'CONFIRM'}
        </Button>
      </Footer>
    </Container>
  )
}
export default RemoveRenderedProduct
