import { observer } from 'mobx-react'
import {
  Label,
  Setting,
  SettingsCategory,
} from '../../../../pages/settings/settings.styles'
import { useContext } from 'react'
import { StoreContext } from '../../../../App'

export const InterfaceComponent = observer(() => {
  const store = useContext(StoreContext)
  const { Navigation, themeName } = store.Theme

  return (
    <SettingsCategory>
      <h1>Personal Settings</h1>
      <h2>Interface options</h2>

      <Setting>
        <Label>Default navigation layout:</Label>
        <select
          value={Navigation}
          onChange={(e) => {
            store.Theme.switchNavigation(e.target.value)
          }}
        >
          <option value='top'>Top Bar</option>
          <option value='side'>Sidebar</option>
        </select>
      </Setting>

      <Setting>
        <Label>Select a theme:</Label>
        <select
          value={themeName}
          onChange={(e) => {
            store.Theme.setTheme(e.target.value)
          }}
        >
          {store.Theme.themeKeys.map((themeKey) => (
            <option key={`themeKey-${themeKey}`} value={themeKey}>
              {themeKey}
            </option>
          ))}
        </select>
      </Setting>

      <Setting>
        <Label>Default Theme:</Label>
        <select
          value={store.UserStore.defaultTheme}
          onChange={(e) =>
            store.UserStore.updateDefaultSettings(
              store.UserStore.defaultLanguage,
              e.target.value,
              store.UserStore.defaultAction,
              store.UserStore.defaultFilter,
              store.UserStore.defaultView,
            )
          }
        >
          {store.Theme.themeKeys.map((themeKey) => (
            <option key={`themeKey-${themeKey}`} value={themeKey}>
              {themeKey}
            </option>
          ))}
        </select>
      </Setting>

      <Setting>
        <Label>Default Language:</Label>
        <select
          value={store.UserStore.defaultLanguage}
          onChange={(e) =>
            store.UserStore.updateDefaultSettings(
              e.target.value,
              store.UserStore.defaultTheme,
              store.UserStore.defaultAction,
              store.UserStore.defaultFilter,
              store.UserStore.defaultView,
            )
          }
        >
          {store.TranslationsState.avaliableLanguages.map((langKey) => (
            <option key={`themeKey-${langKey}`} value={langKey}>
              {langKey}
            </option>
          ))}
        </select>
      </Setting>
    </SettingsCategory>
  )
})
