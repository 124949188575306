import Table from '../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import TableImage from '../_tableGeneric/tableImage/TableImage.component'
import TableReturnsProductComponent from '../_tableGeneric/tableReturnsProduct/TableReturnsProduct.component'
import TableReturnsCounter from '../_tableGeneric/tableReturnsCounter/TableReturnsCounter.component'
import { StyledSelect } from '../dataInputGeneric/DataInputGeneric.styles'
import TableReturnsDecisionComponent from '../_tableGeneric/tableReturnsDecision/TableReturnsDecision.component'
/* eslint-disable */
const ReturnsDetailsTable = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations

  const getImageUrl = (cell: any) => {
    const code = cell.row
      .getAllCells()
      .find((e: any) => e.column.id === 'code')
      ?.getValue()

    const orderDetails: any = store.TableStore.detailsData.products
      ? Object.values(store.TableStore.detailsData.products).find(
          (e: any) => e.code === code,
        )
      : null

    if (orderDetails) return orderDetails.image_url
    else return null
  }

  const columns = {
    id: {
      Cell: (cell) =>
        cell.row
          .getAllCells()
          .find((e) => e.column.id === 'code')
          ?.getValue(),
    },
    code: {},
    product: {
      Cell: (cell) => <TableReturnsProductComponent cell={cell} />,
    },
    image: {
      Header: locales.stockColumns.image,
      Cell: (cell) => <TableImage url={getImageUrl(cell)} />,
      canFilter: false,
      canSort: false,
      editable: false,
    },
    count: {
      Cell: (cell) => <TableReturnsCounter cell={cell} />,
    },
    decision: {
      Header: 'Decision',
      Cell: (cell) => <TableReturnsDecisionComponent cell={cell} />,
    },
  }

  return (
    <Table
      config={{
        tableName: 'returnsDetails',
        data: store.ReturnsStore.detailsData?.products || [],
        fetchData: async () => await store.ReturnsStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: false,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: true,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: true,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: false,
          title: 'Returns',
          subTitle: 'Returns',
        },
        breadcrumb: [],
        loaded: store.ReturnsStore.loaded,
      }}
    />
  )
})

export default ReturnsDetailsTable
