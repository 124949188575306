import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import axios from 'axios'
import { toast } from 'react-toastify'

export interface ViewOption {
  value: string
  label: string
  delete?: boolean
}

export class ViewStore {
  rootStore
  columnOrder: string[] = []
  hiddenColumns: any = {}
  allViews: any[] = []
  views: any[] = []
  viewsList: ViewOption[] = []
  defaultView = ''
  selectedView = ''
  columnColors: { column: string; color: string }[] = []
  columnBorder: { column: string; border: boolean }[] = []
  loaded = true

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound setColumnOrder = (columnOrder: string[]) => {
    this.columnOrder = columnOrder
  }

  @action.bound setSelectedView = (selectedView: string) => {
    this.selectedView = selectedView
  }

  @action.bound setHiddenColumns = (tableName: string, hiddenColumns?: any) => {
    console.log(toJS(hiddenColumns))
    if (Object.keys(hiddenColumns).length) {
      this.hiddenColumns = hiddenColumns
      localStorage.setItem(
        `hiddenColumns-${tableName}`,
        JSON.stringify(hiddenColumns),
      )
    }
  }

  @action.bound setColor = (column: string, color: string) => {
    const columnIndex = this.columnColors.findIndex(
      (col) => col.column === column,
    )
    if (columnIndex !== -1) {
      this.columnColors[columnIndex].color = color
    } else {
      this.columnColors.push({ column, color })
    }
  }

  @action.bound setBorder = (column: string, border: boolean) => {
    const columnIndex = this.columnBorder.findIndex(
      (col) => col.column === column,
    )
    if (columnIndex !== -1) {
      this.columnBorder[columnIndex].border = border
    } else {
      this.columnBorder.push({ column, border })
    }
  }

  @action.bound getDefaultAction = (tableName: string) => {
    if (
      Object.prototype.hasOwnProperty.call(
        this.rootStore.UserStore.defaultAction,
        tableName,
      )
    )
      //@ts-ignore
      return this.rootStore.UserStore.defaultAction[tableName]
  }

  @action.bound convertHiddenColumnsToArray() {
    return Object.entries(this.hiddenColumns)
      .filter(([_, value]) => !value)
      .map(([key]) => key)
  }

  @action.bound convertArrayToHiddenColumns(columnArray: string[]) {
    const hiddenColumns = Object.keys(this.hiddenColumns).reduce(
      (acc, key) => {
        acc[key] = !columnArray.includes(key) // If key is NOT in the array, set to true (hidden)
        return acc
      },
      {} as Record<string, boolean>,
    )

    this.hiddenColumns = hiddenColumns
    return hiddenColumns
  }

  @action.bound clearValues = () => {
    this.columnOrder = []
    this.hiddenColumns = {}
    this.columnColors = []
    this.columnBorder = []
  }

  @action.bound getColumnsWithBorders(): string[] {
    return this.columnBorder
      .filter((col) => col.border)
      .map((col) => col.column)
  }

  @action.bound createTemporaryViewObject = (tableName, pagination) => {
    const viewObject = {
      tableName: tableName,
      tableSize: pagination || null,
      hiddenColumns: toJS(this.hiddenColumns),
      columnOrder: toJS(this.columnOrder),
      columnColors: toJS(this.columnColors),
      columnBorder: this.getColumnsWithBorders(),
      clipTextColumns: toJS(this.rootStore.TableStore.clipTextColumns),
      appliedPredefinedQuery: toJS(
        this.rootStore.FiltersStore.appliedPredefinedQuery,
      ),
      sort: {
        column: toJS(this.rootStore.TableStore.sort.column) || 'none',
        desc: toJS(this.rootStore.TableStore.sort.desc) || 'desc',
        type: toJS(this.rootStore.TableStore.sort.type) || 'none',
      },
      favoriteAction: toJS(this.getDefaultAction(tableName)) || '',
      showSearchField: toJS(this.rootStore.TableStore.showSearchField),
      infinitySwitch: toJS(this.rootStore.TableStore.infinitySwitch),
    }
    return viewObject
  }

  @action.bound createSaveAbleViewObject = (
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const viewObject: { [key: string]: any } = this.createTemporaryViewObject(
      tableName,
      pagination,
    )

    viewObject.name = name
    viewObject.user = toJS(this.rootStore.UserStore.userID)
    viewObject.private = privateView
    viewObject.allowedUsers = [this.rootStore.UserStore.userID]
    viewObject.allowedGroups = []

    return viewObject
  }

  @action.bound getDefaultView(tableName: string) {
    console.log('dw', toJS(this.rootStore.UserStore.defaultView), tableName)
    if (this.rootStore.UserStore.defaultView[tableName]) {
      this.defaultView = this.rootStore.UserStore.defaultView[tableName]
      return this.rootStore.UserStore.defaultView[tableName]
    } else {
      this.defaultView = ''
      return ''
    }
  }

  @action.bound setDefaultView = async (id, tableName: string) => {
    const tempDefaultViews: any = this.rootStore.UserStore.defaultView
    tempDefaultViews[tableName] = id

    await this.rootStore.UserStore.updateDefaultSettings(
      this.rootStore.UserStore.defaultLanguage,
      this.rootStore.UserStore.defaultTheme,
      this.rootStore.UserStore.defaultAction,
      this.rootStore.UserStore.defaultFilter,
      tempDefaultViews,
    )

    this.defaultView = id
    toast(
      `Default view set successfully! ${this.allViews.find((e) => e.id === id)?.name || id}`,
      { type: 'success' },
    )
  }

  @action.bound saveView = async (
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const url =
      'https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views/_create'

    const viewObject = this.createSaveAbleViewObject(
      name,
      tableName,
      privateView,
      pagination,
    )

    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        username: this.rootStore.UserStore.user,
      },
    }

    const response = await axios.post(url, viewObject, config)
    await toast('View saved successfully', { type: 'success' })
  }

  @action.bound getAllViews = async () => {
    const url =
      'https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views'
    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
      },
    }
    const response = await axios.get(url, config)
    this.allViews = response.data
    console.log(response.data)
  }

  @action.bound getViews = async (tableName: string) => {
    this.views = this.allViews
      .filter((e: any) => e.tableName === tableName)
      .filter(
        (e: any) =>
          e.allowedUsers.includes(this.rootStore.UserStore.userID) ||
          !e.private,
      )
    return this.views
  }

  @action.bound getViewsList = async (views: any[], withDelete: boolean) => {
    const viewsList = [
      { value: '', label: ' ' },
      ...views.map((e: any) => {
        if (withDelete) {
          return {
            value: e.id,
            label: `${e.name} ${e.private ? `(private)` : `(public)`}`,
            delete: e.private,
          }
        } else
          return {
            value: e.id,
            label: `${e.name} ${e.private ? `(private)` : `(public)`}`,
          }
      }),
    ]

    this.viewsList = viewsList
  }

  @action.bound deleteView = async (id: string) => {
    const url = `https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views/${id}`
    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        'Access-Control-Allow-Methods':
          'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS',
      },
    }
    const response = await axios.delete(url, config)
  }

  @action.bound editView = async (
    id: string,
    name: string,
    tableName: string,
    privateView: boolean,
    pagination?: number,
  ) => {
    const url = `https://moodnook.westeurope.cloudapp.azure.com:443/api/views/v1/views/${id}`
    const viewObject = this.createSaveAbleViewObject(
      name,
      tableName,
      privateView,
      pagination,
    )
    const config = {
      headers: {
        'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
        'Access-Control-Allow-Methods':
          'GET,HEAD,PUT,PATCH,POST,DELETE,OPTIONS',
      },
    }
    const response = await axios.put(url, viewObject, config)
  }

  @action.bound applySessionView = async (sessionView: any) => {
    this.hiddenColumns = sessionView.hiddenColumns
    this.columnOrder = sessionView.columnOrder
    this.columnColors = sessionView.columnColors
    this.columnBorder = sessionView.columnBorder
    this.rootStore.TableStore.clipTextColumns = sessionView.clipTextColumns
    this.rootStore.FiltersStore.appliedPredefinedQuery =
      sessionView.appliedPredefinedQuery
    this.rootStore.TableStore.sort = sessionView.sort
    this.rootStore.TableStore.showSearchField = sessionView.showSearchField
    this.rootStore.TableStore.infinitySwitch = sessionView.infinitySwitch
  }

  @action.bound applyView = async (selectedView: string) => {
    this.loaded = false
    this.setSelectedView(selectedView)
    const view = this.views.find((e) => e.id === selectedView)

    if (view) {
      this.hiddenColumns = view.hiddenColumns
      this.columnOrder = view.columnOrder
      this.columnColors = view.columnColors
      this.columnBorder = view.columnBorder
      this.rootStore.TableStore.clipTextColumns = view.clipTextColumns
      this.rootStore.FiltersStore.appliedPredefinedQuery =
        view.appliedPredefinedQuery
      this.rootStore.TableStore.sort = {
        column: view.sort.column !== 'none' ? view.sort.column : undefined,
        desc: view.sort.desc !== 'none' ? view.sort.desc : undefined,
        type: view.sort.type !== 'none' ? view.sort.type : undefined,
      }
      this.rootStore.TableStore.showSearchField = view.showSearchField
      this.rootStore.TableStore.infinitySwitch = view.infinitySwitch
      this.loaded = true

      const selectedFilter = this.rootStore.FiltersStore.allFilters.find(
        (f: any) => f.id === view.appliedPredefinedQuery.defaultFilter,
      ) || {
        action: {
          query: {
            rules: [],
            combinator: 'and',
            not: false,
          },
        },
      }

      this.rootStore.FiltersStore.setQuery(selectedFilter.action.query)
      this.rootStore.FiltersStore.applyDefaultAndPredefinedQuery(
        view.appliedPredefinedQuery.defaultFilter,
        'empty',
      )
    } else {
      toast('Cannot find default view!')
    }
  }

  @action.bound setLoaded = async (loaded: boolean) => {
    this.loaded = loaded
  }
}
