import {
  IconAddressBook,
  IconBuildingFactory2,
  IconBuildingStore,
  IconCashBanknote,
  IconLayoutNavbar,
  IconLayoutSidebar,
  IconPackageExport,
  IconPackages,
  IconReport,
  IconSearch,
  IconShoppingCart,
  IconTruckReturn,
  IconUserCircle,
} from '@tabler/icons-react'
import { observer } from 'mobx-react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import '../../index.css'
import { StoreContext } from '../../App'
import { MoodNookLogo } from '../moodNookLogo/MoodNookLogo.component'
import { NavButton } from '../navButton/NavButton.component'

import {
  AbsoluteLeftPanel,
  Cont,
  Container,
  ExpanderCont,
  Hamburger,
  HamburgerBox,
  HamburgerContentHeader,
  HamburgerContentInner,
  HamburgerContentMain,
  HamburgerInner,
  HeaderShow,
  InnerRight,
  Input,
  InputPanel,
  LayoutSwitch,
  LeftPanel,
  Logo,
  MainPanel,
  RightCont,
  SearchContainer,
  SearchIcon,
  SettingsWrap,
  Sidebar,
  SideBarCont,
  SidebarInner,
  SidebarLogo,
  SidebarMenu,
  SidebarShow,
  SiderbarBottom,
  StyledSelect,
} from './Header.styles'

import Stock from '../../assets/icons/stock.svg'
import CustomerOrders from '../../assets/icons/customer-orders.svg'
import SupplierOrders from '../../assets/icons/supplier-order.svg'
import Reports from '../../assets/icons/reports.svg'
import Bank from '../../assets/icons/bank.svg'
import Packing from '../../assets/icons/packing.svg'
import Packages from '../../assets/icons/packages.svg'
import Settings from '../../assets/icons/settings.svg'
import Profile from '../../assets/icons/profile.png'
import Return from '../../assets/icons/return.svg'

import ArrowLeft from '../../assets/icons/arrow-left.svg'
import ArrowRight from '../../assets/icons/arrow-right.svg'

import { ReactComponent as ArrowsLeft } from '../../assets/icons/arrows-left.svg'
import { ReactComponent as ArrowsRight } from '../../assets/icons/arrows-right.svg'

import HeaderProfile from '../headerProfile/HeaderProfile.component'
import useClickOutside from '../../methods/methods/useClickOutside'
import useWindowDimensions from '../../methods/hooks/useWindowDimensions'
import SearchSelectComponent from '../searchSelect/SearchSelect.component'

interface HeaderProps {
  navType?: string | 'top' | 'side'
  setNavType?: (value: string) => void
}

/* eslint-disable */
const Header = observer(({ navType = 'top', setNavType }: HeaderProps) => {
  const store = useContext(StoreContext)
  const { Theme, sideBarActive, sideBarExpanded } = store.Theme
  const locales = store.TranslationsState.translations
  const { width } = useWindowDimensions()
  const useHover = <T extends HTMLElement>(): [
    (node?: T | null) => void,
    boolean,
  ] => {
    const [value, setValue] = useState(false)
    const handleMouseOver = useCallback(() => setValue(true), [])
    const handleMouseOut = useCallback(() => setValue(false), [])
    const ref = useRef<T>()
    const callbackRef = useCallback<(node?: null | T) => void>(
      (node) => {
        if (ref.current) {
          ref.current.removeEventListener('mouseover', handleMouseOver)
          ref.current.removeEventListener('mouseout', handleMouseOut)
        }
        ref.current = node || undefined
        if (ref.current) {
          ref.current.addEventListener('mouseover', handleMouseOver)
          ref.current.addEventListener('mouseout', handleMouseOut)
        }
      },
      [handleMouseOver, handleMouseOut],
    )
    return [callbackRef, value]
  }
  const [ref, isHovered] = useHover()

  const [active, setActive] = useState(false)
  const [activeProfile, setActiveProfile] = useState(false)
  const [sideInput, setSideInput] = useState(false)
  const headerRef = useRef(null)

  const [topLeftExpanded, setTopLeftExpanded] = useState(false)

  useEffect(() => {
    if (navType === 'top' && width < 1080) {
      store.Theme.switchNavigation('side')
    }
  }, [width])
  useClickOutside(headerRef, () => setSideInput(false))

  const hamburgerContent = (
    <HamburgerContentMain>
      <HamburgerContentHeader>
        <Logo to={'/'}>
          <MoodNookLogo />
        </Logo>
        <Hamburger onClick={() => setActive(!active)}>
          <HamburgerBox>
            <HamburgerInner active={active} />
          </HamburgerBox>
        </Hamburger>
      </HamburgerContentHeader>
      <HamburgerContentInner {...Theme.headerStyles.sidebarInner}>
        <SidebarMenu onClick={() => setActive(!active)}>
          <NavButton label={'customerorders'} />
          <NavButton label={'stock'} />
          <NavButton label={'supplierorders'} />
          <NavButton label={'reports'} />
          <NavButton label={'bank'} />
          <NavButton label={'packing'} />
          <NavButton label={'packages'} />
          <NavButton label={'returns'} />
          {/*user.includes('super.admin') ? <NavButton label={'admin'} /> : <></>*/}
        </SidebarMenu>
        <SiderbarBottom>
          <LayoutSwitch
            onClick={() => {
              setNavType && setNavType('top')
            }}
          >
            <IconLayoutNavbar color='#E0FBFC' size='24px' />
            {locales.buttons.switchLayout}
          </LayoutSwitch>
          <SidebarMenu>
            <NavButton label={'admin'} />
          </SidebarMenu>
          <SearchSelectComponent
            Container={StyledSelect}
            value={store.TranslationsState.setLanguage}
            onClick={(e) => {
              store.TranslationsState.setTranslations(e.value)
            }}
            options={[
              ...store.TranslationsState.avaliableLanguages.map((e) => ({
                value: e,
                label:
                  store.TranslationsState.getLanguageLabel(e) || 'No label',
              })),
            ]}
          />
        </SiderbarBottom>
      </HamburgerContentInner>
    </HamburgerContentMain>
  )

  if (navType === 'side') {
    return (
      <>
        <SideBarCont
          ref={ref}
          //active={sideBarActive || isHovered}
          active={sideBarActive}
          expanded={sideBarExpanded}
        />
        <SidebarShow
          onClick={() => {
            store.Theme.setSideBarActive(!sideBarActive)
          }}
          //active={sideBarActive || isHovered}
          active={sideBarActive}
          expanded={sideBarExpanded}
        >
          <p>
            {sideBarActive ? <img src={ArrowLeft} /> : <img src={ArrowRight} />}
          </p>
        </SidebarShow>
        <Sidebar
          ref={ref}
          //active={sideBarActive || isHovered}
          active={sideBarActive}
          expanded={sideBarExpanded}
        >
          <SidebarInner
            //active={sideBarActive || isHovered}
            active={sideBarActive}
            expanded={sideBarExpanded}
            {...Theme.headerStyles.sidebarInner}
          >
            <>
              <SidebarLogo {...Theme.headerStyles.sideBarLogo}>
                <MoodNookLogo />
              </SidebarLogo>
              <SidebarMenu>
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconShoppingCart size={'24px'} />
                    ) : undefined
                  }
                  label={'customerorders'}
                  tooltip={locales.tableNames.customerorders}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconBuildingStore size={'24px'} />
                    ) : undefined
                  }
                  label={'stock'}
                  tooltip={locales.tableNames.stock}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconBuildingFactory2 size={'24px'} />
                    ) : undefined
                  }
                  label={'supplierorders'}
                  tooltip={locales.tableNames.supplierorders}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? <IconReport size={'24px'} /> : undefined
                  }
                  label={'reports'}
                  tooltip={locales.tableNames.reports}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconCashBanknote size={'24px'} />
                    ) : undefined
                  }
                  label={'bank'}
                  tooltip={locales.tableNames.bank}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconPackageExport size={'24px'} />
                    ) : undefined
                  }
                  label={'packing'}
                  tooltip={locales.tableNames.packing}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? <IconPackages size='24px' /> : undefined
                  }
                  label={'packages'}
                  tooltip={locales.tableNames.packages}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconTruckReturn size='24px' />
                    ) : undefined
                  }
                  label={'returns'}
                  tooltip={locales.tableNames.returns}
                />
                <NavButton
                  icon={
                    !sideBarExpanded ? (
                      <IconAddressBook size='24px' />
                    ) : undefined
                  }
                  label={'contacts'}
                  tooltip={locales.tableNames.contacts}
                />
                {/*user.includes('super.admin') ? (
                  <NavButton label={'admin'} />
                ) : (
                  <></>
                )*/}
                <SearchIcon ref={headerRef}>
                  <IconSearch
                    //onClick={() => setSideInput(!sideInput)}
                    size='24px'
                    color={store.Theme.Theme.global.global.bgColor}
                  />
                  {sideInput && (
                    <SearchContainer>
                      <InputPanel
                        noHide={true}
                        {...Theme.headerStyles.inputPanel}
                      >
                        <IconSearch size='20px' color='#98c1d9' />
                        <Input
                          noHide={false}
                          id={'globalsearch'}
                          placeholder={locales.buttons.search}
                          type={'search'}
                          {...Theme.headerStyles.input}
                        />
                      </InputPanel>
                    </SearchContainer>
                  )}
                </SearchIcon>
              </SidebarMenu>
              <SiderbarBottom>
                <SidebarMenu>
                  <NavButton
                    icon={!sideBarExpanded ? <img src={Settings} /> : undefined}
                    label={'admin'}
                    tooltip={locales.tableNames.admin}
                  />
                </SidebarMenu>
                <Cont></Cont>
                <p style={{ color: 'white' }}>
                  {sideBarExpanded ? (
                    <ExpanderCont
                      onClick={() => store.Theme.setSideBarExpanded(false)}
                    >
                      <ArrowsLeft />
                      <p>Collapse Sidebar</p>
                    </ExpanderCont>
                  ) : (
                    <ExpanderCont
                      onClick={() => store.Theme.setSideBarExpanded(true)}
                    >
                      <ArrowsRight />
                      <p>Expand</p>
                    </ExpanderCont>
                  )}
                </p>
                <LayoutSwitch
                  onClick={() => {
                    setNavType && setNavType('top')
                    store.Theme.setSideBarActive(false)
                  }}
                >
                  <IconLayoutNavbar color='#E0FBFC' size='24px' />
                  {sideBarExpanded && locales.buttons.switchLayout}
                </LayoutSwitch>
                <SearchSelectComponent
                  Container={StyledSelect}
                  value={store.TranslationsState.setLanguage}
                  onClick={(e) => {
                    store.TranslationsState.setTranslations(e.value)
                  }}
                  options={[
                    ...store.TranslationsState.avaliableLanguages.map((e) => ({
                      value: e,
                      label:
                        store.TranslationsState.getLanguageLabel(e) ||
                        'No label',
                    })),
                  ]}
                />
              </SiderbarBottom>
            </>
          </SidebarInner>
        </Sidebar>
      </>
    )
  }

  return (
    <>
      <Container {...Theme.headerStyles.container} id={'header'}>
        <MainPanel>
          <Logo to={'/'}>
            <MoodNookLogo />
          </Logo>
          <LeftPanel expanded={topLeftExpanded}>
            <NavButton label={'customerorders'} />
            <NavButton label={'stock'} />
            <NavButton label={'supplierorders'} />
            <NavButton label={'reports'} />
            <NavButton label={'bank'} />
            <NavButton label={'packing'} />
            <NavButton label={'packages'} />
            <NavButton label={'returns'} />
            <NavButton label={'contacts'} />
          </LeftPanel>
          <AbsoluteLeftPanel expanded={!topLeftExpanded}>
            <NavButton label={'customerorders'} />
            <NavButton label={'stock'} />
            <NavButton label={'supplierorders'} />
            <NavButton label={'reports'} />
            <NavButton label={'bank'} />
            <NavButton label={'packing'} />
            <NavButton label={'packages'} />
            <NavButton label={'returns'} />
            <NavButton label={'contacts'} />
            <HeaderShow
              onClick={() => {
                setTopLeftExpanded(!topLeftExpanded)
              }}
            >
              {topLeftExpanded ? (
                <img src={ArrowLeft} />
              ) : (
                <img src={ArrowRight} />
              )}
            </HeaderShow>
          </AbsoluteLeftPanel>
          {!topLeftExpanded ? (
            <HeaderShow
              onClick={() => {
                setTopLeftExpanded(!topLeftExpanded)
              }}
            >
              <img src={ArrowRight} />
            </HeaderShow>
          ) : (
            <></>
          )}
          <InputPanel {...Theme.headerStyles.inputPanel}>
            <IconSearch size='20px' color='#98c1d9' />
            <Input
              id={'globalsearch'}
              placeholder={locales.buttons.search}
              type={'search'}
              {...Theme.headerStyles.input}
            />
          </InputPanel>
        </MainPanel>
        <RightCont>
          <InnerRight>
            <SettingsWrap>
              <NavButton label={'admin'} />
            </SettingsWrap>
            <SearchSelectComponent
              Container={StyledSelect}
              value={store.TranslationsState.setLanguage}
              onClick={(e) => {
                store.TranslationsState.setTranslations(e.value)
              }}
              options={[
                ...store.TranslationsState.avaliableLanguages.map((e) => ({
                  value: e,
                  label:
                    store.TranslationsState.getLanguageLabel(e) || 'No label',
                })),
              ]}
            />
          </InnerRight>
          <LayoutSwitch
            onClick={() => {
              setNavType && setNavType('side')
            }}
            {...Theme.headerStyles.layoutSwitch}
          >
            <IconLayoutSidebar color='#E0FBFC' size='24px' />
            {locales.buttons.switchLayout}
          </LayoutSwitch>
          <IconUserCircle
            size='36px'
            style={{ margin: 'auto', marginRight: '10px' }}
            onClick={() => setActiveProfile(!activeProfile)}
          />
          {activeProfile ? (
            <HeaderProfile close={() => setActiveProfile(false)} />
          ) : (
            <></>
          )}
          <Hamburger onClick={() => setActive(!active)}>
            <HamburgerBox>
              <HamburgerInner active={active} />
            </HamburgerBox>
          </Hamburger>
        </RightCont>
      </Container>
      {active && hamburgerContent}
    </>
  )
})

export default Header
