import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../../../../App'
import {
  DeleteButton,
  ArrayRow,
  ArrayContainer,
  MainContainer,
  Select,
} from './StringArraySelectWithLabel.styles'
import ClearFilters from '../../../../../assets/icons/clear-filters.svg'
import { hiddenColumns } from '../../../../../columnsConfig'
import Button from '../../../../button/Button.component'
import { toJS } from 'mobx'

/* eslint-disable */
interface StringArrayInputProps extends PropsWithChildren {
  data?: string[]
  dataList: { value: string; label: string }[]
  header: string
  index: number
  form: Array<{
    header: string
    value: any
    error: string
    rules: Array<string>
    isActive: boolean
  }>
  handleChange: (
    val: Array<{
      header: string
      value: any
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => void
  active: boolean
}

export const StringArraySelectWithLabel = ({
  data,
  handleChange,
  header,
  form,
  index,
  dataList,
  active,
}: StringArrayInputProps) => {
  const store = useContext(StoreContext)
  const { TagsStore } = store
  const { Theme } = store.Theme
  console.log(toJS(dataList))
  const [selectedData, setSelectedData] = useState<string[]>(data || [])

  const handleSelectChange = (i: number, newValue: string) => {
    setSelectedData((prevData) => {
      const updatedData = [...prevData]
      updatedData[i] = newValue
      return updatedData
    })
  }

  const handleAddSelect = () => {
    if (selectedData.length < dataList.length)
      setSelectedData((prevData) => {
        const availableOptions = dataList.filter(
          (item) => !prevData.includes(item.value),
        )
        const newValue = availableOptions[0]?.value || ''
        return [...prevData, newValue]
      })
  }

  const deleteField = (i: number) => {
    setSelectedData((prevData) => {
      const updatedData = [...prevData]
      updatedData.splice(i, 1)
      return updatedData
    })
  }

  const getOptions = (excludeValue: string) => {
    return dataList.filter(
      (item) =>
        !selectedData.includes(item.value) || item.value === excludeValue,
    )
  }

  useEffect(() => {
    const convertedData = {
      header: header,
      value: selectedData,
      error: '',
      rules: [],
      isActive: form[index].isActive,
    }
    const tempForm = [...form]
    tempForm[index] = convertedData
    handleChange(tempForm)
  }, [selectedData])

  if (!hiddenColumns.includes(header))
    return (
      <MainContainer>
        <ArrayContainer>
          {selectedData.map((item, i) => (
            <ArrayRow key={`array-input-${i}`}>
              <Select
                id={`input-${store.RouteStore.currentPage}-${header}-${i}`}
                value={item}
                onChange={(e: any) => handleSelectChange(i, e.target.value)}
                activeStyle={active}
                disabled={!active}
              >
                {getOptions(item).map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <DeleteButton
                active={active}
                onClick={() => active && deleteField(i)}
              >
                <img src={ClearFilters} alt='Delete' />
              </DeleteButton>
            </ArrayRow>
          ))}
        </ArrayContainer>
        <Button
          type={'primary'}
          size={'small'}
          onClick={handleAddSelect}
          margin={'16px'}
          active={active}
        >
          {'+ Add new'}
        </Button>
      </MainContainer>
    )
  else return <></>
}
