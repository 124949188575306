import axios from 'axios'

// Create an Axios instance
const axiosInstance = axios.create()

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    if (config.method === 'post' || config.method === 'delete') {
      const pattern = /.+experience\/v1\/(\w+)_view\??/
      if (
        !pattern.test(config.url) &&
        // eslint-disable-next-line no-undef
        config.url.startsWith(process.env.REACT_APP_BASE_URL)
      ) {
        console.log('Logging request...')
        try {
          const parts = config.url.split('/')
          const lastWord = parts[parts.length - 1]
          const secondLastWord = parts[parts.length - 2]
          // eslint-disable-next-line no-undef
          const url = `${process.env.REACT_APP_BASE_URL}experience/v1/audit_new`
          console.log(config.data)
          const data = {
            action: `${config.method}: ${secondLastWord} ${lastWord}`,
            entity: JSON.stringify(config.data),
            date: new Date().toISOString(),
            username: config.headers.username
              ? config.headers.username.toString()
              : 'unknown',
          }
          const reqconfig = {
            headers: {
              // eslint-disable-next-line no-undef
              'x-apikey': `${process.env.REACT_APP_API_KEY}`,
            },
          }
          if (
            // eslint-disable-next-line no-undef
            process.env.REACT_APP_BASE_URL ===
            'https://moodnook.westeurope.cloudapp.azure.com/api/'
          )
            await axios.post(url, data, reqconfig)
          await console.log('Request Logged!')
        } catch (e) {
          console.log(e)
        }
      }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  },
)

// Export the Axios instance
export default axiosInstance
