import Table from '../../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../../../App'

import TableBool from '../../_tableGeneric/TableBool/TableBool.component'
/* eslint-disable */

const StatusesTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
      mandatory: true,
    },
    name: {
      canFilter: false,
      editable: true,
      editType: 'text',
      mandatory: true,
    },
    system: {
      canFilter: false,
      editable: true,
      editType: 'text',
      mandatory: true,
    },
    instance: {
      canFilter: false,
      editable: true,
      editType: 'number',
      canBeNull: false,
      mandatory: true,
    },
    url: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'text',
      mandatory: true,
    },
    domain: {
      canFilter: false,
      editable: true,
      editType: 'text',
      mandatory: true,
    },
    active: {
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: false,
      editable: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
  }

  useEffect(() => {
    store.StatusesStore.fetchSalesData()
    return () => store.StatusesStore.clearData()
  }, [])

  return (
    <Table
      config={{
        tableName: 'salesdata',
        data: store.StatusesStore.salesChannels || [],
        fetchData: async () => await store.StatusesStore.fetchSalesData(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: {
          display: true,
          label: locales.tableLabels.salesData,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/sales_channel`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/sales_channel/create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/sales_channel/remove`,
          assignTag: null,
          custom: [],
        },
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'List of Sales Channels',
          subTitle: 'List of Sales Channels',
        },
        breadcrumb: [],
        loaded: store.StatusesStore.loaded,
      }}
    ></Table>
  )
})
export default StatusesTable
