import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../App'

interface TableDefaultCellInterface {
  cell: any
}

const TableCompleteable = observer(({ cell }: TableDefaultCellInterface) => {
  const store = useContext(StoreContext)
  const column = cell.column.id
  const textValue = cell.getValue()
  const boldTexts = store.FiltersStore.appliedQuery.rules
    .filter((rule) => rule.field === column && rule.operator === 'contains')
    .map((rule) => rule.value.toLowerCase())

  // Helper to highlight all matches
  const getHighlightedText = (text: string, patterns: string[]) => {
    let parts: any[] = [text]
    patterns.forEach((pattern) => {
      const updatedParts: any[] = []
      parts.forEach((part) => {
        if (typeof part === 'string') {
          let index = part.toLowerCase().indexOf(pattern)
          while (index !== -1) {
            // Add text before match
            if (index > 0) updatedParts.push(part.slice(0, index))
            // Add match
            updatedParts.push(
              <b key={Math.random()} style={{ color: '#e4bf71' }}>
                {part.slice(index, index + pattern.length)}
              </b>,
            )
            // Update part to remaining text
            part = part.slice(index + pattern.length)
            index = part.toLowerCase().indexOf(pattern)
          }
          // Add remaining text
          if (part.length > 0) updatedParts.push(part)
        } else {
          updatedParts.push(part)
        }
      })
      parts = updatedParts
    })
    return parts
  }

  return (
    <div>
      <p>{getHighlightedText(textValue, boldTexts)}</p>
    </div>
  )
})

export default TableCompleteable
