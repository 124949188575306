/* eslint-disable */
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Row } from '@tanstack/react-table'

import { hiddenColumns } from '../../columnsConfig'

import { Container, Footer, InnerCont } from './RemoveOrder.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  tableName: string
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  name: string
  url: string
  fetchData: () => Promise<void>
}

const RemoveRenderedProduct: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme

  const handleSave = async () => {
    const id = props.columns.find((e: any) => e.header === 'id')?.value
    store.TableStore.setLoaded(false)
    if (id) {
      const config = {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      }
      if (props.tableName === 'currencies') {
        const data = store.StoreSettingsStore.data.filter(
          (e: any) => e.id !== id,
        )
        console.log(props.url, data, config)
        await axios.post(props.url, data, config)
      } else if (props.tableName === 'predefinedViews') {
        await axios.delete(`${props.url}/${id}`, {
          headers: {
            'x-apikey': `5732b217-1763-469a-a49b-7b8231389aa1`,
          },
        })
      } else {
        await axios.delete(`${props.url}/${id}`, config)
      }
      await store.SlidingPanelState.setVisibility(false)
      await setTimeout(() => store.TableStore.fetchData(), 2000)
      await toast(locales.successes.success, {
        type: 'success',
        autoClose: 5000,
      })
      await props.fetchData()
    }
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont>
        <p>{locales.warnings.wantToRemove}</p>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
          {...Theme.buttons.secondaryButton}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => handleSave()}
          active={true}
          {...Theme.buttons.primaryButton}
        >
          {'CONFIRM'}
        </Button>
      </Footer>
    </Container>
  )
}
export default RemoveRenderedProduct
