import { RootStore } from './Root.store'
import { action, makeAutoObservable } from 'mobx'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'

export class AuditStore {
  rootStore
  data = []
  loaded = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound fetchData = async () => {
    this.loaded = false

    const currentPagination = this.rootStore.PaginationStore.currentPagination
    const pagination = this.rootStore.PaginationStore.pagination[
      currentPagination
    ] || {
      tableSize: 0,
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
    }

    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/audit_view?size=${pagination.pageSize}&from=${
      (pagination.pageIndex - 1) * pagination.pageSize
    }`

    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }

    const res = await axios.post(url, {}, config)
    this.data = res.data.audits
    pagination.tableSize = await res.data.count
    this.loaded = true
  }

  @action.bound clearData = async () => {
    this.data = []
  }
}
