import { RootStore } from './Root.store'
import { action, makeAutoObservable, toJS } from 'mobx'
import { toast } from 'react-toastify'
import { tableConfig } from '../tableConfig'
import axios from '../axiosconfig'
import { socket } from '../socket/socket'
import {
  AllowEmptyStrings,
  FormatIntegers,
  ReplaceEmptyStringsForNulls,
  ReplaceEmptyStringsForZeros,
} from '../columnsConfig'

/* eslint-disable */
export class TableStore {
  rootStore
  data: Array<object> = []
  auditData: Array<object> = []
  detailsData: {
    customer?: { [key: string]: string | number | Date }
    products?: any
    [key: string]: any
  } = {}
  loaded = false
  detailsLoaded = false
  headLine = {
    visible: true,
    title: '',
    subTitle: '',
  }
  pagination: {
    [key: string]: {
      tableSize: number
      pageSize: number
      pageIndex: number
      totalCount: number
    }
  } = {
    default: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    customerorders: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    stock: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    supplierorders: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    packing: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    admin: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    bank: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    reports: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    themeverify: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    filtersview: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    packages: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    returns: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    contacts: { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
    '': { tableSize: 0, pageSize: 10, pageIndex: 1, totalCount: 0 },
  }
  sort: { column?: string; desc?: string; type?: string } = {
    column: undefined,
    desc: 'desc',
    type: undefined,
  }
  configColumnVisibility = false
  configAllowColumnResize = false
  configDisplaySearch = false
  configDisplaySelect = false
  configDisplayExpand = false
  configDisplayTooltip = false
  configDisplayEntry = false
  configDisplayPaginator = false
  configAllowSort = false
  configBankFeatures = false
  mobileOptions = false
  allSelectedRows = false
  selectedExpanded: Array<{ rowId: string; values: object }> = []
  selectedRowId: Array<string> = []
  showSearchField = false
  infinitySwitch = false
  expandedRows = {}
  dataInput = false
  clipTextColumns: string[] = []
  languages: string[] = []
  isResizing = false
  isInfinityLoading = false
  productSpecificationTypes = []
  productTypes = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.setConfig()
    this.initProductTypes()
  }

  getApiRequestFromUrl = () => {
    switch (this.rootStore.RouteStore.currentPage) {
      case 'customerorders':
        return 'customer_orders_view'
      case 'stock':
        return 'stock_view'
      case 'supplierorders':
        return 'supplierorder_view'
      case 'bank':
        return 'transactions_view'
      case 'packing':
        return 'packing_view'
      default:
        return 'customer_orders_view'
    }
  }

  getApiRequestFromTableName = (tableName: string) => {
    switch (tableName) {
      case 'customerorders':
        return 'customer_orders_view'
      case 'stock':
        return 'stock_view'
      case 'supplierorders':
        return 'supplierorder_view'
      case 'categories':
        return 'categories_view'
      case 'bank':
        return 'transactions_view'
      case 'packing':
        return 'packing_view'
      case 'audit':
        return 'audit_view'
      case 'tags':
        return 'tags_view'
      case 'roottags':
        return 'roottags_view'
      case 'grouptags':
        return 'grouptags_view'
      default:
        return null
    }
  }

  getResponseParam = () => {
    switch (this.rootStore.RouteStore.currentPage) {
      case 'customerorders':
        return 'orders'
      case 'stock':
        return 'products'
      case 'supplierorders':
        return 'orders'
      case 'bank':
        return 'transactions'
      case 'packing':
        return 'orders'
      default:
        return 'orders'
    }
  }

  updateField(obj: any) {
    let newobj = JSON.parse(JSON.stringify(obj))
    if (newobj && newobj.rules && Array.isArray(newobj.rules)) {
      newobj.rules = newobj.rules.map((rule: any) => {
        if (
          ['creationDate', 'ordered', 'addDate', 'availabilityDate'].includes(
            rule.field,
          )
        ) {
          rule.field = rule.field + '.Raw'
        }
        if (rule.field === 'object_tags') rule.field = 'tag_ids'
        if (rule.field === 'product_tags') {
          rule.field = 'product_tags.tags'
          rule.operator = 'contains'
        }
        return rule
      })
    }
    //console.log(newobj)
    return newobj
  }

  @action.bound getData = () => {
    let data: any = {
      filterGroups: this.updateField(this.rootStore.FiltersStore.appliedQuery),
    }
    if (this.rootStore.FiltersStore.appliedGlobalSearch.length > 2) {
      data = {
        searchAll: `*${this.rootStore.FiltersStore.appliedGlobalSearch}*`,
        ...data,
      }
    }

    if (this.sort.column) {
      data = {
        sort: [
          {
            [this.sort.column]: {
              order: this.sort.desc,
            },
          },
        ],
        ...data,
      }
    }
    //console.log(toJS(data))
    return data
  }

  @action.bound fetchData = async (): Promise<void> => {
    const currentPagination = this.rootStore.PaginationStore.currentPagination
    const pagination = this.rootStore.PaginationStore.pagination[
      currentPagination
    ] || {
      tableSize: 0,
      pageSize: 10,
      pageIndex: 1,
      totalCount: 0,
    }
    const requestRoute = this.rootStore.RouteStore.currentPage

    try {
      if (this.rootStore.RouteStore.currentDataId === '') {
        this.loaded = false
      }

      if (!this.isInfinityLoading) this.data = []

      const url = `${
        process.env.REACT_APP_BASE_URL
      }experience/v1/${this.getApiRequestFromUrl()}?size=${
        pagination.pageSize
      }&from=${(pagination.pageIndex - 1) * pagination.pageSize}`
      const data = this.getData()
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
          'Access-Control-Allow-Credentials': 'true',
        },
      })

      if (
        response.data.count <
        (pagination.pageIndex - 1) * pagination.pageSize
      ) {
        pagination.pageIndex = 1
        this.fetchData()
      }
      //prevent page change during waiting for request
      if (requestRoute === this.rootStore.RouteStore.currentPage) {
        this.data = await response.data[`${this.getResponseParam()}`]
      }
      this.rootStore.PaginationStore.setPagination(
        currentPagination,
        response.data.count,
        pagination.pageSize,
        pagination.pageIndex,
        pagination.totalCount,
      )

      this.rootStore.FiltersStore.applyQuery()
      this.loaded = await true
    } catch (e: any) {
      this.data = []
      toast(e.message.toString(), { type: 'error' })
      console.log(e)
    }
  }

  @action.bound setLoaded = (loaded: boolean) => {
    this.loaded = loaded
  }

  @action.bound fetchDetailsData = async (id?: string, noLoading?: boolean) => {
    if (!noLoading) {
      this.detailsLoaded = false
      this.loaded = false
    }
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${id}`
    const res = await axios.get(url, {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
    this.detailsData = res.data
    this.detailsLoaded = true
  }

  @action.bound clearDetailsData = async () => {
    this.detailsData = []
    this.detailsLoaded = false
  }

  @action.bound fetchSupplierData = async (
    id?: string,
    noLoading?: boolean,
  ) => {
    this.loaded = false
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order/${id}`
    const res = await axios.get(url, {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
    this.detailsData = res.data
    this.loaded = true
  }

  @action.bound initProductTypes = async () => {
    const productTypesUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/product_types_view?from=0&size=500`
    const productSpecificationTypesUrl = `${process.env.REACT_APP_BASE_URL}experience/v1/product_specification_types_view?from=0&size=500`
    const data = {}
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }

    const productTypes = await axios.post(productTypesUrl, data, config)
    const productSpecificationTypes = await axios.post(
      productSpecificationTypesUrl,
      data,
      config,
    )

    this.productTypes = productTypes.data.product_types.map((e: any) => ({
      value: e.name,
      label: e.name,
    }))

    this.productSpecificationTypes =
      productSpecificationTypes.data.product_specification_types.map(
        (e: any) => ({
          value: e.name,
          label: e.name,
        }),
      )
  }

  @action.bound setCheckListItems = (checkListItems: object) => {
    this.detailsData.checkListItems = checkListItems
  }

  getUpadateParam = () => {
    switch (this.rootStore.RouteStore.currentPage) {
      case 'customerorders':
        return 'order'
      case 'stock':
        return 'product'
      case 'supplierorders':
        return 'supplier_order'
      default:
        return 'order'
    }
  }
  @action.bound updateData = async (data: object | any) => {
    const id = data['id']
    const url = `${
      process.env.REACT_APP_BASE_URL
    }experience/v1/${this.getUpadateParam()}/${id}`
    //console.log(url)
    await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
  }

  @action.bound setData = (data: Array<object>) => {
    this.data = data
  }

  @action.bound setPaginationToDefault = () => {
    Object.keys(this.pagination).forEach((key) => {
      this.pagination[key] = {
        tableSize: 10,
        pageSize: 10,
        pageIndex: 1,
        totalCount: 0,
      }
    })
  }

  @action.bound setSort = (
    column: string | undefined,
    type: string | undefined,
  ) => {
    const dateArray = [
      'ordered',
      'creationDate',
      'lastEditDate',
      'estimateDate',
      'availabilityDate',
    ]
    const numArray = [
      'id',
      'paid',
      'order',
      'code',
      'stock',
      'minimumWarnLevel',
      'secureWarnLevel',
      'maximumWarnLevel',
      'quantityToOrder',
      'status',
    ]
    if (column && dateArray.includes(column)) this.sort.type = 'date'
    else if (column && numArray.includes(column)) this.sort.type = 'long'
    this.sort.column = column
    this.sort.desc = type

    localStorage.setItem(
      `${this.rootStore.RouteStore.currentPage}-sort`,
      JSON.stringify({ column: column, desc: type }),
    )
  }

  @action.bound setDefaultSort = async () => {
    const storageSort = JSON.parse(
      localStorage.getItem(`${this.rootStore.RouteStore.currentPage}-sort`)!,
    )
    if (storageSort) this.setSort(storageSort.column, storageSort.desc)
    else
      switch (this.rootStore.RouteStore.currentPage) {
        case 'customerorders':
          this.setSort('ordered', 'desc')
          break
        case 'stock':
          this.setSort('addDate', 'desc')
          break
        case 'supplierorders':
          this.setSort('creationDate', 'desc')
          break
        default:
          this.setSort(undefined, undefined)
          break
      }
  }

  @action.bound setSelectAll = async () => {
    if (
      this.pagination[this.rootStore.RouteStore.currentPage].tableSize > 10000
    ) {
      toast(
        'Current table size is bigger than maximum request size (10000). Please filter data first!',
        { type: 'error' },
      )
      return
    }
    if (
      this.selectedRowId.length !==
      this.pagination[this.rootStore.RouteStore.currentPage].tableSize
    ) {
      this.loaded = false
      const url = `${
        process.env.REACT_APP_BASE_URL
      }experience/v1/${this.getApiRequestFromUrl()}?size=${
        this.pagination[this.rootStore.RouteStore.currentPage].tableSize
      }&from=0`
      const response = await axios.post(
        url,
        {
          filterGroups: this.rootStore.FiltersStore.query,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-apikey': `${process.env.REACT_APP_API_KEY}`,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
            'Access-Control-Allow-Credentials': 'true',
          },
        },
      )
      const preFilteredData = await response.data[`${this.getResponseParam()}`]
      const selectedRowId = [...preFilteredData.map((e: any) => e.id)]
      this.selectedRowId = [...new Set(selectedRowId)]

      if (this.selectedRowId.length > 0) this.allSelectedRows = true
      this.loaded = true
    } else {
      this.allSelectedRows = false
      this.selectedRowId = []
      this.fetchData()
    }
  }

  @action.bound setSelectedExpanded = (
    selectedExpanded: Array<{ rowId: string; values: object }>,
  ) => {
    this.selectedExpanded = selectedExpanded
  }

  @action.bound setConfig = () => {
    const currentPage =
      this.rootStore.RouteStore.currentPage || 'customerorders'
    if (tableConfig[currentPage]) {
      this.configColumnVisibility = tableConfig[currentPage].ColumnVisibility
      this.configAllowColumnResize = tableConfig[currentPage].AllowColumnResize
      this.configDisplaySearch = tableConfig[currentPage].DisplaySearch
      this.configDisplaySelect = tableConfig[currentPage].DisplaySelect
      this.configDisplayExpand = tableConfig[currentPage].DisplayExpand
      this.configDisplayTooltip = tableConfig[currentPage].DisplayTooltip
      this.configDisplayEntry = tableConfig[currentPage].DisplayEntry
      this.configAllowSort = tableConfig[currentPage].AllowSort
      this.configBankFeatures = tableConfig[currentPage].BankFeatures
      this.configDisplayPaginator = tableConfig[currentPage].DisplayPaginator
      this.headLine = tableConfig[currentPage].headLine
    }
  }

  @action.bound setMobileOptions = (mobileOptions: boolean) => {
    this.mobileOptions = mobileOptions
  }

  @action.bound setAllSelectedRows = (allSelectedRows: boolean) => {
    this.allSelectedRows = allSelectedRows
  }

  @action.bound setSelectedRows = (selectedRows: Array<string>) => {
    this.selectedRowId = selectedRows
  }

  @action.bound setShowSearchField = (showSearchField: boolean) => {
    this.showSearchField = showSearchField
  }

  @action.bound setInfinitySwitch = (infinitySwitch: boolean) => {
    this.infinitySwitch = infinitySwitch
  }

  @action.bound exportBankTransaction = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/transactions_update`
    const data = {
      idList: this.selectedRowId,
      user: this.rootStore.UserStore.user,
    }
    const response: any = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE',
        'Access-Control-Allow-Credentials': 'true',
      },
    })
    return response.data.blob_url
  }

  @action.bound toggleDataInput = () => {
    this.dataInput = !this.dataInput
  }

  @action.bound clearClipTextColumns = () => {
    this.clipTextColumns = []
  }

  @action.bound changeClipColumn = (column: string, type: 'wrap' | 'clip') => {
    if (type === 'wrap') {
      this.clipTextColumns = this.clipTextColumns.filter(
        (e: string) => e !== column,
      )
    } else {
      this.clipTextColumns.push(column)
    }
  }

  @action.bound setExpandedRows = (expandedRows: object) => {
    this.expandedRows = expandedRows
  }

  @action.bound setAuditData = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/audit_view?size=100&from=0`
    const res = await axios.post(
      url,
      {},
      {
        headers: {
          'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        },
      },
    )
    this.auditData = res.data.audits
  }

  @action.bound formatRequestData = (data: any) => {
    let formattedData = {}

    Object.entries(data).forEach(([key, value]: any[]) => {
      if (ReplaceEmptyStringsForNulls.includes(key) && value === '') {
        formattedData = { ...formattedData, [key]: null }
      } else if (
        (key === 'stockAmountInPackageBool' ||
          (key === 'active' &&
            this.rootStore.RouteStore.currentPage != 'stock') ||
          key === 'purchasable' ||
          key === 'active_positioning' ||
          key === 'auto_exchange' ||
          key === 'equal',
        key === 'private')
      ) {
        formattedData = { ...formattedData, [key]: value !== 'false' }
      } else if (FormatIntegers.includes(key)) {
        formattedData = { ...formattedData, [key]: parseInt(value) }
      } else if (key === 'products') {
        let valueData = {}
        Object.entries(value).forEach(([productKey, productValue]: any[]) => {
          let productData = {}
          Object.entries(productValue).forEach(
            ([singleKey, singleValue]: any[]) => {
              //console.log(singleKey, singleValue)
              if (
                ReplaceEmptyStringsForNulls.includes(singleKey) &&
                singleValue === ''
              ) {
                productData = { ...productData, [singleKey]: null }
              } else if (
                ReplaceEmptyStringsForZeros.includes(singleKey) &&
                singleValue === ''
              ) {
                productData = { ...productData, [singleKey]: 0 }
              } else if (
                AllowEmptyStrings.includes(singleKey) &&
                singleValue === ''
              ) {
                productData = { ...productData, [singleKey]: '' }
              } else if (singleValue !== '') {
                productData = { ...productData, [singleKey]: singleValue }
              }
            },
          )
          valueData = { ...valueData, [productKey]: productData }
        })
        formattedData = { ...formattedData, [key]: valueData }
      } else formattedData = { ...formattedData, [key]: value }
    })
    console.log(formattedData)
    return formattedData
  }

  @action.bound setLanguages = (languages: string[]) => {
    this.languages = languages
  }

  @action.bound setIsResizing = (isResizing: boolean) => {
    this.isResizing = isResizing
  }

  @action.bound getTaskList = () => {
    const locales = this.rootStore.TranslationsState.translations
    return [
      { value: 'controlInvoice', label: locales.steps.controlInvoice },
      { value: 'printListProducts', label: locales.steps.printListProducts },
      { value: 'pickProducts', label: locales.steps.pickProducts },
      { value: 'countProducts', label: locales.steps.countProducts },
      {
        value: 'checkQualityProducts',
        label: locales.steps.checkQualityProducts,
      },
      { value: 'packProducts', label: locales.steps.packProducts },
      { value: 'packLeeflets', label: locales.steps.packLeeflets },
      { value: 'issueSalesInvoice', label: locales.steps.issueSalesInvoice },
      {
        value: 'sendSalesInvoiceEmail',
        label: locales.steps.sendSalesInvoiceEmail,
      },
      {
        value: 'controlTagsStatusesOrderDetailsOnInvoice',
        label: locales.steps.controlTagsStatusesOrderDetailsOnInvoice,
      },
      { value: 'generateShipping', label: locales.steps.generateShipping },
      {
        value: 'stickShippingLabelOnPackageAndSend',
        label: locales.steps.stickShippingLabelOnPackageAndSend,
      },
    ]
  }

  @action.bound setIsInfinityLoading = (isInfinityLoading: boolean) => {
    this.isInfinityLoading = isInfinityLoading
  }
}
