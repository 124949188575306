import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import { useParams } from 'react-router-dom'

import {
  Container,
  OuterCont,
  SettingsList,
  StyledLink,
  StyledMenuItem,
} from './reports.styles'

import { IconChevronDown } from '@tabler/icons-react'
import Menu, { Item as MenuItem } from 'rc-menu'
import Calendar from '../../components/reports/calendarComponents/calendar/Calendar.component'
import Timeline from '../../components/reports/timeline/Timeline.component'

const Reports = observer(() => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const params = useParams()

  const getComponentFromUrl = () => {
    switch (params.page) {
      case 'interface':
        return (
          <iframe
            src='https://elasticsearch-1f14fc.kb.westeurope.azure.elastic-cloud.com:9243/app/dashboards#/view/16161b20-1c46-11ee-82a9-29babb1cf086?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:now-15y,to:now))&_a=()'
            height='600'
            width='800'
          />
        )
      case 'orderreport':
        return (
          <iframe src='https://moodnook.westeurope.cloudapp.azure.com:8080/s/moodnook-dashboards/app/dashboards#/view/4cb90c81-a283-4b22-9acf-2d90f9c288c3?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-1s%2Cto%3Anow))'></iframe>
        )
      case 'calendar':
        return <Calendar />
      case 'timeline':
        return <Timeline />
      default:
        return <>{params.page}</>
    }
  }

  useEffect(() => {
    //store.CalendarStore.loadData()
    return () => {
      store.CalendarStore.clearData()
    }
  }, [])

  return (
    <OuterCont>
      <SettingsList>
        <Menu
          mode='inline'
          style={{ margin: 0, width: '100%' }}
          expandIcon={<IconChevronDown size='18px' />}
        >
          <StyledMenuItem key='interface'>
            <StyledLink to={'/reports/interface'}>
              Order Fullfilment Dashboard
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem key='orderreport'>
            <StyledLink to={'/reports/orderreport'}>
              Inventory planning dashboard
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem key='warehouse'>
            <StyledLink to={'/reports/warehouse'}>
              Warehouse management report
            </StyledLink>
          </StyledMenuItem>
          <MenuItem key='calendar'>
            <StyledLink to={'/reports/calendar'}>Calendar</StyledLink>
          </MenuItem>
          <MenuItem key='timeline'>
            <StyledLink to={'/reports/timeline'}>Product Timeline</StyledLink>
          </MenuItem>
        </Menu>
      </SettingsList>
      <Container {...Theme.SettingsPage.container}>
        {getComponentFromUrl()}
      </Container>
    </OuterCont>
  )
})
export default Reports
