/* eslint-disable */
import React, {
  ChangeEvent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  Container,
  Footer,
  InnerCont,
  TagCont,
} from './AssignTagGeneric.styles'

import { StoreContext } from '../../App'

import axios from '../../axiosconfig'
import { toast } from 'react-toastify'
import {
  SingleTag,
  SingleTagCross,
} from '../_tableGeneric/tableNewTags/TableNewTags.styles'
import Cross from '../../assets/icons/cross-white.svg'
import SearchTagNewComponent from '../_settings/tags/edits/searchTagNew/SearchTagNew.component'
import { FilterLabel } from '../_settings/tags/edits/searchTagNew/SearchTagNew.styles'
import Button from '../button/Button.component'

export interface EditProductsProps extends PropsWithChildren {
  id: string
  row: any
  columns: Array<{ header: string; value: string }>
  toggleClick: () => void
  url: string
  tableName: string
  fetchData: () => Promise<void>
}

const AssignTagGeneric: (props: EditProductsProps) => JSX.Element = (
  props: EditProductsProps,
) => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const { Theme } = store.Theme
  const [isAbleToSave, setIsAbleToSave] = useState(false)

  const [currentTagList, setCurrentTagList] = useState<Array<any>>([])
  const [initialTagList, setInitialTagList] = useState<Array<any>>([])
  const applySearch = (tag: any) => {
    const addedTag = [...currentTagList, tag]
    const unique = [
      ...new Map(addedTag.map((item) => [item.id, item])).values(),
    ]
    setCurrentTagList(unique)
    setIsAbleToSave(true)
  }

  const applyClearTag = (tag: any) => {
    setCurrentTagList([...currentTagList.filter((e) => e.id !== tag.id)])
    setIsAbleToSave(true)
  }

  const getUpdateId = () => {
    switch (props.tableName) {
      case 'stock':
      case 'customerorders':
      default:
        return 'id'
    }
  }

  const handleSave = async () => {
    let id = props.columns.find((e: any) => e.header === getUpdateId())?.value

    const data = {
      tags: [...currentTagList.map((e) => e.id)],
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: store.UserStore.user.length
          ? store.UserStore.user
          : 'No data',
      },
    }

    if (props.tableName === 'collectiontags') {
      const finalTags = data.tags
      const initialTags = [...initialTagList.map((e) => e.id)]
      const addedTags = finalTags.filter((tag) => !initialTags.includes(tag))
      const removedTags = initialTags.filter((tag) => !finalTags.includes(tag))

      await axios.post(
        `${props.url}/${id}?operator=assign`,
        { tags: addedTags },
        config,
      )
      await axios.post(
        `${props.url}/${id}?operator=unassign`,
        { tags: removedTags },
        config,
      )
    } else await axios.post(`${props.url}/${id}`, data, config)

    await toast(locales.successes.success, { type: 'success' })
    await store.SlidingPanelState.setVisibility(false)
    await props.fetchData()
  }

  const getTagsId = () => {
    switch (props.tableName) {
      case 'collectiontags':
        return 'tags'
      default:
        return 'object_tags'
    }
  }

  const getFirstLevel = () => {
    switch (props.tableName) {
      case 'collectiontags':
        return true
      default:
        return false
    }
  }

  useEffect(() => {
    const tags = props.row
      .getAllCells()
      .find((e: any) => e.column.id === getTagsId())
      ?.getValue()
    if (tags && tags.length > 0) {
      let tempTags: any = []
      if (getFirstLevel()) tempTags = [...tempTags, ...tags]
      tags.map((e: any) => {
        if (e.tags) tempTags = [...tempTags, ...e.tags]
      })
      setInitialTagList(tempTags)
      setCurrentTagList(tempTags)
    }
  }, [])

  return (
    <Container {...Theme.editProductsStyles.container}>
      <InnerCont cols={1}>
        <SearchTagNewComponent
          active={true}
          view={'tag'}
          header={locales.headers.addNewTag}
          applySearch={applySearch}
          clearInputValue={true}
        />
        <FilterLabel {...Theme.editProductsStyles.filterLabel}>
          {locales.headers.currentTags}
        </FilterLabel>
        <TagCont>
          {currentTagList.map((e: any, index) => {
            if (e)
              return (
                <SingleTag
                  key={`new-tag-${e}`}
                  size={'12px'}
                  style={{ whiteSpace: 'nowrap' }}
                  color={e.hasOwnProperty('colour') ? e.colour : '#505050'}
                >
                  {e.name.EN}
                  <SingleTagCross onClick={() => applyClearTag(e)}>
                    <img src={Cross} />
                  </SingleTagCross>
                </SingleTag>
              )
          })}
        </TagCont>
      </InnerCont>
      <Footer {...Theme.editProductsStyles.styledFooter}>
        <Button
          type={'secondary'}
          onClick={() => store.SlidingPanelState.setVisibility(false)}
        >
          {locales.buttons.cancel}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            if (isAbleToSave) handleSave()
          }}
          active={isAbleToSave}
        >
          {locales.buttons.save}
        </Button>
      </Footer>
    </Container>
  )
}
export default AssignTagGeneric
