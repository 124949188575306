import Table from '../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../App'
import TableDate from '../_tableGeneric/tableDate/TableDate.component'
import TableStatus from '../_tableGeneric/tableStatus/TableStatus.component'
import TableNewTagsComponent from '../_tableGeneric/tableNewTags/TableNewTags.component'

/* eslint-disable */

interface SuppliersTableProps {
  mockData?: boolean
}

const SuppliersTable = observer(({ mockData }: SuppliersTableProps) => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  const columns = {
    id: {
      Header: locales.supplierordersColumns.id,
      canFilter: true,
      editable: false,
      Cell: (cell: any) => (
        <a href={`/supplierorders/${cell.getValue()}`}>{cell.getValue()}</a>
      ),
    },
    supplierOrderId: {
      Header: locales.supplierordersColumns.supplierOrderId,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    supplier: {
      Header: locales.supplierordersColumns.supplier,
      canFilter: true,
      editable: true,
      editType: 'select',
      selectValues: store.StatusesStore.producers.map((supplier) => ({
        value: supplier,
        label: supplier,
      })),
    },
    creationDate: {
      Header: locales.supplierordersColumns.creationDate,
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: true,
      editable: false,
      editType: 'datetime-local',
    },
    author: {
      Header: locales.supplierordersColumns.author,
      canFilter: true,
      editable: false,
    },
    price_type: {
      Header: locales.supplierordersColumns.price_type,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    currency: {
      Header: locales.supplierordersColumns.currency,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    wfirma_vat_id: {
      Header: locales.supplierordersColumns.wfirma_vat_id,
      canFilter: true,
      editable: false,
    },
    wfirma_id: {
      Header: locales.supplierordersColumns.wfirma_id,
      canFilter: true,
      editable: false,
    },
    vat: {
      Header: locales.supplierordersColumns.vat,
      canFilter: true,
      editable: false,
    },
    object_tags: {
      Header: locales.customerordersColumns.tags,
      Cell: (cell: any) => (
        <TableNewTagsComponent key={cell.getValue()} tags={cell.getValue()} />
      ),
      canFilter: true,
      canSort: false,
      editable: false,
      editType: 'select',
      selectValues: store.TagsStore.getIndicesTags().map((tag: any) => ({
        value: tag.id,
        label: tag.name.EN,
      })),
    },
    lastEditDate: {
      Header: locales.supplierordersColumns.lastEditDate,
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: true,
      editable: false,
      editType: 'datetime-local',
    },
    lastEditAuthor: {
      Header: locales.supplierordersColumns.lastEditAuthor,
      canFilter: true,
      editable: false,
    },
    estimateDate: {
      Header: locales.supplierordersColumns.estimateDate,
      canFilter: true,
      editable: true,
      editType: 'date',
    },
    deliveryCode: {
      Header: locales.supplierordersColumns.deliveryCode,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    orderLink: {
      Header: locales.supplierordersColumns.orderLink,
      canFilter: true,
      canSort: false,
      editable: true,
      editType: 'text',
    },
    notes: {
      Header: locales.supplierordersColumns.notes,
      canFilter: true,
      canSort: false,
      editable: true,
      editType: 'text',
    },
    product_tags: {
      canFilter: true,
      canSort: false,
      editable: false,
      editType: 'select',
      selectValues: store.TagsStore.getIndicesTags().map((tag: any) => ({
        value: tag.id,
        label: tag.name.EN,
      })),
      Cell: () => <div></div>,
    },
    status: {
      Header: locales.supplierordersColumns.status,
      Cell: (cell: any) => <TableStatus status={cell.getValue()} />,
      canFilter: true,
      canSort: false,
      editable: true,
      editType: 'select',
      selectValues: Object.entries({
        '0': 'Cancelled',
        '1': 'Exported to excel',
        '2': 'Sent to supplier',
        '3': 'In Progress',
        '4': 'Finished',
        '5': 'In Progress (delay)',
        '6': 'In Progress (reclamation)',
      }).map(([value, label]) => ({
        value,
        label,
      })),
    },
  }

  if (mockData) {
    return (
      <Table
        config={{
          tableName: 'supplierorders',
          data: store.MockStore.suppliersData || [],
          fetchData: async () => await store.TableStore.fetchData(),
          columnHeaders: columns,
          displayColumnVisibility: true,
          allowColumnResize: true,
          displaySearch: true,
          displaySelect: true,
          selectAllFunction: () => store.TableStore.setSelectAll(),
          displayExpand: true,
          displayTooltip: false,
          displayEntry: false,
          displayPaginator: false,
          displayOrdinalList: true,
          displayInfinitySwitch: false,
          allowSort: true,
          bank: false,
          actions: null,
          actionsMultiple: null,
          views: true,
          headline: {
            visible: true,
            title: 'Supplier Orders',
            subTitle: 'Mock List of supplier Orders',
          },
          breadcrumb: ['Panel', 'Supplier Orders'],
          loaded: store.MockStore.loaded,
          setLoaded: (loaded) => store.TableStore.setLoaded(loaded),
        }}
      ></Table>
    )
  }
  return (
    <Table
      config={{
        tableName: 'supplierorders',
        data: store.TableStore.data || [],
        fetchData: async () => await store.TableStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: tableStore.configColumnVisibility,
        allowColumnResize: tableStore.configAllowColumnResize,
        displaySearch: tableStore.configDisplaySearch,
        displaySelect: tableStore.configDisplaySelect,
        selectAllFunction: () => store.TableStore.setSelectAll(),
        displayExpand: tableStore.configDisplayExpand,
        displayTooltip: tableStore.configDisplayTooltip,
        displayEntry: tableStore.configDisplayEntry,
        displayPaginator: tableStore.configDisplayPaginator,
        displayOrdinalList: true,
        displayInfinitySwitch: true,
        allowSort: tableStore.configAllowSort,
        bank: tableStore.configBankFeatures,
        views: true,
        actions: {
          display: true,
          label: locales.tableLabels.supplierorders,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order`,
          create: null,
          remove: null,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          custom: [
            'assignCollection',
            'addSupplierProduct',
            'orderLineAllTags',
          ],
        },
        actionsMultiple: {
          display: true,
          label: locales.tableLabelsMultiple.supplierorders,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/supplier_order`,
          create: null,
          remove: null,
          assignTag: true,
          custom: [
            'assignCollection',
            'copyToClipboard',
            'downloadFile',
            'addProducts',
            'editOrderline',
            'assignOrderLineTags',
          ],
        },
        headline: {
          visible: store.Theme.Navigation === 'side',
          title: 'Supplier Orders',
          subTitle: 'List of supplier Orders',
        },
        breadcrumb: ['Panel', 'Supplier Orders'],
        loaded: store.TableStore.loaded,
        setLoaded: (loaded) => store.TableStore.setLoaded(loaded),
      }}
    ></Table>
  )
})
export default SuppliersTable
