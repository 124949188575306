import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import { Container } from './TableArrayDisplay.styles'

interface TableArrayDisplayProps {
  data: string[]
}

const TableArrayDisplay: FC<TableArrayDisplayProps> = observer(({ data }) => {
  return (
    <Container>
      {data.length ? (
        data.map((value, index) => (
          <div key={index}>
            {value}
            {index < data.length - 1 && ', '}
          </div>
        ))
      ) : (
        <div>{'None'}</div>
      )}
    </Container>
  )
})

export default TableArrayDisplay
