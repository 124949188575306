import Table from '../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../App'
import TableImage from '../_tableGeneric/tableImage/TableImage.component'
import TableTags from '../_tableGeneric/tableTags/TableTags.component'
import TableDate from '../_tableGeneric/tableDate/TableDate.component'
import TableStockInProgress from '../_tableGeneric/tableStockInProgress/TableStockInProgress.component'
import TableBool from '../_tableGeneric/TableBool/TableBool.component'
import TableNewTagsComponent from '../_tableGeneric/tableNewTags/TableNewTags.component'
import TableProducerColumnComponent from '../_tableGeneric/tableProducerColumn/TableProducerColumn.component'
import TableCategories from '../_tableGeneric/tableCategories/TableCategories.component'
/* eslint-disable */

const StockTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      Header: locales.stockColumns.id,
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    code: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    name: {
      Header: locales.stockColumns.name,
      canFilter: true,
      editable: true,
      editType: 'text',
      //regex: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$\n',
    },
    ean: {
      Header: locales.stockColumns.ean,
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    producer: {
      Header: locales.stockColumns.producer,
      canFilter: true,
      editable: true,
      editType: 'select',
      selectValues: store.StatusesStore.producers.map((supplier) => ({
        value: supplier.toString(),
        label: supplier.toString(),
      })),
    },
    avaliabilityByProducerID: {
      Header: 'Availability By Producer',
      Cell: (cell: any) => (
        <TableProducerColumnComponent
          data={cell.getValue()}
          searchData={store.StatusesStore.avaliabilityByProducer}
        />
      ),
      canFilter: true,
      canSort: false,
      editable: true,
      editType: 'select',
      selectValues: store.StatusesStore.avaliabilityByProducer.map(
        (e: any) => ({
          value: e.id.toString(),
          label: e.name.en_GB,
        }),
      ),
    },
    shippingTimeStatusID: {
      Header: 'shippingTimeStatus',
      Cell: (cell: any) => (
        <TableProducerColumnComponent
          data={cell.getValue()}
          searchData={store.StatusesStore.shippingTimeStatus}
        />
      ),
      canFilter: true,
      canSort: false,
      editable: false,
      editType: 'select',
      selectValues: store.StatusesStore.shippingTimeStatus.map((e: any) => ({
        value: e.id.toString(),
        label: e.name.en_GB,
      })),
    },
    avaliabilityStatusID: {
      Header: 'avalibilityStatus',
      Cell: (cell: any) => (
        <TableProducerColumnComponent
          data={cell.getValue()}
          searchData={store.StatusesStore.avaliabilityStatus}
        />
      ),
      canFilter: true,
      canSort: false,
      editable: false,
      editType: 'select',
      selectValues: store.StatusesStore.avaliabilityStatus.map((e: any) => ({
        value: e.id.toString(),
        label: e.name.en_GB,
      })),
    },
    addDate: {
      Header: locales.stockColumns.addDate,
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: true,
      editable: false,
      editType: 'datetime-local',
    },
    active: {
      Cell: (cell: any) => (
        <div>
          {cell.getValue() !== null
            ? cell.getValue() == true ||
              cell.getValue() === 1 ||
              cell.getValue() === '1'
              ? 'True'
              : 'False'
            : 'null'}
        </div>
      ),
      Header: locales.stockColumns.active,
      canFilter: true,
      editable: true,
      editType: 'select',
      selectValues: [
        { value: '0', label: 'False' },
        { value: '1', label: 'True' },
      ],
    },
    stock: {
      Header: locales.stockColumns.stock,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: false,
    },
    stockDemand: {
      Header: locales.stockColumns.stockDemand,
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: false,
    },
    stockInvoices: {
      Header: locales.stockColumns.stockInvoices,
      Cell: (cell: any) => (
        <div>
          {cell.getValue() &&
            cell.getValue().map((f: any, i: number) => {
              return <TableStockInProgress data={f} key={i} secondary={true} />
            })}
        </div>
      ),
      canFilter: false,
      canSort: false,
      editable: false,
    },
    stockInDelivery: {
      Header: 'stockInDelivery',
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: true,
    },
    stockDelivered: {
      Header: 'stockDelivered',
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: true,
    },
    stockOrdered: {
      Header: 'stockOrdered',
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: true,
    },
    eanOuterBox: {
      Header: 'eanOuterBox',
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    stockAmountInOuterBox: {
      Header: 'stockAmountInOuterBox',
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    stockAfterPacking: {
      Header: locales.stockColumns.stockAfterPacking,
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: false,
    },
    stockLastInvoice: {
      Header: locales.stockColumns.stockLastInvoice,
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: false,
    },
    stockLastInvoiceDate: {
      Header: locales.stockColumns.stockLastInvoiceDate,
      canFilter: true,
      editable: false,
      editType: 'date',
    },
    stockCalculated: {
      Header: locales.stockColumns.stockCalculated,
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: false,
    },
    sellPrice: {
      Header: locales.stockColumns.sellPrice,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    stockInProgress: {
      Header: locales.stockColumns.stockInProgress,
      Cell: (cell: any) => (
        <div>
          {cell.getValue() &&
            cell.getValue().map((f: any, i: number) => {
              return <TableStockInProgress data={f} key={i} />
            })}
        </div>
      ),
      canFilter: false,
      canSort: false,
      editable: false,
    },
    stockAfterOrders: {
      Header: locales.stockColumns.stockAfterOrders,
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: false,
    },
    stockAmountInPackage: {
      Header: locales.stockColumns.stockAmountInPackage,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: false,
      rules: ['positive'],
    },
    stockAmountInPackageBool: {
      Header: locales.stockColumns.stockAmountInPackageBool,
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      canFilter: true,
      editable: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
    },
    minimumWarnLevel: {
      Header: locales.stockColumns.minimumWarnLevel,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    secureWarnLevel: {
      Header: locales.stockColumns.secureWarnLevel,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    maximumWarnLevel: {
      Header: locales.stockColumns.maximumWarnLevel,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    quantityToOrder: {
      Header: locales.stockColumns.quantityToOrder,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    minimumQuantityForSupplierOrder: {
      Header: locales.stockColumns.minimumQuantityForSupplierOrder,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: true,
    },
    stockMOQAfterChecking: {
      Header: 'MOQAfterChecking',
      canFilter: true,
      editable: false,
      editType: 'number',
      canBeNull: false,
    },
    availabilityDate: {
      Header: locales.stockColumns.availabilityDate,
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canFilter: true,
      editable: true,
      editType: 'date',
    },
    salesPriceBrutto: {
      Header: locales.stockColumns.salesPriceBrutto,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: false,
    },
    salesPriceNetto: {
      Header: locales.stockColumns.salesPriceNetto,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: false,
    },
    defaultSupplierPrice: {
      Header: locales.stockColumns.defaultSupplierPrice,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: false,
    },
    defaultSupplierPriceCurrency: {
      Header: locales.stockColumns.defaultSupplierPriceCurrency,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    salesTime: {
      Header: locales.stockColumns.salesTime,
      canFilter: true,
      editable: false,
      editType: 'date',
    },
    wfirma_id: {
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    wfirma_url: {
      Cell: (cell: any) => (
        <a href={cell.getValue()} target='_blank' rel='noreferrer'>
          {cell.getValue()}
        </a>
      ),
      canFilter: true,
      canSort: false,
      editable: true,
      editType: 'text',
    },
    productType: {
      canFilter: true,
      canSort: false,
      editable: true,
      canBeNull: true,
      editType: 'select',
      selectValues: store.TableStore.productTypes,
    },
    productSpecificationType: {
      canFilter: true,
      canSort: false,
      editable: true,
      canBeNull: true,
      editType: 'select',
      selectValues: store.TableStore.productSpecificationTypes,
    },
    permalink: {
      Header: locales.stockColumns.permalink,
      canFilter: true,
      canSort: false,
      editable: false,
      editType: 'text',
    },
    image: {
      Header: locales.stockColumns.image,
      Cell: (cell: any) => <TableImage url={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: false,
    },
  }

  if (store.UserStore.features.includes('tagmgm')) {
    columns = {
      ...columns,
      tags: {
        Header: locales.customerordersColumns.tags,
        Cell: (cell: any) => (
          <TableTags key={cell.getValue()} tags={cell.getValue()} />
        ),
        canFilter: false,
        canSort: false,
        editable: false,
      },
      object_tags: {
        Header: locales.customerordersColumns.tags,
        Cell: (cell: any) => (
          <TableNewTagsComponent key={cell.getValue()} tags={cell.getValue()} />
        ),
        canFilter: true,
        canSort: false,
        editable: false,
        editType: 'select',
        selectValues: store.TagsStore.getIndicesTags().map((tag: any) => ({
          value: tag.id,
          label: tag.name.EN,
        })),
      },
      categories: {
        Header: 'Categories',
        Cell: (cell: any) => (
          <TableCategories key={cell.getValue()} categories={cell.getValue()} />
        ),
        canFilter: false,
        canSort: false,
        editable: false,
      },
      main_category: {
        Header: 'Main Category',
        Cell: (cell: any) => (
          <TableCategories
            key={cell.getValue()}
            categories={cell.getValue() ? [cell.getValue()] : []}
          />
        ),
        canFilter: false,
        canSort: false,
        editable: false,
      },
      categoriesIDs: {
        Header: 'Categories IDs',
        Cell: (cell: any) => (
          <div>
            {cell.getValue() ? (
              cell
                .getValue()
                .map((e: string, index: number) => <div key={index}>{e}</div>)
            ) : (
              <></>
            )}
          </div>
        ),
        canFilter: false,
        canSort: false,
        editable: false,
      },
      main_categoryID: {
        Header: 'Main Category ID',
        canFilter: false,
        canSort: false,
        editable: false,
      },
    }
  }

  return (
    <Table
      config={{
        tableName: 'stock',
        data: store.TableStore.data,
        fetchData: async () => await store.TableStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: tableStore.configColumnVisibility,
        allowColumnResize: tableStore.configAllowColumnResize,
        displaySearch: tableStore.configDisplaySearch,
        displaySelect: tableStore.configDisplaySelect,
        selectAllFunction: () => store.TableStore.setSelectAll(),
        displayExpand: tableStore.configDisplayExpand,
        displayTooltip: tableStore.configDisplayTooltip,
        displayEntry: tableStore.configDisplayEntry,
        displayPaginator: tableStore.configDisplayPaginator,
        displayOrdinalList: true,
        displayInfinitySwitch: true,
        allowSort: tableStore.configAllowSort,
        bank: tableStore.configBankFeatures,
        views: true,
        importSelect: true,
        actions: {
          display: true,
          label: locales.tableLabels.stock,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/product`,
          create: null,
          remove: null,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          custom: ['assignCollection'],
        },
        actionsMultiple: {
          display: true,
          label: locales.tableLabelsMultiple.stock,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/products_update`,
          create: null,
          remove: null,
          assignTag: true,
          custom: [
            'createOrder',
            'assignCollection',
            'copyToClipboard',
            'downloadFile',
          ],
        },
        headline: {
          visible: store.Theme.Navigation === 'side',
          title: 'Stock',
          subTitle: 'List of Stocked Products',
        },
        breadcrumb: ['Panel', 'Stock'],
        loaded: store.TableStore.loaded,
        setLoaded: (loaded) => store.TableStore.setLoaded(loaded),
      }}
    ></Table>
  )
})
export default StockTable
