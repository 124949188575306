import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../../../App'
import Table from '../../../_tableGeneric/table/Table.component'
import { ObjectDisplayCont } from '../Tags.styles'
import { LanguageBasedCell } from '../edits/languageBasedCell/LanguageBasedCell.component'
/* eslint-disable */
const TagsTable = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const columns = {
    id: {
      canFilter: false,
      canSort: false,
      editable: false,
    },
    name: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    root_id: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'rootSearch',
    },
    colour: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'color',
    },
    priority: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'number',
      canBeNull: false,
    },
    parent: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'tagSearch',
    },
    tag_size: {
      canFilter: false,
      canSort: false,
      editable: true,
      editType: 'select',
      selectValues: [
        { value: 'default', label: 'default' },
        { value: 'big', label: 'big' },
        { value: 'small', label: 'small' },
      ],
    },
    tag_icon: {
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'select',
      selectValues: store.TagsStore.avaliableIcons.map((icon) => ({
        value: icon,
        label: icon,
      })),
    },
    description: {
      Cell: (cell: any) => <LanguageBasedCell cellValue={cell.getValue()} />,
      canFilter: false,
      canSort: false,
      editable: true,
      mandatory: true,
      editType: 'languageObject',
      mandatoryLanguage: 'EN',
    },
    path: {
      canFilter: false,
      canSort: false,
      editable: false,
      Cell: (cell: any) => (
        <ObjectDisplayCont>
          {cell.getValue() &&
            Object.entries(cell.getValue()).map(([header, value], index) => (
              <div key={`${index}-value-name`}>{`${header}: ${value}`}</div>
            ))}
        </ObjectDisplayCont>
      ),
    },
  }
  return (
    <Table
      config={{
        tableName: 'tags',
        data: store.TagsStore.data || [],
        fetchData: async () => await store.TagsStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: true,
        displayOrdinalList: true,
        displayInfinitySwitch: true,
        allowSort: false,
        bank: false,
        actions: {
          display: true,
          label: locales.tableLabels.tags,
          edit: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          create: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/_create`,
          remove: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/remove`,
          assignTag: null,
          custom: [],
        },
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'Tags',
          subTitle: 'List of Tags',
        },
        breadcrumb: [],
        loaded: store.TagsStore.loaded,
        setLoaded: (loaded: boolean) => store.TagsStore.setLoaded(loaded),
      }}
    ></Table>
  )
})

export { TagsTable }
