import styled, { css } from 'styled-components'
import { breakpoints } from '../../static/breakpoints'
import { darken } from 'polished'

/* eslint-disable */
interface ContainerProps {
  padding?: string
  bgColor?: string
  loading: boolean
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  min-height: calc(100vh - 54px);
  position: relative;
  width: 100%;
  overflow-x: hidden !important;
  justify-content: space-between;
  flex-grow: 1;
  flex-shrink: 1;
  ${(props) => {
    if (!props.loading)
      return css`
        justify-content: center;
        align-items: center;
      `
  }}
`
export const HeaderCont = styled.div`
  display: flex;
  flex-direction: column;
`
export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`
export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 35%;
  flex-grow: 2;
  border-radius: 4px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
export const HeaderInnerRight = styled(HeaderInner)`
  justify-content: flex-end;
  align-items: flex-start;
  flex-grow: 1;

  @media screen and (max-width: 600px) {
    margin-top: 16px;
    justify-content: flex-end;
    flex-wrap: wrap;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;

  p {
    font-weight: 600;
    font-size: 13px;
  }
`
export const ButtonCont = styled.div`
  display: flex;
  flex-wrap: wrap;
`

interface HeaderTitleProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const HeaderTitle = styled.p<HeaderTitleProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '26px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '32px')};
  font-feature-settings:
    'pnum' on,
    'lnum' on;
  margin-right: 16px;
  @media print {
    position: fixed;
    display: block;
    top: 16px;
    left: 24px;
  }
`

interface HeaderButtonProps {
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  color?: string
}

export const HeaderButton = styled.div<HeaderButtonProps>`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;
  margin-right: 16px;
  color: ${(props) => (props.color ? props.color : '#424242')};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};

  img {
    cursor: pointer;
    margin-right: 6px;
  }

  @media print {
    display: none;
  }
`
export const PrintSelectedButton = styled(HeaderButton)``

PrintSelectedButton.defaultProps = {
  onClick: () => {
    const selectedElements: NodeListOf<HTMLElement> =
      document.querySelectorAll('.hide-print')
    selectedElements.forEach((element) => {
      element.classList.add('no-print')
    })
    window.print()
    selectedElements.forEach((element) => {
      element.classList.remove('no-print')
    })
  },
}

interface HeaderButtonProps {
  border?: string
  borderRadius?: string
  fontWeight?: number
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
}

/* eslint-disable */
const HeaderButtonProps = css<HeaderButtonProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  outline: none;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
  border: ${(props) => (props.border ? props.border : '1px solid')};
  min-width: 150px;
  margin-left: 4px;
  margin-bottom: 4px;

  p {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    border-right: 1px solid;
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '16px')};
    letter-spacing: ${(props) =>
      props.letterSpacing ? props.letterSpacing : '0.5px'};
    flex-grow: 1;
    justify-content: center;
    text-transform: uppercase;
  }
`

/* eslint-enable */
interface SecondaryButtonProps {
  borderColor?: string
  color?: string
  bgColor?: string
}

export const SecondaryButton = styled.button<SecondaryButtonProps>`
  position: relative;
  width: 25%;
  max-width: 200px;
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : '#303030'};
  color: ${(props) => (props.color ? props.color : '#303030')};
  background-color: ${(props) => props.bgColor};
  ${HeaderButtonProps};

  @media print {
    display: none;
  }
`
export const StyledImg = styled.img`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  width: 12px;
  height: 12px;
  display: block;
`

interface ActionsPrimaryButtonProps {
  borderColor?: string
  color?: string
  bgColor?: string
}

export const PrimaryButtonCont = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 400px;
  min-width: 240px;
  align-content: flex-end;
  text-align: center;
`
export const ActionsPrimaryButton = styled.button<ActionsPrimaryButtonProps>`
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#303030')};
  border-color: ${(props) =>
    props.borderColor ? props.borderColor : '#303030'};
  color: ${(props) => (props.color ? props.color : '#fafafa')};
  ${HeaderButtonProps};
  position: relative;
  width: 100%;

  img {
    padding-left: 10px;
    padding-right: 10px;
    filter: brightness(0) saturate(100%) invert(98%) sepia(12%) saturate(659%)
      hue-rotate(219deg) brightness(110%) contrast(100%);
  }

  @media print {
    display: none;
  }
`

export const NextStepCont = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  @media print {
    display: none;
  }
`

interface BodyInterface {
  packing?: boolean
}

export const Body = styled.div<BodyInterface>`
  display: grid;
  position: relative;
  grid-template-columns: ${(props) => (props.packing ? '1fr 1fr' : '2fr 1fr')};
  margin-top: 32px;
  margin-bottom: 40px;
  width: 100%;
  overflow: hidden;
  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }

  @media print {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 12px 0;
  }

  &.no-print {
    @media print {
      display: none !important;
      transform: scale(0.6);
    }
  }
`
export const LeftBody = styled.div`
  margin-right: 16px;

  > div {
    margin-bottom: 16px;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    margin-right: 0;
  }
  @media print {
    position: relative;
  }
`
export const AddressCont = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @media screen and (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`
export const AddressBody = styled.div`
  height: min-content;
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  gap: 16px;
  width: 100%;

  > div {
    height: calc(100% - 24px);
    align-self: start;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
  @media print {
    position: relative;
    max-height: 50%;
  }
`
export const RightBody = styled.div`
  width: 100%;
  @media print {
    display: none;
  }
`
export const PrintFooter = styled.div`
  display: none;
  @media print {
    display: block;
    position: fixed;
    bottom: 12px;
    right: 12px;
    font-size: 12px;
    width: 100%;
    text-align: right;
  }

  ::after {
    @page {
      @bottom-left {
        content: counter(page) ' of ' counter(pages);
      }
    }
  }
`
