/* eslint-disable */
import React, { useContext } from 'react'
import { observer } from 'mobx-react'
import { StoreContext } from '../../App'
import Table from '../_tableGeneric/table/Table.component'
import { Cell } from '@tanstack/react-table'
import TableLock from '../_tableGeneric/tableLock/TableLock.component'
import TableTags from '../_tableGeneric/tableTags/TableTags.component'
import TablePriorityMap from '../_tableGeneric/tablePriorityMap/TablePriorityMap.component'
import TableCompleteable from '../_tableGeneric/tableCompleteable/TableCompleteable.component'
import TableLastAction from '../_tableGeneric/tableLastAction/TableLastAction.component'
import TableNewTagsComponent from '../_tableGeneric/tableNewTags/TableNewTags.component'
import URL from '../../assets/icons/url.png'

const PackingTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    lock: {
      Header: locales.packingColumns.lock,
      Cell: (cell: any) => (
        <TableLock page={'packing'} label={cell.getValue()} cell={cell} />
      ),
      canFilter: true,
      canSort: false,
      editable: false,
      editType: 'text',
    },
    id: {
      Header: locales.packingColumns.id,
      canFilter: true,
      editable: false,
      editType: 'text',
    },
    order: {
      Header: 'Order_number_in_source',
      canFilter: true,
      editable: false,
    },
    order_number: {
      canFilter: true,
      editable: false,
    },
    priority: {
      Header: locales.packingColumns.priority,
      Cell: (cell: any) => <TablePriorityMap value={cell.getValue()} />,
    },
    completable: {
      Header: locales.packingColumns.completable,
      Cell: (cell: any) => (
        <TableCompleteable value={parseInt(cell.getValue())} />
      ),
      canFilter: true,
      editable: false,
      editType: 'select',
      selectValues: [
        { value: '0', label: locales.packable.allMissing },
        { value: '1', label: locales.packable.someMissing },
        { value: '2', label: locales.packable.packable },
      ],
    },
    completableMicroserviceInt: {
      Header: locales.packingColumns.completableMicroserviceInt,
      Cell: (cell: any) => (
        <TableCompleteable value={parseInt(cell.getValue())} />
      ),
      canFilter: true,
      editable: false,
      editType: 'select',
      selectValues: [
        { value: '0', label: locales.packable.allMissing },
        { value: '1', label: locales.packable.someMissing },
        { value: '2', label: locales.packable.packable },
      ],
    },
    status: {
      Header: locales.packingColumns.status,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    ordered: {
      Header: locales.packingColumns.ordered,
      canFilter: true,
      editable: false,
      editType: 'datetime-local',
    },
    client: {
      Header: locales.packingColumns.client,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    paid: {
      Header: locales.packingColumns.paid,
      canFilter: true,
      editable: true,
      editType: 'number',
      canBeNull: false,
    },
    shipping: {
      Header: locales.packingColumns.shipping,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    estimateProduct: {
      Header: locales.packingColumns.estimateProduct,
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    estimateDate: {
      Header: locales.packingColumns.estimateDate,
      canFilter: true,
      editable: true,
      editType: 'date',
    },
    orderSource: {
      Header: locales.packingColumns.orderSource,
      canFilter: false,
      editable: false,
      editType: 'text',
    },
    documents: {
      Header: locales.packingColumns.documents,
      canFilter: false,
      editable: false,
    },
    checkListItems: {
      Header: locales.packingColumns.checkListItems,
      Cell: (cell: any) => (
        <TableLastAction
          key={cell.getValue()}
          checkListItems={cell.getValue()}
        />
      ),
      canFilter: false,
      canSort: false,
      editable: false,
    },
    system: {
      Header: 'System',
      columns: {
        order_url: {
          canFilter: false,
          editable: false,
          Cell: (cell: any) => (
            <a href={cell.getValue()} target={'_blank'} rel='noreferrer'>
              <img style={{ width: '24px' }} src={URL} />
            </a>
          ),
        },
        domain: {
          canFilter: true,
          editable: false,
          editType: 'select',
          selectValues: store.StatusesStore.salesChannels.map(
            (channel: any) => ({
              value: channel.domain,
              label: channel.domain,
            }),
          ),
        },
        type: {
          canFilter: true,
          editable: false,
          editType: 'text',
        },
        url: {
          canFilter: true,
          editable: false,
          editType: 'text',
        },
      },
      canFilter: false,
      editable: false,
      editType: 'text',
    },
    delivery_date: {
      canFilter: true,
      editable: false,
      editType: 'date',
    },
  }

  if (store.UserStore.features.includes('tagmgm')) {
    columns = {
      ...columns,
      tags: {
        Header: locales.customerordersColumns.tags,
        Cell: (cell: any) => (
          <TableTags key={cell.getValue()} tags={cell.getValue()} />
        ),
        canFilter: false,
        canSort: false,
        editable: false,
      },
      object_tags: {
        Header: locales.customerordersColumns.tags,
        Cell: (cell: any) => (
          <TableNewTagsComponent key={cell.getValue()} tags={cell.getValue()} />
        ),
        canFilter: true,
        canSort: false,
        editable: false,
        editType: 'select',
        selectValues: store.TagsStore.getIndicesTags().map((tag: any) => ({
          value: tag.id,
          label: tag.name.EN,
        })),
      },
    }
  }

  return (
    <Table
      config={{
        tableName: 'packing',
        data: store.TableStore.data || [],
        fetchData: async () => await store.TableStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: tableStore.configColumnVisibility,
        allowColumnResize: tableStore.configAllowColumnResize,
        displaySearch: tableStore.configDisplaySearch,
        displaySelect: tableStore.configDisplaySelect,
        selectAllFunction: () => store.TableStore.setSelectAll(),
        displayExpand: tableStore.configDisplayExpand,
        displayTooltip: tableStore.configDisplayTooltip,
        displayEntry: tableStore.configDisplayEntry,
        displayPaginator: tableStore.configDisplayPaginator,
        displayOrdinalList: true,
        displayInfinitySwitch: true,
        allowSort: tableStore.configAllowSort,
        bank: tableStore.configBankFeatures,
        views: true,
        actions: {
          display: true,
          label: locales.tableLabels.packing,
          edit: null,
          create: null,
          remove: null,
          assignTag: `${process.env.REACT_APP_BASE_URL}experience/v1/tag/update`,
          custom: ['assignCollection'],
        },
        actionsMultiple: {
          display: true,
          label: locales.tableLabelsMultiple.packing,
          edit: null,
          create: null,
          remove: null,
          assignTag: true,
          custom: ['assignCollection', 'copyToClipboard', 'downloadFile'],
        },
        headline: {
          visible: store.Theme.Navigation === 'side',
          title: 'Packing',
          subTitle: 'List of packing orders in queue',
        },
        breadcrumb: ['Panel', 'Packing queue'],
        loaded: store.TableStore.loaded,
        setLoaded: (loaded) => store.TableStore.setLoaded(loaded),
      }}
    ></Table>
  )
})
export default PackingTable
