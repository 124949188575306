import { observer } from 'mobx-react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StoreContext } from '../../App'
import { Option, InputCont, StyledInput } from './SearchSelect.styles'
import Menu from '@mui/material/Menu'
import { Tooltip } from '@mui/material'
import Divider from '@mui/material/Divider'
import { IconX } from '@tabler/icons-react'

interface SearchSelectProps {
  onClick?: (option: any) => void
  onChange?: (option: any) => void
  onKeydown?: (option: any) => void
  Container: React.ComponentType<any>
  value: any
  options: { value: string; label: string; delete?: boolean }[]
  emptyValue?: string
  deleteFunction?: (e: any) => void
}

const SearchSelectComponent = observer(
  ({
    value,
    onClick,
    onChange,
    onKeydown,
    Container,
    options,
    emptyValue,
    deleteFunction,
  }: SearchSelectProps) => {
    const store = useContext(StoreContext)
    const { Theme } = store.Theme
    const locales = store.TranslationsState.translations

    const [filterText, setFilterText] = useState('')
    const [active, setActive] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const selectRef: any = useRef()
    const containerRef: any = useRef()

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(filterText.toLowerCase()),
    )

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setActive(false)
      setAnchorEl(null)
    }

    const handleOptionClick = (option: any) => {
      setActive(false)
      if (onClick) onClick(option)
    }

    const getWidth = () => {
      const containerWidth = containerRef.current
        ? containerRef.current.getBoundingClientRect().width
        : 0

      return `${Math.max(containerWidth - 16, 170)}px`
    }

    //useOutsideClick(selectRef, () => handleClose())

    /*useEffect(() => {
                                          options.forEach((e: any) =>
                                            console.log(e, toJS(store.ViewStore.selectedView))
                                          )
                                        }, [])*/

    return (
      <div>
        <Container
          ref={containerRef}
          value={value}
          readOnly={true}
          onClick={handleOpen}
          onKeyDown={(e: any) => onKeydown && onKeydown(e)} // Use onKeyDown instead of onKeydown
        >
          {options.map((e: any, index) => (
            <option
              style={{ display: 'none' }}
              key={`option-value-${e.value}`}
              value={e.value}
              selected={e.value === value}
            >
              {e.label}
            </option>
          ))}
        </Container>
        <Menu
          id={'basic-menu'}
          open={open}
          anchorEl={anchorEl}
          ref={selectRef}
          onClose={handleClose}
          disableScrollLock={true}
          PaperProps={{
            style: {
              maxHeight: 600,
            },
          }}
        >
          <InputCont
            style={{
              minWidth: getWidth(),
            }}
          >
            <p>Find element:</p>
            <StyledInput
              type={'text'}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder={'Search options...'}
            />
          </InputCont>
          <Divider sx={{ my: 0.5 }} />
          {filteredOptions.map((option, index) => (
            <Tooltip key={index} placement={'left'} arrow title={option.label}>
              <Option
                onClick={() => {
                  handleOptionClick(option)
                  handleClose()
                }}
              >
                {option.label !== ' ' ? (
                  <>
                    {option.label}
                    {option.delete && deleteFunction ? (
                      <IconX
                        size={'20px'}
                        onClick={(e) => {
                          e.preventDefault()
                          deleteFunction(option)
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <i>
                    {emptyValue ? emptyValue : `${locales.headers.noFilter}...`}
                  </i>
                )}
              </Option>
            </Tooltip>
          ))}
        </Menu>
      </div>
    )
  },
)

export default SearchSelectComponent
