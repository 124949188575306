import Table from '../../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext } from 'react'
import { StoreContext } from '../../../App'
import TableObjectDisplayComponent from '../../_tableGeneric/tableObjectDisplay/TableObjectDisplay.component'
import TableBool from '../../_tableGeneric/TableBool/TableBool.component'
import TableArrayDisplay from '../../_tableGeneric/tableArrayDisplay/TableArrayDisplay.component'
import TableObjectDisplay from '../../_tableGeneric/tableObjectDisplay/TableObjectDisplay.component'

/* eslint-disable */
const PredefinedFiltersTable = observer(() => {
  const store = useContext(StoreContext)
  const tableStore = store.TableStore
  const locales = store.TranslationsState.translations

  let columns: { [key: string]: any } = {
    id: {
      canFilter: false,
      editable: false,
    },
    name: {
      canFilter: true,
      editable: true,
      editType: 'text',
    },
    user: {
      canFilter: true,
      editable: false,
    },
    tableName: {
      canFilter: true,
      editable: false,
    },
    tableSize: {
      canFilter: true,
      editable: false,
    },
    private: {
      canFilter: true,
      editable: true,
      editType: 'selectBool',
      selectValues: [
        { value: 'true', label: 'True' },
        { value: 'false', label: 'False' },
      ],
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
    },
    allowedUsers: {
      canFilter: false,
      editable: true,
      editType: 'stringArraySelectWithLabel',
      selectValues: store.UserStore.userList,
      Cell: (cell: any) => <TableArrayDisplay data={cell.getValue()} />,
    },
    allowedGroups: {
      canFilter: false,
      editable: true,
      editType: 'stringArraySelectWithLabel',
      selectValues: store.UserStore.groupList,
      Cell: (cell: any) => <TableArrayDisplay data={cell.getValue()} />,
    },
    hiddenColumns: {
      canFilter: false,
      editable: false,
      Cell: (cell: any) => <TableObjectDisplay data={cell.getValue()} />,
    },
    columnOrder: {
      canFilter: false,
      editable: false,
      Cell: (cell: any) => <TableArrayDisplay data={cell.getValue()} />,
    },
    columnBorder: {
      canFilter: false,
      editable: false,
      Cell: (cell: any) => <TableArrayDisplay data={cell.getValue()} />,
    },
    clipTextColumns: {
      canFilter: false,
      editable: false,
      Cell: (cell: any) => <TableArrayDisplay data={cell.getValue()} />,
    },
    appliedPredefinedQuery: {
      canFilter: false,
      editable: false,
      Cell: (cell: any) => (
        <TableObjectDisplayComponent data={cell.getValue()} />
      ),
    },
    sort: {
      canFilter: false,
      editable: false,
      Cell: (cell: any) => (
        <TableObjectDisplayComponent data={cell.getValue()} />
      ),
    },
    favoriteAction: {
      canFilter: false,
      editable: false,
      editType: 'text',
    },
    showSearchField: {
      canFilter: true,
      editable: false,
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      Header: 'Show Search Field',
    },
    infinitySwitch: {
      canFilter: true,
      editable: false,
      Cell: (cell: any) => <TableBool value={cell.getValue()} />,
      Header: 'Infinity Switch',
    },
  }

  return (
    <Table
      config={{
        tableName: 'predefinedViews',
        data: store.ViewStore.allViews || [],
        fetchData: async () => {
          await store.ViewStore.setLoaded(false)
          await store.ViewStore.getAllViews()
          await store.ViewStore.setLoaded(true)
        },
        columnHeaders: columns,
        displayColumnVisibility: true,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: false,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: {
          display: true,
          label: locales.tableLabels.predefinedViews,
          edit: `${process.env.REACT_APP_BASE_URL}views/v1/views`,
          create: null,
          remove: `${process.env.REACT_APP_BASE_URL}views/v1/views`,
          assignTag: null,
          custom: [],
        },
        actionsMultiple: null,
        headline: {
          visible: true,
          title: 'Views',
          subTitle: 'List of views',
        },
        breadcrumb: [],
        loaded: store.ViewStore.loaded,
      }}
    ></Table>
  )
})
export default PredefinedFiltersTable
