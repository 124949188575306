import { action, makeAutoObservable, toJS } from 'mobx'
import { RootStore } from './Root.store'
import axios from '../axiosconfig'
import { toast } from 'react-toastify'
import removeAccents from 'remove-accents'
import Cookies from 'js-cookie'

/* eslint-disable */
export class UserStore {
  rootStore
  userObject: { [key: string]: any } = {}
  user = ''
  id = ''
  userID = ''
  image = ''
  email = ''
  token = ''
  features: string[] = []
  userList: any[] = []
  groupList: any[] = []
  featureId = ''
  mainLoaded = false
  defaultLanguage = 'en_GB'
  defaultTheme = 'default'
  defaultAction = {}
  defaultFilter = {}
  defaultView = {}

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  @action.bound authenticate = async (
    name: string,
    image: string,
    token: string,
    userId: string,
    userObject: any,
  ) => {
    this.user = removeAccents(name)
    this.image = image
    this.userID = userObject.id
    this.userObject = userObject
    this.id = userObject.id

    if (this.id) {
      await this.getDefaultSettings()
    }
    const storedToken = Cookies.get('auth_token')
    const tokenExpiry = Cookies.get('auth_token_expiry')
    const currentTime = new Date().getTime()

    if (storedToken && tokenExpiry && parseInt(tokenExpiry, 10) > currentTime) {
      this.token = storedToken
    } else {
      try {
        const url = 'https://api.frontegg.com/auth/vendor/'
        const data = {
          clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID,
          secret: process.env.REACT_APP_FRONTEGG_SECRET,
        }
        const config = {}

        const res = await axios.post(url, data, config)
        const newToken = res.data.token
        const expiresIn = res.data.expiresIn

        this.token = newToken

        Cookies.set('auth_token', newToken, { expires: expiresIn / 86400 })
        Cookies.set(
          'auth_token_expiry',
          (currentTime + expiresIn * 1000).toString(),
          {
            expires: expiresIn / 86400,
          },
        )
      } catch (error) {
        console.error('Authentication failed:', error)
      }
    }

    await this.getAvaliableFeatures()
    await this.getUserList()
    await this.getGroupList()
  }

  @action.bound getUserList = async () => {
    const usersUrl = 'https://api.frontegg.com/identity/resources/users/v1'
    const config = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
    }

    const usersResponse = await axios.get(usersUrl, config)
    if (usersResponse) {
      this.userList = usersResponse.data.items.map((user: any) => ({
        value: user.id,
        label: user.name,
      }))
    } else this.userList = []
  }
  @action.bound getGroupList = async () => {
    const groupsUrl = 'https://api.frontegg.com/identity/resources/groups/v1'
    const config = {
      headers: {
        authorization: `Bearer ${this.token}`,
        'frontegg-tenant-id': this.userObject.tenantId,
      },
    }

    const groupsResponse = await axios.get(groupsUrl, config)

    if (groupsResponse) {
      this.groupList = groupsResponse.data.groups.map((group: any) => ({
        value: group.id,
        label: group.name,
      }))
    } else this.groupList = []
  }

  @action.bound getAvaliableFeatures = async () => {
    const url =
      'https://api.frontegg.com/entitlements/resources/feature-bundles/v1'
    const config = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
      params: {
        tenantIds: this.userObject.tenantId,
      },
    }

    const res = await axios.get(url, config)
    this.featureId = res.data.items[0].id

    const url2 = `https://api.frontegg.com/entitlements/resources/feature-bundles/v1/${this.featureId}`
    const config2 = {
      headers: {
        authorization: `Bearer ${this.token}`,
      },
      params: { offset: '0', limit: '100' },
    }
    const res2 = await axios.get(url2, config2)
    this.features = res2.data.features.map((feature: any) => feature.key)
  }

  @action.bound getDefaultSettings = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/usersettings/${this.id}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.user,
      },
    }
    try {
      const response = await axios.get(url, config)
      this.defaultTheme = await response.data.user_default_theme_id
      this.defaultLanguage = await response.data.language
      this.defaultAction = (await response.data.default_action) || {}
      this.defaultFilter = (await response.data.default_filter) || {}
      this.defaultView = (await response.data.default_view) || {}
    } catch (e) {
      const data = {
        language: 'en_GB',
        user_default_theme_id: 'default',
        default_action: {},
        default_filter: {},
        default_view: {},
      }
      await axios.post(url, data, config)
      const response = await axios.get(url, config)
      this.defaultTheme = await response.data.user_default_theme_id
      this.defaultLanguage = await response.data.language
      this.defaultAction = (await response.data.default_action) || {}
      this.defaultFilter = (await response.data.default_filter) || {}
      this.defaultView = (await response.data.default_view) || {}
    }
    await this.rootStore.TranslationsState.setTranslations(this.defaultLanguage)
    await this.rootStore.Theme.setTheme(this.defaultTheme)
  }

  @action.bound setMainLoaded = (mainLoaded: boolean) => {
    this.mainLoaded = mainLoaded
  }

  @action.bound updateDefaultSettings = async (
    defaultLanguage: string,
    defaultTheme: string,
    defaultAction: any,
    defaultFilter: any,
    defaultView: any,
  ) => {
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/storesettings/usersettings/${this.id}`
    const data = {
      language: defaultLanguage,
      user_default_theme_id: defaultTheme,
      default_action: defaultAction,
      default_filter: defaultFilter,
      default_view: defaultView,
    }
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
        username: this.user,
      },
    }
    try {
      await axios.post(url, data, config)
      await toast('Saved', {
        type: 'success',
      })
      this.defaultLanguage = defaultLanguage
      this.defaultTheme = defaultTheme
    } catch (e: any) {
      toast(e.message.toString(), { type: 'error' })
    }
  }
}
