import Table from '../_tableGeneric/table/Table.component'

import { observer } from 'mobx-react'
import { useContext, useEffect } from 'react'
import { StoreContext } from '../../App'
import { Cell } from '@tanstack/react-table'
import TableDate from '../_tableGeneric/tableDate/TableDate.component'

const AuditTable = observer(() => {
  const store = useContext(StoreContext)
  const locales = store.TranslationsState.translations
  const tableStore = store.TableStore
  const columns = {
    id: {
      canSort: false,
    },
    action: {
      canSort: false,
    },
    entity: {
      canSort: false,
    },
    date: {
      Cell: (cell: any) => <TableDate date={cell.getValue()} />,
      canSort: false,
    },
    log: {
      Header: 'User',
      canSort: false,
    },
  }

  useEffect(() => {
    store.AuditStore.fetchData()
    return () => {
      store.AuditStore.clearData()
    }
  }, [])

  return (
    <Table
      config={{
        tableName: 'audit',
        data: store.AuditStore.data || [],
        fetchData: async () => await store.AuditStore.fetchData(),
        columnHeaders: columns,
        displayColumnVisibility: false,
        allowColumnResize: true,
        displaySearch: false,
        displaySelect: false,
        displayExpand: false,
        displayTooltip: false,
        displayEntry: false,
        displayPaginator: true,
        displayOrdinalList: true,
        displayInfinitySwitch: false,
        allowSort: true,
        bank: false,
        actions: null,
        actionsMultiple: null,
        headline: {
          visible: store.Theme.Navigation === 'side',
          title: 'Audit Log',
          subTitle: 'List of logged information',
        },
        breadcrumb: [],
        loaded: store.AuditStore.loaded,
      }}
    ></Table>
  )
})
export default AuditTable
