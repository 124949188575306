import styled from 'styled-components'

interface DropDownItemProps {
  fontSize?: string
  lineHeight?: string
  letterSpacing?: string
  color?: string
  hoverBgColor?: string
  active: boolean
}

/* eslint-disable */
export const DropDownItem = styled.div<DropDownItemProps>`
  position: relative;
  width: 100%;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '40px')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : '0.15px'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: ${(props) =>
    props.active ? 'rgba(0, 255, 0, 0.2)' : 'rgba(255, 0, 0, 0.2)'};

  svg {
    margin-right: 4px;
  }

  :hover {
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : '#f8f8f8'};
  }
`

interface HeaderDropDownProps {
  color?: string
  bgColor?: string
  borderRadius?: string
  boxShadow?: string
}

export const HeaderDropDown = styled.div<HeaderDropDownProps>`
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%;
  min-width: 140px;
  z-index: 50;
  color: ${(props) => (props.color ? props.color : '#000000')};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#ffffff')};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : '0 1px 3px rgba(0, 0, 0, 0.06)'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '6px'};
`

export const DropDownCont = styled.div``
