/* eslint-disable */
import {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
  ChangeEvent,
} from 'react'

import { ValidateArray } from '../../columnsConfig'

import {
  Container,
  Footer,
  InnerCont,
  ProductParamsText,
  StyledCheckbox,
  StyledProduct,
} from './CreateReturn.styles'

import { StoreContext } from '../../App'
import axios from '../../axiosconfig'
import { DataInputGeneric } from '../dataInputGeneric/DataInputGeneric.component'
import Button from '../button/Button.component'
import { getEmptyValue } from '../createGeneric/CreateGeneric.component'
import AddOrderSearchComponent from '../addOrderSearch/AddOrderSearch.component'
import { toast } from 'react-toastify'
import {
  FilterLabel,
  Input,
  StyledSelect,
} from '../dataInputGeneric/DataInputGeneric.styles'
import { TextArea } from '../editInput/EditInput.styles'

export interface CreateProps extends PropsWithChildren {
  tableName: string
  columns: any[]
  toggleClick?: () => void
  name: string
  setLoaded?: (loaded: boolean) => void
  fetchData: () => Promise<void>
}

interface SelectedProduct {
  id: string
  code: string
  name: string
  price: number
  quantity: number
  image_url: string
  returnQuantity: number
}

const CreateReturn: (props: CreateProps) => JSX.Element = (
  props: CreateProps,
) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const locales = store.TranslationsState.translations
  const [lockChecked, setLockChecked] = useState(true)
  const [order, setOrder] = useState<any>(null)
  const [step, setStep] = useState(1)
  const [locked, setLocked] = useState(false)
  const [lockedUser, setLockedUser] = useState('')
  const [triggerChange, setTriggerChange] = useState(false)
  const [isAbleToSave, setIsAbleToSave] = useState(false)

  const divRef = useRef<any>(null)

  const initialState = [
    ...props.columns
      .filter((e) => e.editCustomOptions.editType && e.id)
      .map((e) => {
        return {
          header: e.id,
          value: getEmptyValue(e.editCustomOptions.editType, e),
          error: '',
          rules: ValidateArray[e.id] ? ValidateArray[e.id] : [],
          isActive: false,
        }
      }),
  ]
  const [form, setForm] = useState(initialState)
  const [inputColumns, setInputColumns] = useState(1)

  // Step 2 states
  const [reasonList, setReasonList] = useState<string[]>([
    'Damaged Product',
    'Wrong Item Delivered',
    'Not Satisfied',
    'Other',
  ])
  const [selectedReason, setSelectedReason] = useState('')
  const [comment, setComment] = useState('')
  const [mediaFiles, setMediaFiles] = useState<File[]>([])
  const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>(
    [],
  )

  const handleSetForm = (
    tempForm: Array<{
      header: string
      value: string
      error: string
      rules: Array<string>
      isActive: boolean
    }>,
  ) => {
    setForm([...tempForm])
  }

  const handleFirstStep = async () => {
    if (!order) {
      toast('Please select an order', { type: 'warning' })
      return
    }
    const url = `${process.env.REACT_APP_BASE_URL}experience/v1/customer_order/${order.id}`
    const config = {
      headers: {
        'x-apikey': `${process.env.REACT_APP_API_KEY}`,
      },
    }
    const response = await axios.get(url, config)
    if (response.status === 200) {
      setOrder(response.data)
      setStep(2)
    } else {
      toast('Order not found', { type: 'error' })
    }
  }

  const handleSave = async () => {
    // Here you would implement the logic to save the return:
    // This might involve sending the selected products, reason, comment, and attached media to your API.

    if (selectedProducts.length === 0) {
      toast('Please select at least one product to return', { type: 'warning' })
      return
    }

    if (!selectedReason) {
      toast('Please select a reason for return', { type: 'warning' })
      return
    }

    // Construct form data or JSON payload as needed
    const returnPayload = {
      orderId: order.id,
      products: selectedProducts.map((p) => ({
        code: p.code,
        returnQuantity: p.returnQuantity,
      })),
      reason: selectedReason,
      comment: comment,
      // If you need to upload media, you may need a multipart/form-data request or a separate endpoint
    }

    console.log('Submitting return:', returnPayload, mediaFiles)

    // Example:
    // try {
    //   await axios.post(`${process.env.REACT_APP_BASE_URL}returns`, returnPayload)
    //   toast('Return created successfully!', { type: 'success' })
    //   store.SlidingPanelState.setVisibility(false)
    //   props.fetchData()
    // } catch (error) {
    //   toast('Error saving return', { type: 'error' })
    // }
  }

  useEffect(() => {
    let changed = true
    form.forEach((e) => {
      const editOptions = props.columns.find(
        (f: any) => f.id === e.header,
      )?.editCustomOptions

      const isMandatory =
        editOptions && editOptions.hasOwnProperty('mandatory')
          ? editOptions.mandatory
          : false
      const type = editOptions?.type
      if (e.value === getEmptyValue(type, e) && isMandatory) {
        changed = false
      }
    })
    setIsAbleToSave(changed)
  }, [triggerChange, form, props.columns])

  // Step 2 Handlers
  const handleMediaChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setMediaFiles([...mediaFiles, ...Array.from(event.target.files)])
    }
  }

  const handleRemoveMedia = (index: number) => {
    const updatedMediaFiles = mediaFiles.filter((_, i) => i !== index)
    setMediaFiles(updatedMediaFiles)
  }

  const renderMediaPreview = () =>
    mediaFiles.map((file, index) => (
      <div
        key={index}
        style={{ position: 'relative', display: 'inline-block', margin: '5px' }}
      >
        {file.type.startsWith('image') ? (
          <img
            src={URL.createObjectURL(file)}
            alt='upload preview'
            width={100}
            height={100}
          />
        ) : (
          <video width={100} height={100} controls>
            <source src={URL.createObjectURL(file)} type={file.type} />
          </video>
        )}
        <button
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            background: 'red',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleRemoveMedia(index)}
        >
          X
        </button>
      </div>
    ))

  // Products table logic
  const isProductSelected = (id: string) =>
    !!selectedProducts.find((p) => p.id === id)

  const getReturnQuantity = (id: string) => {
    const product = selectedProducts.find((p) => p.id === id)
    return product ? product.returnQuantity : 1
  }

  const handleSelectProduct = (product: any) => {
    const existingProduct = selectedProducts.find((p) => p.id === product.id)
    if (existingProduct) {
      // Deselect if already selected
      setSelectedProducts(selectedProducts.filter((p) => p.id !== product.id))
    } else {
      // Select with default returnQuantity = 1
      setSelectedProducts([
        ...selectedProducts,
        {
          id: product.id,
          code: product.code,
          name: product.name,
          price: parseFloat(product.price), // ensure numeric
          quantity: parseInt(product.quantity),
          image_url: product.image_url,
          returnQuantity: 1,
        },
      ])
    }
  }

  const handleQuantityChange = (
    id: string,
    newQuantity: number,
    maxQuantity: number,
  ) => {
    if (newQuantity < 1) return
    if (newQuantity > maxQuantity) return

    setSelectedProducts((prev) =>
      prev.map((p) =>
        p.id === id ? { ...p, returnQuantity: newQuantity } : p,
      ),
    )
  }

  const calculateTotalReturnPrice = () => {
    const amount = selectedProducts
      .reduce((total, product) => {
        return total + product.price * product.returnQuantity
      }, 0)
      .toFixed(2)
    return amount
  }

  return (
    <Container {...Theme.editProductsStyles.container}>
      {lockChecked && !locked ? (
        <>
          <InnerCont
            ref={divRef}
            cols={inputColumns}
            style={{
              maxWidth: '400px',
            }}
          >
            <>
              {step === 1 ? (
                <AddOrderSearchComponent
                  applySearch={(orderId) => setOrder(orderId)}
                />
              ) : null}
            </>

            <>
              {step === 2 && order ? (
                <>
                  <FilterLabel>Products in this order:</FilterLabel>
                  <div>
                    {Object.entries(order.products).map(
                      ([id, product]: any) => (
                        <StyledProduct
                          key={`product-${id}`}
                          style={{ borderBottom: '1px solid #ccc' }}
                        >
                          <StyledCheckbox
                            type='checkbox'
                            checked={isProductSelected(product.id)}
                            onChange={() => handleSelectProduct(product)}
                          />
                          <img
                            src={product.image_url}
                            alt='product'
                            width={50}
                            height={50}
                          />
                          <ProductParamsText>{product.code}</ProductParamsText>
                          <ProductParamsText>{product.name}</ProductParamsText>
                          <ProductParamsText>
                            {product.price} {order.currency_name || 'PLN'}
                          </ProductParamsText>
                          <ProductParamsText>
                            {product.quantity}
                          </ProductParamsText>
                          <div>
                            <input
                              type='number'
                              min={1}
                              max={parseInt(product.quantity)}
                              value={getReturnQuantity(product.id)}
                              onChange={(e) =>
                                handleQuantityChange(
                                  product.id,
                                  parseInt(e.target.value),
                                  parseInt(product.quantity),
                                )
                              }
                              disabled={!isProductSelected(product.id)}
                            />
                          </div>
                        </StyledProduct>
                      ),
                    )}
                  </div>

                  <FilterLabel>Return Reason</FilterLabel>
                  <StyledSelect
                    activeStyle={true}
                    value={selectedReason}
                    onChange={(e) => setSelectedReason(e.target.value)}
                  >
                    <option value=''>--Please choose a reason--</option>
                    {reasonList.map((reason, idx) => (
                      <option key={idx} value={reason}>
                        {reason}
                      </option>
                    ))}
                  </StyledSelect>

                  <FilterLabel>Upload media (optional)</FilterLabel>
                  <Input
                    activeStyle={true}
                    type='file'
                    multiple
                    accept='image/*,video/*'
                    onChange={handleMediaChange}
                  />
                  <div style={{ marginTop: '10px' }}>
                    {renderMediaPreview()}
                  </div>

                  <FilterLabel>Additional Comment</FilterLabel>
                  <TextArea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{ width: '100%', height: '80px' }}
                  />

                  <h3 style={{ marginTop: '20px' }}>
                    Total Return: {calculateTotalReturnPrice()}{' '}
                    {order.currency_name || 'PLN'}
                  </h3>
                </>
              ) : null}
            </>
          </InnerCont>
          <Footer {...Theme.editProductsStyles.styledFooter}>
            <Button
              type={'secondary'}
              onClick={() => store.SlidingPanelState.setVisibility(false)}
              {...Theme.buttons.secondaryButton}
            >
              {locales.buttons.cancel}
            </Button>
            {step === 1 ? (
              <Button
                type={'primary'}
                onClick={() => handleFirstStep()}
                active={true}
                {...Theme.buttons.primaryButton}
              >
                {'Select Order'}
              </Button>
            ) : null}
            {step === 2 ? (
              <Button
                type={'primary'}
                onClick={() => handleSave()}
                active={true}
                {...Theme.buttons.primaryButton}
              >
                {'Confirm Return'}
              </Button>
            ) : null}
          </Footer>
        </>
      ) : (
        <>
          {locked ? (
            <>This order is currently edited by {lockedUser}</>
          ) : (
            <>Checking order status</>
          )}
        </>
      )}
    </Container>
  )
}

export { CreateReturn }
