import { observer } from 'mobx-react'
import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { Cell, ColumnInstance, flexRender, Row } from '@tanstack/react-table'

import { StoreContext } from '../../../App'
import ArrowExpand from '../../../assets/icons/arrow-expand.svg'
import ArrowExpanded from '../../../assets/icons/arrow-expanded.svg'

import {
  TableAbsolute,
  TableCellArrowCont,
  TableCellExpand,
  TableData,
} from './TableCell.styles'
import { toast } from 'react-toastify'
import { toJS } from 'mobx'
import debounce from 'lodash/debounce'

interface ColumnExtended extends ColumnInstance {
  headerCustomOptions?: {
    sort: {
      desc: boolean
    }
    settings: {
      wrapText: boolean
      color: string
      border: boolean
    }
  }
}

interface Props extends PropsWithChildren {
  cell: any
  key2: number
  column: any
  row: any
  change: boolean
}

const TableCell: FC<Props> = observer((props: Props) => {
  const store = useContext(StoreContext)
  const { Theme } = store.Theme
  const absoluteColumn = props.cell.column.id === '_options'
  const [selected, setSelected] = useState(false)
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null)

  const debouncedSingleClick = debounce((cellValue: string) => {
    navigator.clipboard.writeText(cellValue)
    toast('Copied to clipboard', {
      type: 'success',
      autoClose: 500,
    })
  }, 400)

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    const cellValue = props.cell.getValue()

    switch (e.detail) {
      case 1:
        if (typeof cellValue === 'string' && props.cell.column.id !== 'lock') {
          debouncedSingleClick(cellValue)
        }
        break
      case 2:
        debouncedSingleClick.cancel()
        setSelected((prevSelected) => !prevSelected)
        break

      default:
        console.log(`Unhandled click count: ${e.detail}`)
    }
  }

  const CellRender = (props: Props) => (
    <TableData
      width={props.cell.column.getSize()}
      selected={selected}
      wrapText={
        props.cell.column.columnDef.headerCustomOptions
          ? props.cell.column.columnDef.headerCustomOptions.settings.wrapText
          : false
      }
      border={
        props.cell.column.columnDef.headerCustomOptions
          ? props.cell.column.columnDef.headerCustomOptions.settings.border
          : false
      }
      customColor={
        props.cell.column.columnDef.headerCustomOptions
          ? props.cell.column.columnDef.headerCustomOptions.settings.color
          : 'transparent'
      }
      onClick={handleClick}
      key={props.key2}
      {...Theme.tableStyles.tableData}
    >
      {flexRender(props.cell.column.columnDef.cell, props.cell.getContext())}
    </TableData>
  )

  const memoizedCellRender = useMemo(() => {
    return CellRender(props)
  }, [
    selected,
    props.cell.getValue(),
    props.cell.column.columnDef.headerCustomOptions?.settings.wrapText,
    props.cell.column.columnDef.headerCustomOptions?.settings.color,
    props.cell.column.columnDef.headerCustomOptions?.settings.border,
    props.row.getIsExpanded(),
    store.TableStore.selectedExpanded,
    props.cell.column.getSize(),
  ])

  if (props.cell.column.id === 'selection') {
    return useMemo(() => {
      return CellRender(props)
    }, [
      props.cell.row.isSelected,
      props.row.getIsExpanded(),
      store.TableStore.selectedExpanded.length,
    ])
  }

  const AbsoluteCellRenderer = (props: Props) => (
    <TableData
      width={props.cell.column.getSize()}
      selected={false}
      wrapText={false}
      border={false}
      customColor={'transparent'}
      key={props.key2}
      {...Theme.tableStyles.tableData}
    >
      <TableAbsolute style={{ position: 'absolute', right: '0' }}>
        {flexRender(props.cell.column.columnDef.cell, props.cell.getContext())}
      </TableAbsolute>
    </TableData>
  )

  const memoisedAbsoluteCellRenderer = useMemo(
    () => AbsoluteCellRenderer(props),
    [props.cell.getValue()],
  )

  if (absoluteColumn) return memoisedAbsoluteCellRenderer

  return memoizedCellRender
})

export default TableCell
