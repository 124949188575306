import { Container } from './TableBool.styles'
import { observer } from 'mobx-react'
import { SwitchInput } from '../table/Table.styles'
import React from 'react'

interface TableBoolInterface {
  value: boolean
}

const TableBool = observer(({ value }: TableBoolInterface) => {
  const getColor = () => {
    switch (value) {
      case true:
        return 'rgba(0,255,0,0.4)'
      case false:
        return 'rgba(255,0,0,0.4)'
      default:
        return 'transparent'
    }
  }
  return (
    <Container color={getColor()}>
      <SwitchInput
        onColor={'#016220'}
        offColor={'#900d09'}
        checked={!!value}
        uncheckedIcon={false}
        checkedIcon={false}
        height={20}
        width={36}
        onChange={() => {}}
        handleDiameter={16}
      />
    </Container>
  )
})
export default TableBool
